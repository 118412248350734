import styled from "@emotion/styled";
import { h3, h6 } from "../../assets";

export const Wrap = styled.div`
  padding-bottom: 120px;

  @media (max-width: 1024px) {
    padding-bottom: 80px;
  }

  @media (max-width: 768px) {
    padding-bottom: 48px;
  }
`;
export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 48px;
  column-gap: 24px;

  @media (max-width: 1024px) {
    ${h6}
    font-weight: 500;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
export const Title = styled.h2`
  ${h3};
  padding-bottom: 64px;
  @media (max-width: 1024px) {
    ${h6}
    padding-bottom: 24px;
  }
`;

export const ListElement = styled.li`
  padding: 0;
  margin: 0;
`;
