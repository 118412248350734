import styled from "@emotion/styled";

export const SpanWrap = styled.div`
  max-width: 84px;
  min-width: 84px;
  width: 100%;
  height: 84px;
  border-radius: 50%;
  background-color: #b080ed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpanCss = styled.span`
  font-size: 40px;
`;
