import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  id: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

const initialState: UserState = {
  id: null,
  email: null,
  firstName: null,
  lastName: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<UserState>>) => {
      const { id, email, firstName, lastName } = action.payload;
      if (id !== undefined) {
        state.id = id;
      }
      if (email !== undefined) {
        state.email = email;
      }
      if (firstName !== undefined) {
        state.firstName = firstName;
      }
      if (lastName !== undefined) {
        state.lastName = lastName;
      }
    },
    clearUser: (state) => {
      state.id = null;
      state.email = null;
      state.firstName = null;
      state.lastName = null;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
