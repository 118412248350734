import styled from "@emotion/styled";
import { black } from "../../assets";

export const Svg = styled.svg<{ active?: boolean }>`
  fill: ${(props) => (props.active ? black : "#D9D9D9")};
  stroke: ${(props) => (props.active ? black : "#D9D9D9")};
`;

export const PresentationEditorNavigationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dedfe1;
  padding: 32px 20px 32px 20px;
`;
export const ActionsButtonsBlock = styled.div`
  display: flex;
  gap: 8px;
`;
export const ActionButton = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${(props) => (props.active ? black : "#D9D9D9")};
  background-color: transparent;
  cursor: pointer;
`;
export const EditButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;

  & > svg {
    stroke: #bcbadb;
    fill: #fff;
    width: 24px;
    height: 24px;

    path.ql-stroke,
    line.ql-stroke {
      stroke: inherit;
    }
  }

  &:hover > svg {
    path.ql-stroke,
    line.ql-stroke {
      stroke: inherit;
    }
  }

  &:focus > svg {
    stroke: inherit;
    fill: inherit;
  }
`;

export const QuillEditButtons = styled.div`
  display: flex;
  gap: 24px;
`;
