import React from "react";
import { SwitchContainer, SwitchLabel, SwitchButton } from "./Switch.styled";

interface SwitchProps {
  isOn: boolean;
  handleToggle: () => void;
}

const Switch: React.FC<SwitchProps> = ({ isOn, handleToggle }) => {
  return (
    <SwitchContainer isOn={isOn}>
      <SwitchLabel isOn={isOn} onClick={handleToggle}>
        <SwitchButton isOn={isOn} />
      </SwitchLabel>
    </SwitchContainer>
  );
};

export default Switch;
