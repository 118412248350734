import React, { useCallback, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { TContentPlanPost } from "../../types/index";
import sprite from "../../icons/symbol-defs.svg";
import ImagePreview from "../../assets/image/image-preview.png";
import {
  PresentationEditorSlideWrap,
  PresentationEditorSlideInfo,
  PresentationEditorSlideTitle,
  PresentationEditorSlideText,
  PresentationEditorSlideInfoWrap,
  PresentationEditorSlideImageWrap,
  PresentationEditorSlideImage,
  PresentationEditorSlideImageButtons,
  PresentationEditorSlideTypeWrap,
  CommonWrap,
  CustomReactQuill,
  PresentationEditorSlideInfoTitle,
  IntroLogoWrap,
  IntroLogo,
  TitleSvg,
  PresentationEditorSlideImageTooltipWrap,
  PresentationEditorSlideImageTooltipBox,
  PresentationEditorSlideImageTooltipMark,
} from "./PresentationEditorSlide.styled";
import Button from "../Button";
import LoadingSpinner from "../LoadingSpinner";

interface PresentationEditorSlideProps {
  type: string;
  preview: boolean;
  title?: string;
  post?: TContentPlanPost;
  id: number;
  active: boolean;
  quillRef?: any;
  quillData?: {
    htmlContent: string;
  };
  toolbarRef: React.RefObject<HTMLDivElement | null>;
  handleChangeImage?: () => void;
  handleOpenUploadImagePopup?: () => void;
  updateHtmlContent: (index: number, htmlContent: string) => void;
}

const PresentationEditorSlide: React.FC<PresentationEditorSlideProps> =
  React.memo(
    ({
      id,
      type,
      preview,
      title,
      post,
      quillRef,
      active,
      quillData,
      toolbarRef,
      handleChangeImage,
      handleOpenUploadImagePopup,
      updateHtmlContent,
    }) => {
      const [visibleTooltip, setVisibleTooltip] = useState(false);
      const showTooltip = () => setVisibleTooltip(true);
      const hideTooltip = () => setVisibleTooltip(false);

      const handleChange = useCallback(() => {
        if (quillRef) {
          const quill = quillRef.current?.getEditor();
          if (quill && id !== undefined) {
            const html = quill.root.innerHTML;
            updateHtmlContent(id, html);
          }
        }
      }, [quillRef, updateHtmlContent, id]);

      useEffect(() => {
        if (!preview) {
          const quill = quillRef?.current?.getEditor();
          if (quill) {
            quill.on("text-change", handleChange);
          }
          return () => {
            if (quill) {
              quill.off("text-change", handleChange);
            }
          };
        }
      }, [quillRef, updateHtmlContent, handleChange, id, preview]);

      const modules = {
        toolbar: {
          container: toolbarRef.current,
        },
      };

      return (
        <PresentationEditorSlideTypeWrap
          type={type}
          active={active}
          preview={preview}
        >
          {type === "intro" && (
            <IntroLogoWrap>
              <IntroLogo width="364" height="56">
                <use xlinkHref={`${sprite}#woohoo`}></use>
              </IntroLogo>
            </IntroLogoWrap>
          )}
          {type === "post" && post && (
            <PresentationEditorSlideWrap>
              <PresentationEditorSlideInfo>
                <PresentationEditorSlideTitle>
                  Content Calendar
                </PresentationEditorSlideTitle>
                <PresentationEditorSlideText>
                  <span>{post.week}</span>
                </PresentationEditorSlideText>
                <PresentationEditorSlideInfoWrap>
                  {quillData ? (
                    <CustomReactQuill
                      ref={quillRef}
                      value={quillData.htmlContent}
                      readOnly={preview}
                      modules={modules}
                    />
                  ) : (
                    <LoadingSpinner width="50px" height="50px" />
                  )}
                </PresentationEditorSlideInfoWrap>
              </PresentationEditorSlideInfo>
              <PresentationEditorSlideImageWrap>
                <PresentationEditorSlideImage
                  src={post.visual_example ?? ImagePreview}
                />
                {preview || !post.visual_example ? (
                  <></>
                ) : (
                  <>
                    {post.customImage ? (
                      <></>
                    ) : (
                      <PresentationEditorSlideImageTooltipWrap>
                        <PresentationEditorSlideImageTooltipMark
                          onMouseEnter={showTooltip}
                          onMouseLeave={hideTooltip}
                        >
                          !
                        </PresentationEditorSlideImageTooltipMark>
                        <PresentationEditorSlideImageTooltipBox
                          visible={visibleTooltip}
                        >
                          This image is generated in a sketch style to serve as
                          a reference for your future final image
                        </PresentationEditorSlideImageTooltipBox>
                      </PresentationEditorSlideImageTooltipWrap>
                    )}
                    <PresentationEditorSlideImageButtons>
                      <Button
                        text="Generate image using AI"
                        btnType="ai-generator"
                        bgColor="#F0F424"
                        icon="edit"
                        width="173px"
                        onClick={handleChangeImage}
                      />
                      <Button
                        text="Upload your image"
                        btnType="ai-generator"
                        bgColor="#F0F424"
                        icon="upload"
                        width="152px"
                        onClick={handleOpenUploadImagePopup}
                      />
                    </PresentationEditorSlideImageButtons>
                  </>
                )}
              </PresentationEditorSlideImageWrap>
            </PresentationEditorSlideWrap>
          )}
          {type === "strategy-slide" && title && (
            <CommonWrap type={type} preview={preview}>
              <PresentationEditorSlideInfoTitle>
                {title}
                <TitleSvg width="30" height="29">
                  <use xlinkHref={`${sprite}#stars`}></use>
                </TitleSvg>
              </PresentationEditorSlideInfoTitle>
              <PresentationEditorSlideInfoWrap>
                {quillData ? (
                  <CustomReactQuill
                    ref={quillRef}
                    value={quillData.htmlContent}
                    readOnly={preview}
                    modules={modules}
                  />
                ) : (
                  <LoadingSpinner width="50px" height="50px" />
                )}
              </PresentationEditorSlideInfoWrap>
            </CommonWrap>
          )}
        </PresentationEditorSlideTypeWrap>
      );
    }
  );

export default PresentationEditorSlide;
