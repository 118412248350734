import { ReactNode } from "react";
import { SectionCss } from "./Section.styled";

type SectionProps = {
  id?: string;
  children: ReactNode;
  marginB?: string;
  overflow?: string;
  type?: string | undefined;
  zIndex?: string;
};

function Section({
  id,
  children,
  marginB,
  overflow,
  type,
  zIndex,
}: SectionProps) {
  return (
    <SectionCss marginB={marginB} overflow={overflow} type={type} id={id} zIndex={zIndex}>
      {children}
    </SectionCss>
  );
}

export default Section;
