export function trackPurchase(serviceName, amount) {
  if (window.fbq) {
    window.fbq("track", "Purchase", {
      currency: "USD",
      value: amount,
      content_ids: serviceName,
    });
  } else {
    console.warn("Facebook Pixel не ініціалізовано");
  }
}
