import styled from "@emotion/styled";
import { white, c3, c1, pink, h4, purple, orderText } from "../../assets";
import { Link } from "react-router-dom";

export const PaymentPlanCardWrap = styled.div`
  background-color: ${white};
  padding: 48px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  width: 365px;

  @media (max-width: 1024px) {
    padding: 24px;
  }
  @media (max-width: 768px) {
    padding: 16px;
    max-width: 500px;
    margin: auto;
  }
`;

export const PaymentPlanCardType = styled.p`
  ${c1}
  position: relative;
  font-weight: 400;
  text-align: left;
  width: auto;
`;
export const PaymentPlanCardSvg = styled.svg`
  position: absolute;
  left: 55px;
  top: -3px;
  fill: ${pink};
  transform: rotate(26deg);
  @media (max-width: 1024px) {
  }
`;
export const PaymentPlanCardBody = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
export const PaymentPlanCardPrice = styled.h4`
  ${h4}
  font-weight: 500;
  color: ${purple};
  padding-bottom: 12px;
`;
export const PaymentPlanCardBilling = styled.h4`
  ${orderText}
  font-weight: 400;
  font-style: italic;
`;

export const PaymentPlanCardTrial = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 257px;
  height: 48px;
  background-color: rgba(184, 191, 255, 0.15);
  margin-top: 16px;
  border-radius: 12px;
`;
export const PaymentPlanCardTrialSvg = styled.svg``;
export const PaymentPlanCardTrialText = styled.div`
  padding-left: 5px;
  ${orderText}
  font-weight: 800;
  font-style: italic;
  color: ${purple};
`;
export const PaymentPlanCardFeatures = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
  @media (max-width: 1300px) {
  }
  @media (max-width: 768px) {
  }
`;

export const PaymentPlanCardFeatureItem = styled.li`
  display: flex;
  ${c3}
  font-weight: 500;
  color: #414141;
  line-height: 21.92px;
`;
export const PaymentPlanCardFeatureSvg = styled.svg`
  min-width: 24px;
  min-height: 24px;
  margin-right: 16px;

`;
export const PaymentPlanCardFeatureText = styled.p``;
export const PaymentPlanCardLink = styled(Link)`
  ${c3}
  color: inherit;
  cursor: pointer;
`;
