import styled, { keyframes } from "styled-components";

export const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

export const Label = styled.label`
  position: relative;
  display: flex;
  padding-left: 16px;
  margin: 0.6em 1em;
`;

export const rotate = keyframes`
  from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

export const Indicator = styled.div`
  width: 32px;
  height: 32px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: -15px;
  border: 1px solid #bcbadb;
  border-radius: 6px;
  transform: translateY(-50%);

  ${Input}:checked + & {
    background: #b080ed;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 5px;
    left: 11px;
    width: 8px;
    height: 18px;
    border: solid #fff;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }
`;
