import styled from "@emotion/styled";
import { h3, purple } from "../../assets";

type ListProps = {
  marginB: string;
};
export const FeaturesWrap = styled.div``;
export const FeaturesTitleWrap = styled.div`
  position: relative;
  margin-bottom: 64px;
`;

export const FeaturesTitle = styled.h3`
  ${h3}
  font-weight: 500;
  text-align: center;
  & > span {
    color: ${purple};
  }
`;
export const Svg = styled.svg`
  position: absolute;
  right: -10px;
  top: -35px;
  fill: ${purple};
  transform: rotate(27deg);
  @media (max-width: 768px) {
    top: -50px;
  }
`;
export const FeaturesList = styled.ul<ListProps>`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: ${({ marginB }) => marginB};
  @media (max-width: 1300px) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 32px;
  }
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

export const ListElement = styled.li`
  padding: 0;
  margin: 0;
`;
