import styled from "@emotion/styled";
import {
  inputError,
  white,
  inputText,
  h3,
  buttonText,
} from "../../assets";
import starPink from "../../assets/image/star-shape.png";
import { Link } from "react-router-dom";

type AuthorizationWrapperProps = {
  type: string;
};

export const AuthorizationWrapper = styled.div<AuthorizationWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${(props) =>
    props.type === "signup" || props.type === "login" ? "172px" : "144px"};
  min-height: 100vh;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    background-image: url(${starPink});
    width: 1340px;
    height: 1180px;
    top: 0;
    right: -400px;
    z-index: 0;
    @media (max-width: 1024px) {
      top: 600px;
      transform: rotate(-10deg);
    }
    @media (max-width: 678px) {
      top: 1000px;
    }
  }
`;
export const AuthorizationTitleWrap = styled.div`
  margin-bottom: 48px;
`;

export const AuthorizationTitle = styled.h3`
  ${h3}
  font-weight: 500;
  z-index: 1;
  @media (max-width: 1024px) {
  }
  @media (max-width: 768px) {
  }
`;
export const AuthorizationAdditiionalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 834px;
  width: 100%;
  padding: 48px;
  border-radius: 20px;
  box-shadow: 0px 42px 20px rgba(184, 191, 255, 0.21);
  background-color: ${white};
  z-index: 1;
`;
export const AuthorizationText = styled.p`
  ${inputText}
  line-height: 28px;
`;

export const FormWrap = styled.div`
  overflow: visible;
  position: relative;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 768px) {
  }
`;

export const InputsWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: ${white};
  @media (max-width: 1024px) {
    padding: 24px 32px 64px 32px;
  }
  @media (max-width: 768px) {
    padding: 16px 16px 24px 16px;
  }
`;

export const InputsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;
export const InputRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  row-gap: 24px;
  width: 100%;
`;
export const AdditionalOptions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  margin-top: 8px;
`;
export const TermsLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
export const SecondaryLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
export const CustomLink = styled(Link)`
  text-decoration: none;
  color: #6a00b8;
  font-weight: 700;
`;
export const ResendButton = styled.button`
  color: #6a00b8;
  font-weight: 700;
  background: none;
  border: none;
  cursor: pointer;
`;
export const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const ErrorText = styled.p`
  display: flex;
  ${inputText}
  color: ${inputError};
  text-align: left;
  &::before {
    content: "!";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    text-align: center;
    color: ${inputError};
    border: 1.5px solid ${inputError};
    font-weight: bold;
    border-radius: 50%;
    margin-right: 12px;
  }
`;

export const ErrorIcon = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  text-align: center;
  color: ${inputError};
  border: 1.5px solid ${inputError};
  font-weight: bold;
  border-radius: 50%;
  margin-right: 12px;
`;

export const GoogleButtonWrap = styled.div`
  border-radius: 12px;
  z-index: 1;
`;

export const StyledGoogleeButton = styled.button`
  ${buttonText}
  width: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 12px;
  height: 64px;
  background-color: rgba(184, 191, 255, 0.15);
  transition: background-color 0.3s, transform 0.2s;
`;

export const GoogleImg = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export const PasswordToggleWrapper = styled.div`
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: ${white}
`;

export const HidePasswordSvg = styled.svg``;
