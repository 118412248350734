export const white = "#ffffff";
export const whiteRGBA = "rgba(255, 255, 255, 0.8)";
export const whiteRGBA05 = "rgba(255, 255, 255, 0.5)";
export const lightGray = "#333333";
export const black = "#000000";
export const pink = "#F095FF";
export const green = "#16EA9E";
export const greenRGBA = "rgba(22, 234, 158, 0.16)";
export const yellow = "#F0F424";
export const violet = "#B080ED";
export const purple = "#6A00B8";
export const darkGray = "#2B2F36";
export const darkGrayRGBA = "rgba(43, 47, 54, 0,2)";
export const darkGraRGBA = "#2B2F36";
export const lavender = "#B0B8FF";
export const inputBorder = "#BCBADB";
export const inputError = "#B21E10";
export const includes = "rgba(184, 191, 255, 0.15)";
