import styled, { keyframes } from "styled-components";
import { black, buttonText, d2, purple } from "../../assets";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
type PaymentPlansPeriodsProps = {
  width: number;
  offset: number;
};

export const PaymentPlansListWrap = styled.div`
  position: relative;
  width: 770px;
  margin: auto;
  margin-top: 40px;
  
`;
export const PaymentPlansList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  @media (max-width: 1300px) {
  }
  @media (max-width: 768px) {
  }
`;
export const PaymentPlansInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
`;

export const PaymentPlansCardListElement = styled.li`
  padding: 0;
  margin-top: 32px;
  animation: ${fadeIn} 1s linear;
`;
export const PaymentPlansPeriods = styled.div<PaymentPlansPeriodsProps>`
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 48px;
    width: ${(props) => `${props.width}px`};
    transform: translateX(${(props) => `${props.offset}px`});
    background-color: #f0f424;
    border-radius: 12px;
    transition: all 0.3s linear;
  }
`;
export const PaymentPlansPeriodBtn = styled.button`
  position: relative;
  z-index: 1;
  padding: 13px 24px;
  background-color: transparent;
  border: none;
  ${buttonText};
  cursor: pointer;
  text-transform: uppercase;
`;
export const PaymentPlansText = styled.p`
  ${d2}
  font-weight: 400;
  color: ${black};
  & > span {
    font-weight: 700;
    color: ${purple};
  }
`;
