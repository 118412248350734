import React from "react";
import { Star, StarWrapper } from "./RatingStars.styled";

interface RatingProps {
  rating: number;
  maxStars?: number;
  width: string;
}

const Rating: React.FC<RatingProps> = ({ rating, maxStars = 5, width }) => {
  const fullStars = Math.floor(rating);
  const partialStar = rating - fullStars;
  const stars = Array.from({ length: maxStars }, (_, index) => {
    if (index < fullStars) {
      return <Star key={index} filled={100} width={width} />;
    } else if (index === fullStars) {
      return <Star key={index} filled={partialStar * 100} width={width} />;
    } else {
      return <Star key={index} filled={0} width={width} />;
    }
  });

  return <StarWrapper>{stars}</StarWrapper>;
};

export default Rating;
