import styled from "@emotion/styled";
import patternY from "../../assets/image/pattern-y.png";
import { c1, h5 } from "../../assets";

export const StyledHeroCard = styled.div`
  padding: 80px 16px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #16ea9e;
  background-image: url(${patternY});
  background-size: 600px;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    min-width: 155px;
  }
`;

export const Card = styled.p`
  text-align: center;
  ${h5}
  @media (max-width: 1048px) {
    width: 230px;
  }
  @media (max-width: 768px) {
    ${c1}
    font-weight: 700;
  }
`;
