import {
  StepWrapper,
  StepNumber,
  StepTitle,
  Woohoo,
  WoohooMain,
} from "./TimelineStep.styled";

type TimelineStepProps = {
  stepIndex: number;
  stepNumber: number;
  stepColor: string;
  stepTitle: string;
  stepNumberColor: string;
  stepTitleWidth?: string;
  stepMainTitle?: string;
};

const TimelineStep: React.FC<TimelineStepProps> = ({
  stepNumber,
  stepColor,
  stepTitle,
  stepNumberColor,
  stepTitleWidth,
  stepIndex,
  stepMainTitle,
}) => {
  return (
    <StepWrapper>
      <StepNumber
        stepBgColor={stepColor}
        stepNumber={stepNumber}
        stepNumberColor={stepNumberColor}
      />
      {stepMainTitle ? (
        <WoohooMain>
          {stepMainTitle}
          <span> Woohoo!</span>
        </WoohooMain>
      ) : stepTitle === "Woohoo!" ? (
        <Woohoo>{"Woohoo!"}</Woohoo>
      ) : (
        <StepTitle stepTitleWidth={stepTitleWidth}>{stepTitle}</StepTitle>
      )}
    </StepWrapper>
  );
};

export default TimelineStep;
