import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { menuText, black, menuTextM, h2, h4 } from "../../assets";

export const NavLinkCss = styled(NavLink)`
  ${menuText}
  color: ${black};

  text-align: center;
  @media (max-width: 1024px) {
    ${menuTextM}
  }

  @media (max-width: 768px) {
    ${h2}
  }
  @media (max-width: 480px) {
    ${h4}
  }
`;
