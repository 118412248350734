import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TContentPlan, TContentPlanPost } from "../../types/index";
import {
  ContentPlanResultWrap,
  ContentPlanResultBody,
  ContentPlanResultPreviewList,
  ContentPlanResultPostWrapper,
  PreviewItem,
  PreviewItemWrap,
} from "./ContentPlanGenerator.styled";
import PresentationEditorSlide from "../PresentationEditorSlide/PresentationEditorSlide";
import PresentationEditorNavigation from "../PresentationEditorNavigation";

type SlideData = {
  title: string;
  value: string | TContentPlanPost[];
  id: number;
};

type ContentPlanResultProps = {
  data: TContentPlan;
  handleOpenChangeImagePopup: (index: number) => void;
  handleOpenUploadImagePopup: (index: number) => void;
};

const ContentPlanResult: React.FC<ContentPlanResultProps> = ({
  data,
  handleOpenChangeImagePopup,
  handleOpenUploadImagePopup,
}) => {
  const postRefs = useRef<React.RefObject<ReactQuill>[]>([]);
  const slideRefs = useRef<React.RefObject<ReactQuill>[]>([]);
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [activeRefIndex, setActiveRefIndex] = useState<number>(0);
  const [activeSlideType, setActiveSlideType] = useState<string>("");
  const [loadingPDFGenerating, setLoadingPDFGenerating] =
    useState<boolean>(false);

  const slides = useMemo(() => {
    const slidesData: SlideData[] = [
      { title: "Account goals", value: data.account_goals, id: 2 },
      { title: "Target Audience", value: data.target_audience, id: 3 },
      { title: "Content Types", value: data.content_types, id: 4 },
      { title: "Posts", value: data.posts, id: 4 },
      {
        title: "Stories and Scheduling",
        value: data.additional_information,
        id: data.posts.length + 5,
      },
    ];
    return slidesData;
  }, [
    data.account_goals,
    data.target_audience,
    data.content_types,
    data.posts,
    data.additional_information,
  ]);
  const [formattedPosts, setFormattedPosts] = useState<
    { htmlContent: string; imageUrl?: string; week?: string }[]
  >([]);
  const [formattedSlides, setFormattedSlides] = useState<
    { htmlContent: string }[]
  >([]);

  const handleToolbarRef = (ref: HTMLDivElement | null) => {
    toolbarRef.current = ref;
  };

  useEffect(() => {
    const newPostRefs = data.posts.map(() => React.createRef<ReactQuill>());
    const newSlideRefs = slides
      .filter((slide) => slide.title !== "Posts")
      .map(() => React.createRef<ReactQuill>());

    postRefs.current = newPostRefs;
    slideRefs.current = newSlideRefs;
  }, [data.posts, slides]);

  const handleSlideClick = (index: number, type: string, refIndex: number) => {
    setActiveSlideIndex(index);
    setActiveSlideType(type);
    setActiveRefIndex(refIndex);
  };

  const generateContentPlanPdf = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/pdf/create-content-plan-pdf`;
    const params = {
      acountGoals: formattedSlides[0].htmlContent,
      targetAudience: formattedSlides[1].htmlContent,
      contentTypes: formattedSlides[2].htmlContent,
      storiesSheduling: formattedSlides[3].htmlContent,
      posts: formattedPosts,
    };

    setLoadingPDFGenerating(true);
    try {
      const response = await axios.post(url, params, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "ContentPlan.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoadingPDFGenerating(false);
    }
  };

  const updatePostHtmlContent = (id: number, htmlContent: string) => {
    setFormattedPosts((prevPosts) => {
      const index = id;
      if (index >= 0 && index < prevPosts.length) {
        const item = prevPosts[index];
        if (item.htmlContent !== htmlContent) {
          const updatedPosts = [...prevPosts];
          updatedPosts[index] = { ...item, htmlContent };
          return updatedPosts;
        }
      }
      return prevPosts;
    });
  };

  const updateSlideHtmlContent = (id: number, htmlContent: string) => {
    setFormattedSlides((prevSlides) => {
      let index = 0;
      if (id - 2 > prevSlides.length) {
        index = 3;
      } else {
        index = id - 2;
      }
      if (index >= 0 && index < prevSlides.length) {
        const updatedSlides = [...prevSlides];
        updatedSlides[index] = {
          ...updatedSlides[index],
          htmlContent,
        };
        return updatedSlides;
      }
      return prevSlides;
    });
  };

  const formatPostHtmlContent = (
    id: number | undefined,
    post: TContentPlanPost | undefined
  ): { htmlContent: string; imageUrl?: string; week?: string } => {
    let htmlContent = "";
    let imageUrl;
    let week;

    if (id !== undefined) {
      htmlContent += `<strong>Post #${id}:</strong><br/>`;
    }

    if (post?.post_content_type) {
      htmlContent += `<strong>Content Type:</strong> ${post.post_content_type}<br/>`;
    }

    if (post?.post_text) {
      htmlContent += `<strong>Post Text:</strong> ${post.post_text}<br/>`;
    }

    if (post?.video_script) {
      htmlContent += `<strong>Video Script:</strong> ${post.video_script}<br/>`;
    }

    if (post?.visual_example) {
      imageUrl = post.visual_example;
    }

    if (post?.week) {
      week = post.week;
    }

    return { htmlContent, imageUrl, week };
  };

  const formatText = (text: string): string => {
    return text
      .split("\n")
      .map((line) => `<p>${line}</p>`)
      .join("");
  };

  useEffect(() => {
    const formattedPosts = data.posts.map((post, index) =>
      formatPostHtmlContent(index + 1, post)
    );
    setFormattedPosts(formattedPosts);

    const formatSlideHtmlContent = (
      slide: SlideData
    ): { htmlContent: string } => {
      let htmlContent = "";
      if (typeof slide.value === "string") {
        const formattedSlideText = formatText(slide.value);
        htmlContent += formattedSlideText;
      }
      return { htmlContent };
    };

    const formattedSlides = slides
      .filter((slide) => slide.title !== "Posts")
      .map((slide) => formatSlideHtmlContent(slide));
    setFormattedSlides(formattedSlides);
  }, [data.posts, slides]);

  return (
    <ContentPlanResultWrap>
      <PresentationEditorNavigation
        quillRef={
          activeSlideType === "post"
            ? postRefs.current[activeRefIndex]
            : activeSlideType === "info"
            ? slideRefs.current[activeRefIndex]
            : slideRefs.current[activeRefIndex]
        }
        generatePdf={generateContentPlanPdf}
        loadingPDFGenerating={loadingPDFGenerating}
        formattedPosts={formattedPosts}
        formattedSlides={formattedSlides}
        onToolbarRef={handleToolbarRef}
      />
      <ContentPlanResultBody>
        <ContentPlanResultPreviewList>
          <PreviewItem
            active={activeSlideIndex === 0}
            onClick={() => handleSlideClick(0, "intro", 0)}
          >
            <PreviewItemWrap active={activeSlideIndex === 0} number={1}>
              <PresentationEditorSlide
                id={0}
                type="intro"
                preview={true}
                active={activeSlideIndex === 0}
                updateHtmlContent={updatePostHtmlContent}
                toolbarRef={toolbarRef}
              />
            </PreviewItemWrap>
          </PreviewItem>
          {slides.map((slide, index) => {
            if (slide.title === "Posts" && Array.isArray(slide.value)) {
              return slide.value.map(
                (post: TContentPlanPost, postIndex: number) => (
                  <PreviewItem
                    key={`${index}-${postIndex}`}
                    active={activeSlideIndex === index + postIndex + 1}
                    onClick={() =>
                      handleSlideClick(index + postIndex + 1, "post", postIndex)
                    }
                  >
                    <PreviewItemWrap
                      active={activeSlideIndex === index + postIndex + 1}
                      number={index + postIndex + 2}
                    >
                      <PresentationEditorSlide
                        key={`${index}-${postIndex}`}
                        quillRef={postRefs.current[index]}
                        type="post"
                        preview={true}
                        id={postIndex}
                        active={activeSlideIndex === index + postIndex}
                        post={post}
                        quillData={formattedPosts[postIndex]}
                        updateHtmlContent={updatePostHtmlContent}
                        toolbarRef={toolbarRef}
                      />
                    </PreviewItemWrap>
                  </PreviewItem>
                )
              );
            }

            return (
              <PreviewItem
                key={slide.id}
                active={activeSlideIndex === slide.id - 1}
                onClick={() =>
                  handleSlideClick(
                    slide.id - 1,
                    "info",
                    index === slides.length - 1 ? index - 1 : index
                  )
                }
              >
                <PreviewItemWrap
                  active={activeSlideIndex === slide.id - 1}
                  number={slide.id}
                >
                  <PresentationEditorSlide
                    id={slide.id}
                    preview={true}
                    title={slide.title}
                    type="strategy-slide"
                    active={activeSlideIndex === slide.id - 1}
                    updateHtmlContent={updateSlideHtmlContent}
                    quillData={
                      index === slides.length - 1
                        ? formattedSlides[index - 1]
                        : formattedSlides[index]
                    }
                    quillRef={slideRefs.current[index]}
                    toolbarRef={toolbarRef}
                  />
                </PreviewItemWrap>
              </PreviewItem>
            );
          })}
        </ContentPlanResultPreviewList>
        <ContentPlanResultPostWrapper>
          <PresentationEditorSlide
            id={0}
            type="intro"
            preview={false}
            active={activeSlideIndex === 0}
            updateHtmlContent={updatePostHtmlContent}
            toolbarRef={toolbarRef}
          />
          {slides.map((slide, index) => {
            if (slide.title === "Posts" && Array.isArray(slide.value)) {
              return slide.value.map(
                (post: TContentPlanPost, postIndex: number) => (
                  <PresentationEditorSlide
                    key={`${index}-${postIndex}`}
                    id={postIndex}
                    type="post"
                    preview={false}
                    active={activeSlideIndex === index + postIndex + 1}
                    post={post}
                    quillRef={postRefs.current[postIndex]}
                    quillData={formattedPosts[postIndex]}
                    updateHtmlContent={updatePostHtmlContent}
                    toolbarRef={toolbarRef}
                    handleChangeImage={() =>
                      handleOpenChangeImagePopup(postIndex)
                    }
                    handleOpenUploadImagePopup={() =>
                      handleOpenUploadImagePopup(postIndex)
                    }
                  />
                )
              );
            }

            return (
              <PresentationEditorSlide
                key={slide.id}
                id={slide.id}
                type="strategy-slide"
                preview={false}
                title={slide.title}
                active={activeSlideIndex === slide.id - 1}
                updateHtmlContent={updateSlideHtmlContent}
                toolbarRef={toolbarRef}
                quillData={
                  index === slides.length - 1
                    ? formattedSlides[index - 1]
                    : formattedSlides[index]
                }
                quillRef={
                  index === slides.length - 1
                    ? slideRefs.current[index - 1]
                    : slideRefs.current[index]
                }
              />
            );
          })}
        </ContentPlanResultPostWrapper>
      </ContentPlanResultBody>
    </ContentPlanResultWrap>
  );
};

export default ContentPlanResult;
