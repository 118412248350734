import styled from "@emotion/styled";
import { Form } from "formik";
import {
  c2,
  c3,
  dashboardText,
  popupSmallText,
  popupText,
  popupTitle,
} from "../../assets";

type PopupProps = {
  isOpen: boolean;
};

export const Wrapper = styled.div<PopupProps>`
  position: fixed;
  width: 100vw;
  height: ${(props) => (props.isOpen ? "100vh" : "0vh")};
  background-color: rgba(64, 64, 64, 0.73);
  top: 0;
  left: 0;
  padding: 0 20px;
  transition: 0.3s linear;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  z-index: 999;
`;
export const AdditionalWrapper = styled.div<{ type?: string }>`
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 700px;
  width: 100%;
  max-height: ${(props) => (props.type === "upload-image" ? "696px" : "566px")};
  padding: 40px 40px 64px 40px;
  border-radius: 32px;
  background-color: #fff;
  transform: translate(-50%, -50%);
  @media (max-width: 768px) {
    max-width: 328px;
    max-height: 426px;
    padding: 16px 16px 32px 16px;
  }
`;
export const Img = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 12px;
  object-fit: cover;
  object-position: center;
  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;
export const Title = styled.p`
  ${popupTitle}
  margin: 24px 0;
  @media (max-width: 768px) {
    ${c2}
    margin: 16px 0;
  }
`;

export const Text = styled.p<{ custom?: boolean }>`
  ${(props) =>
    props.custom
      ? `max-width:350px; 
      ${popupSmallText}
      margin-left: auto;
  margin-right: auto;`
      : `${popupText}
      `};
  margin-bottom: 24px;

  @media (max-width: 768px) {
    ${c3}
    margin-bottom:16px;
  }
`;
export const AdditionalText = styled.p`
  ${dashboardText};
  font-weight: 400;
  margin-bottom: 24px;
`;
export const ButtonWrapper = styled.div<{ custom?: boolean }>`
  width: 100%;
  ${(props) =>
    props.custom
      ? `display: flex;
  justify-content: center;
  margin-top:24px;`
      : ``};
`;
export const SpinnerWrapper = styled.div`
  width: 88px;
  height: 88px;
  margin: auto;
`;
export const PopupRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const UploadImagePopupRow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`;
export const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 2px;
    background: #000;
    border-radius: 2px;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
export const CloseImagePopupButton = styled.button`
  position: absolute;
  top: 0;
  right: -120px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 2px;
    background: #000;
    border-radius: 2px;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
