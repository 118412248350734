import React, { useRef, useEffect, useState } from "react";
import ServiceIncItem from "../ServiceIncItem";
import { List } from "./ServiceIncList.styled";

type ServiceIncListProps = { services: string[] };

const ServiceIncList: React.FC<ServiceIncListProps> = ({ services }) => {
  const listRef = useRef<HTMLUListElement | null>(null);
  const [listWidth, setListWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (listRef.current) {
      setListWidth(listRef.current.offsetWidth);
    }
  }, [services]);

  const effectiveListWidth = listWidth ?? 0;

  return (
    <List ref={listRef} count={services.length} listWidth={effectiveListWidth}>
      {services.map((service, index) => {
        return <ServiceIncItem key={index} text={service} />;
      })}
    </List>
  );
};

export default ServiceIncList;
