import React, { useEffect, useState } from "react";
import { Wrap, List, TitleWrap, Title, Item, Img } from "./ImageList.styled";
import Button from "../Button";

type ImageListProps = {
  images: string[];
  title: string;
  link: string;
};

const ImageList: React.FC<ImageListProps> = ({ images, title, link }) => {
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 1024);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSeeAllWorks = () => {
    window.open("https://drive.google.com/file/d/1TUJ4Q61_fRgZB1rjDW_c7-mGWUa-LdAH/view", "_blank", "noopener noreferrer");
  };
  return (
    <Wrap>
      <TitleWrap>
        <Title>{title}</Title>
        {isTablet ? (
          <></>
        ) : (
          <Button
            text="More Works"
            withIcon={false}
            width="180px"
            onClick={handleSeeAllWorks}
          />
        )}
      </TitleWrap>
      <List>
        {images.map((image) => {
          return (
            <Item key={image}>
              <Img src={image} />
            </Item>
          );
        })}
      </List>
      {isTablet ? (
        <Button
          text="More Works"
          withIcon={false}
          width={isMobile ? "320px" : "180px"}
          onClick={handleSeeAllWorks}
        />
      ) : (
        <></>
      )}
    </Wrap>
  );
};

export default ImageList;
