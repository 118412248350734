import { image1, image2, image3, image4 } from "../assets/image/services/index";

const services = [
  {
    img: image1,
    text: "Packaging and label design",
    id: "packaging_design",
  },
  {
    img: image2,
    text: "Brand identity creation",
    id: "brand_identity_creation",
  },
  {
    img: image3,
    text: "Crafting Ads: Script & Storyboard",
    id: "crafting_ads",
  },
  {
    img: image4,
    text: "Advertising Campaign Concept and Design",
    id: "advertising_campaign",
  },
];

export default services
