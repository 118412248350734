import { FC } from "react";
import sprite from "../../icons/symbol-defs.svg";
import { StyledHeroCard, Title, TextWrap, Svg } from "./HeroThirdCard.styled";
import IconCircle from "../IconCircle";

const HeroFirstCard: FC = () => {
  const handleClick = () => {
    smoothScrollTo("services");
  };

  const smoothScrollTo = (scrollTargetId: string) => {
    const element = document.getElementById(scrollTargetId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <StyledHeroCard onClick={handleClick}>
      <TextWrap>
        <Title>
          Explore our services
          <Svg width="35" height="20">
            <use xlinkHref={`${sprite}#vector`}></use>
          </Svg>
        </Title>
        <IconCircle />
      </TextWrap>
    </StyledHeroCard>
  );
};

export default HeroFirstCard;
