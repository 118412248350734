import {
  Wrapp,
  MainTitle,
  Strong,
  // Text, TextNormal,
  Svg,
} from "./AboutUs.styled";
import sprite from "../../icons/symbol-defs.svg";

function AboutUs() {
  return (
    <Wrapp>
      <MainTitle>
        Welcome To <Strong>Woohoo</Strong>! — Your Ultimate Marketing Solution
        <Svg width="69" height="66">
          <use xlinkHref={`${sprite}#stars`}></use>
        </Svg>
      </MainTitle>

      {/* <Text>
        {"Say goodbye to high fees "}
        <TextNormal>
          from traditional marketing agencies. With Woohoo! marketing is easier
          than ever before. <br />
          Enjoy the simplicity of placing an order directly on our website and
          start your project instantly.
        </TextNormal>
        <br />
        Experience the Woohoo! difference today.
      </Text> */}
    </Wrapp>
  );
}

export default AboutUs;
