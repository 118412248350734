import styled from "@emotion/styled";

export const List = styled.ul`
  display: flex;
  gap: 48px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Element = styled.li`
  padding: 0;
  margin: 0;
`;
