import React from "react";
import { TServiceCartList } from "../../types";
import { List, ListElement } from "./ServicePlaningCardList.styled";
import ServiceCard from "../ServiceCard";

type ServiceCardListProps = {
  list: TServiceCartList;
  marginB?: string;
  listType?: "service";
};

const ServiceCardList: React.FC<ServiceCardListProps> = ({
  list,
  marginB = "0",
  listType = "service",
}) => {
  return (
    <List marginB={marginB}>
      {list.map(({ text, id, img }) => {
        return (
          <ListElement key={id}>
            <ServiceCard img={img} text={text} id={id} listType={listType} />
          </ListElement>
        );
      })}
    </List>
  );
};

export default ServiceCardList;
