import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Formik, Form, FormikHelpers } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import sprite from "../../icons/symbol-defs.svg";
import { setUser } from "../../store/slices/userSlice";
import InputComponent from "../InputField";
import GoogleButton from "./GoogleButton";
import Button from "../Button";
import Checkbox from "../Checkbox";
import {
  AuthorizationText,
  InputsWrap,
  InputRow,
  FormWrap,
  TermsLink,
  CustomLink,
  CheckboxWrap,
  ErrorText,
  PasswordToggleWrapper,
  HidePasswordSvg,
} from "./Authorization.styled";

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  agreeToTerms: boolean;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, and one number"
    ),
  agreeToTerms: Yup.boolean()
    .oneOf([true], "Must agree to terms")
    .required("Must agree to terms"),
});

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  const initialValues: FormValues = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    agreeToTerms: false,
  };

  const handleSubmit = async (
    values: FormValues,
    { resetForm, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setError(null);
      await signup(values, resetForm);
    } catch (error) {
      console.error("Signup failed:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const signup = async (formData: FormValues, resetForm: () => void) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/user/signup`;
    try {
      await axios.post(url, {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        password: formData.password,
      });
      const userData = {
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
      };
      dispatch(setUser(userData));
      navigate("/confirm-email");
      resetForm();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data.errors.email) {
            setError("That email is alredy registred");
          }
        }
        console.error("Signup failed:", error.message);
      } else {
        console.error("Signup failed:", error);
        setError("Registration failed. Please try again later.");
      }
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(1));
    const id_token = params.get("id_token");

    const authenticateUser = async (accessToken: string) => {
      try {
        const url = `${process.env.REACT_APP_SERVER_URL}/user/signup-google`;

        const axiosResponse = await axios.post(
          url,
          { accessToken },
          {
            withCredentials: true,
          }
        );

        const userData = {
          email: axiosResponse.data.email,
          firstName: axiosResponse.data.first_name,
          lastName: axiosResponse.data.last_name,
          id: axiosResponse.data._id,
        };
        dispatch(setUser(userData));

        navigate("/payment-plans");
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            if (error.response.data.errors.email) {
              setError("That email is already registered");
            } else if (error.response.data.errors.account) {
              setError("That email is not registered");
            }
          }
          console.error("Authorization failed:", error.message);
        } else {
          console.error("Authorization failed:", error);
          setError("Google authorization failed. Please try again later.");
        }
      }
    };

    if (id_token) {
      authenticateUser(id_token);
    }
  }, [dispatch, navigate]);

  return (
    <>
      <GoogleButton type="signup" />
      <AuthorizationText> or Signup with Email </AuthorizationText>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormWrap>
              <InputsWrap>
                <InputRow>
                  <InputComponent name="firstName" placeholder="First Name" />
                  <InputComponent name="lastName" placeholder="Last Name" />
                  <InputComponent name="email" placeholder="Email" />
                  <div style={{ position: "relative" }}>
                    <InputComponent
                      name="password"
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                    />
                    <PasswordToggleWrapper
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <HidePasswordSvg width="20" height="20">
                        <use
                          xlinkHref={`${sprite}#${
                            showPassword ? "eye" : "eye-off"
                          }`}
                        />
                      </HidePasswordSvg>
                    </PasswordToggleWrapper>
                  </div>
                </InputRow>
                <ErrorMessage name="password" component={ErrorText} />
                <ErrorMessage name="email" component={ErrorText} />
                <ErrorMessage name="agreeToTerms" component={ErrorText} />
                {error && <ErrorText>{error}</ErrorText>}
                <CheckboxWrap>
                  <Checkbox name="agreeToTerms" />
                  <AuthorizationText>
                    By continuing, you agree to our{" "}
                    <TermsLink to="https://app.termly.io/policy-viewer/policy.html?policyUUID=dcafb90d-90c6-4b07-955d-1ac447f91a5f">
                      Terms of Service
                    </TermsLink>
                    .{" "}
                    <TermsLink to="https://app.termly.io/policy-viewer/policy.html?policyUUID=997ef8e0-9529-40fa-a573-2e87e6149829">
                      Read our Privacy Policy.
                    </TermsLink>
                  </AuthorizationText>
                </CheckboxWrap>
                <Button
                  btnType="primary"
                  type="submit"
                  text="Create an Account"
                  withIcon={false}
                />
              </InputsWrap>
            </FormWrap>
          </Form>
        )}
      </Formik>
      <AuthorizationText>
        Already have an account? <CustomLink to="/login">Log in</CustomLink>
      </AuthorizationText>
    </>
  );
};

export default Signup;
