import {
  AuthorizationWrapper,
  AuthorizationTitleWrap,
  AuthorizationTitle,
  AuthorizationAdditiionalWrapper,
} from "./Authorization.styled";
import ConfirmEmail from "./ConfirmEmail";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import Signup from "./Signup";

type AuthorizationProps = {
  type:
    | "signup"
    | "login"
    | "confirm-email"
    | "reset-password"
    | "forgot-password";
};

const Authorization: React.FC<AuthorizationProps> = ({ type }) => {
  return (
    <AuthorizationWrapper type={type}>
      <AuthorizationTitleWrap>
        <AuthorizationTitle>
          {type === "signup"
            ? "Create your account"
            : type === "login"
            ? "Login to your Account"
            : ""}
        </AuthorizationTitle>
      </AuthorizationTitleWrap>
      <AuthorizationAdditiionalWrapper>
        {type === "login" && <Login />}
        {type === "signup" && <Signup />}
        {type === "confirm-email" && <ConfirmEmail />}
        {type === "reset-password" && <ResetPassword />}
        {type === "forgot-password" && <ForgotPassword />}
      </AuthorizationAdditiionalWrapper>
    </AuthorizationWrapper>
  );
};

export default Authorization;
