import HeroFirstCard from "../HeroFirstCard";
import HeroSecondCard from "../HeroSecondCart";
import HeroThirdCard from "../HeroThirdCard";
import sprite from "../../icons/symbol-defs.svg";
import {
  CardList,
  HeroCss,
  HeroTitleWrap,
  HeroTitle,
  Svg,
} from "./Hero.styled";
function Hero() {
  return (
    <HeroCss>
      <HeroTitleWrap>
        <Svg width="69" height="66">
          <use xlinkHref={`${sprite}#stars`}></use>
        </Svg>
        <HeroTitle>Welcome To The Woohoo! Hub</HeroTitle>
      </HeroTitleWrap>

      <CardList>
        <HeroFirstCard />
        <HeroSecondCard />
        <HeroThirdCard />
      </CardList>
    </HeroCss>
  );
}

export default Hero;
