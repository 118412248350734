import { PageLoginWrap } from "./PageLogin.styled";
import Authorization from "../Authorization";
import Section from "../Section";

function PageLogin() {
  return (
    <PageLoginWrap>
      <Section>
        <Authorization type="login" />
      </Section>
    </PageLoginWrap>
  );
}

export default PageLogin;
