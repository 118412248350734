import styled from "@emotion/styled";
import { h3, informerText, violet } from "../../assets";

export const TimerWrap = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 327px;
  background-color: #ffffffba;
  border-radius: 10px;
`;

export const Time = styled.p`
  ${h3}
  font-weight: 700;
`;

export const InformerTime = styled.p`
  ${informerText}
  font-weight: 600;
  color: ${violet};
`;
