import { SpanWrap, SpanCss } from "./IconCircle.styled";

function IconCircle() {
  return (
    <SpanWrap>
      <SpanCss>🪄</SpanCss>
    </SpanWrap>
  );
}

export default IconCircle;
