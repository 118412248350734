import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ErrorMessage, Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import axios from "axios";
import sprite from "../../icons/symbol-defs.svg";
import { setUser } from "../../store/slices/userSlice";
import InputComponent from "../InputField";
import GoogleButton from "./GoogleButton";
import Button from "../Button";
import Checkbox from "../Checkbox";
import {
  AuthorizationText,
  InputsWrap,
  InputsList,
  FormWrap,
  SecondaryLink,
  CustomLink,
  AdditionalOptions,
  CheckboxWrap,
  ErrorText,
  PasswordToggleWrapper,
  HidePasswordSvg,
} from "./Authorization.styled";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
  rememberMe: Yup.boolean().notRequired(),
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  interface FormValues {
    email: string;
    password: string;
    rememberMe: boolean;
  }

  const initialValues: FormValues = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const handleSubmit = async (
    values: FormValues,
    { resetForm, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setError(null);
      await login(values, resetForm);
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const login = async (formData: FormValues, resetForm: () => void) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/user/login`;

    try {
      const response = await axios.post(
        url,
        {
          email: formData.email,
          password: formData.password,
        },
        {
          withCredentials: true,
        }
      );

      const userData = {
        id: response.data._id,
        email: response.data.email,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
      };
      dispatch(setUser(userData));
      navigate("/payment-plans");
      resetForm();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data.errors.email) {
            setError("That email is not registered");
          } else if (error.response.data.errors.password) {
            setError("That password is incorrect");
          }
        }
      } else {
        console.error("Login failed:", error);
        setError("Login failed. Please try again later.");
      }
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(1));
    const id_token = params.get("id_token");

    const authenticateUser = async (accessToken: string) => {
      try {
        const url = `${process.env.REACT_APP_SERVER_URL}/user/login-google`;

        const axiosResponse = await axios.post(
          url,
          { accessToken },
          {
            withCredentials: true,
          }
        );

        const userData = {
          email: axiosResponse.data.email,
          firstName: axiosResponse.data.first_name,
          lastName: axiosResponse.data.last_name,
          id: axiosResponse.data._id,
        };
        dispatch(setUser(userData));

        navigate("/payment-plans");
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            if (error.response.data.errors.email) {
              setError("That email is already registered");
            } else if (error.response.data.errors.account) {
              setError("That email is not registered");
            }
          }
          console.error("Authorization failed:", error.message);
        } else {
          console.error("Authorization failed:", error);
          setError("Google authorization failed. Please try again later.");
        }
      }
    };

    if (id_token) {
      authenticateUser(id_token);
    }
  }, [dispatch, navigate]);

  return (
    <>
      <GoogleButton type="signin" />
      <AuthorizationText>or Login with Email</AuthorizationText>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormWrap>
              <InputsWrap>
                <InputsList>
                  <InputComponent name="email" placeholder="Email"  />
                  <div style={{ position: "relative" }}>
                    <InputComponent
                      name="password"
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                    />
                    <PasswordToggleWrapper
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <HidePasswordSvg width="20" height="20">
                        <use
                          xlinkHref={`${sprite}#${
                            showPassword ? "eye" : "eye-off"
                          }`}
                        />
                      </HidePasswordSvg>
                    </PasswordToggleWrapper>
                  </div>
                  <ErrorMessage name="email" component={ErrorText} />
                  <AdditionalOptions>
                    <CheckboxWrap>
                      <Checkbox name="rememberMe" />
                      <AuthorizationText>Remember me</AuthorizationText>
                    </CheckboxWrap>
                    <SecondaryLink to="/forgot-password">
                      Forgot Password?
                    </SecondaryLink>
                  </AdditionalOptions>
                  {error && <ErrorText>{error}</ErrorText>}
                </InputsList>
                <Button
                  btnType="primary"
                  type="submit"
                  text="Login"
                  withIcon={false}
                />
              </InputsWrap>
            </FormWrap>
          </Form>
        )}
      </Formik>
      <AuthorizationText>
        Not Registered Yet?
        <CustomLink to="/signup"> Create an account</CustomLink>
      </AuthorizationText>
    </>
  );
};

export default Login;
