import styled from "@emotion/styled";
import { c2, h5, h6, preventionText, timelineStepText } from "../../assets";

type TimelineGuideProps = {
  guideColor?: string;
};

export const TimelineWrapper = styled.div`
  margin-bottom: 200px;
  @media (max-width: 1024px) {
    margin-bottom: 128px;
  }
  @media (max-width: 768px) {
    margin-bottom: 48px;
  }
`;
export const TimelineTitle = styled.p`
  ${h5}
  margin-bottom: 48px;
  @media (max-width: 1024px) {
    ${h6}
  }
  @media (max-width: 768px) {
    ${c2}
  }
`;
export const TimelineGuide = styled.div<TimelineGuideProps>`
  position: absolute;
  top: 20px;
  width: 100%;
  max-width: 1312px;
  height: 32px;
  z-index: 0;
  ${(props) =>
    props.guideColor
      ? `background-color: ${props.guideColor};`
      : "background-color: #dbdeff;"}

  border-radius: 100px;

  @media (max-width: 768px) {
    top: -10px;
    left: 12px;
    width: 16px;
    padding: 0;
    height: 105%;
  }
`;
export const TimelineList = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  gap: 2px;
  position: relative;

  > :not(:first-of-type) {
    align-items: center;
  }

  @media (max-width: 1024px) {
    height: 80px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    gap: 10px;
  }
`;
export const TimlinePrevention = styled.div`
  width: 100%;
  height: 104px;
  display: flex;
  align-items: center;
  margin-top: 120px;
  border-radius: 30px;
  background-color: hsla(232, 68%, 85%, 0.15);
  @media (max-width: 768px) {
    margin-top: 48px;
  }
`;

export const Icon = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 33px;
  height: 33px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  background: #b080ed;
  border-radius: 50%;
  margin-right: 24px;
  margin-left: 32px;
  @media (max-width: 768px) {
    margin-right: 10px;
    margin-left: 15px;
  }
`;
export const TimlinePreventionText = styled.p`
  ${preventionText}
  @media (max-width: 768px) {
    ${timelineStepText}
    line-height: 150%;
    margin-right: 10px;
  }
`;
