import React, { useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  TMarketingStrategyHistoryPrompt,
  TMarketingStrategy,
} from "../../types/index";
import sprite from "../../icons/symbol-defs.svg";
import Button from "../Button";
import {
  AIGeneratorProptLabel,
  PromptWrap,
  StyledForm,
  AIGeneratorPromptFormWrap,
  AIGeneratorPromptInputWrap,
  AiGeneratorNotificationRow,
  AiGeneratorNotification,
  NotificationSvg,
  PromptFooter,
} from "./MarketingStrategyGenerator.styled";
import TextareaField from "../TextareaField/TextareaField";

interface MarketingStrategyPromptProps {
  onData: (data: TMarketingStrategy) => void;
  setLoading: (loading: boolean) => void;
  setTechnicalError: (error: boolean) => void;
  setGeneratorLimit: (isLimited: boolean) => void;
  prompt: TMarketingStrategyHistoryPrompt | null;
}

interface FormValues {
  product_description: string;
}

const validationSchema = Yup.object().shape({
  product_description: Yup.string().required("Required"),
});

const MarketingStrategyPrompt: React.FC<MarketingStrategyPromptProps> = ({
  onData,
  setLoading,
  setTechnicalError,
  setGeneratorLimit,
  prompt,
}) => {
  const initialValues: FormValues = {
    product_description: prompt?.productDescription || "",
  };

  const [isAvailableGenerator, setIsAvailableGenerator] = useState(false);
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/user/get-user-info`,
          {},
          {
            withCredentials: true,
          }
        );
        if (response.data.usageLimits.marketingStrategies) {
          setIsAvailableGenerator(
            response.data.usageLimits.marketingStrategies
          );
        }
      } catch (error) {
        console.error("Error fetching user information:", error);
      } finally {
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    if (!isAvailableGenerator) {
      try {
        setLoading(true);
        const result = await generateMarketingStrategy(values);
        onData(result);
      } catch (error) {
        console.error("Error:", error);
        setTechnicalError(true);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    } else {
      setGeneratorLimit(true);
    }
  };

  const generateMarketingStrategy = async (formData: FormValues) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/openai/marketing-strategy-generator`;
    try {
      const response = await axios.post(url, {
        productDescription: formData.product_description,
        userId: user.id,
      });
      return response.data as TMarketingStrategy;
    } catch (error) {
      console.error("Request failed:", error);
      setTechnicalError(true);
      return {
        _id: "",
        target_market: "",
        target_audience: "",
        key_competitors: "",
        smart: "",
        unique_selling_proposition: "",
        marketing_mix_4p: "",
        digital_strategy: "",
        implementation_plan: "",
        budget: "",
        measurements: "",
        crisis_plan: "",
      } as TMarketingStrategy;
    }
  };

  return (
    <PromptWrap>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ handleSubmit, setValues }) => (
          <StyledForm onSubmit={handleSubmit}>
            <AIGeneratorPromptFormWrap>
              <AIGeneratorPromptInputWrap>
                <AIGeneratorProptLabel>
                  Describe your product:
                </AIGeneratorProptLabel>
                <TextareaField
                  name="product_description"
                  placeholder="Describe your product"
                />
              </AIGeneratorPromptInputWrap>
            </AIGeneratorPromptFormWrap>
            <PromptFooter>
              <Button
                btnType="primary"
                type="submit"
                text="Generate"
                withIcon={false}
              />
              <AiGeneratorNotificationRow>
                <NotificationSvg width="24" height="24">
                  <use xlinkHref={`${sprite}#clock`} />
                </NotificationSvg>
                <AiGeneratorNotification>
                  Please note that the personalized model training process
                  initiated by clicking this button will take approximately{" "}
                  <span>2 minutes</span>
                </AiGeneratorNotification>
              </AiGeneratorNotificationRow>
            </PromptFooter>
          </StyledForm>
        )}
      </Formik>
    </PromptWrap>
  );
};

export default MarketingStrategyPrompt;
