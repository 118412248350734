import React from "react";
import {
  CardWrap,
  CardAdditionlWrapper,
  CardBody,
  TitleWrap,
  Title,
  Img,
} from "./AIGeneratorFeaturesCard.styled";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

type AIGeneratorFeaturesCardProps = {
  img: string;
  text: string;
  id: number;
  link: string;
};

const AIGeneratorFeaturesCard: React.FC<AIGeneratorFeaturesCardProps> = ({
  img,
  text,
  id,
  link,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  return (
    <CardWrap>
      <CardAdditionlWrapper>
        <Img src={img} alt="courses" />
        <CardBody>
          <TitleWrap>
            <Title>{text}</Title>
          </TitleWrap>
          <Button text="Get Started" onClick={handleClick} />
        </CardBody>
      </CardAdditionlWrapper>
    </CardWrap>
  );
};

export default AIGeneratorFeaturesCard;
