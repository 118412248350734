import { CheckoutInformerWrap, SummaryText, Icon } from "./Banners.styled";
function CheckoutInformer() {
  return (
    <CheckoutInformerWrap>
      <Icon>!</Icon>
      <SummaryText>
        Please note: A prepayment for 8 hours of work is required to begin any
        project
      </SummaryText>
    </CheckoutInformerWrap>
  );
}

export default CheckoutInformer;
