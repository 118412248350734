import styled from "@emotion/styled";
import { accentText, c4, purple, titleM, white } from "../../assets";

type CardProps = {};

export const FeaturesCardWrap = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 16px 38px;
  background-color: ${white};
  border-radius: 12px;
`;
export const FeaturesCardTitle = styled.p`
  ${c4}
`;
export const UsedCreditsNumber = styled.p`
  ${titleM}
  color: ${purple}
`;
export const RemainingCredits = styled.p`
  ${accentText}
  &>span {
    color: ${purple};
    font-weight: 700;
  }
`;
