import styled from "@emotion/styled";

type ListProps = {
  marginB: string;
};

export const List = styled.ul<ListProps>`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: ${({ marginB }) => marginB};
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 32px;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 24px;
  }
`;

export const ListElement = styled.li`
  padding: 0;
  margin: 0;
`;
