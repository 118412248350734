import { useState, useEffect } from "react";
import { ErrorMessage, Formik, Form, FormikHelpers } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import sprite from "../../icons/symbol-defs.svg";
import InputComponent from "../InputField";
import Button from "../Button";
import {
  SecondaryLink,
  AuthorizationTitle,
  AuthorizationText,
  InputsWrap,
  FormWrap,
  ErrorText,
  PasswordToggleWrapper,
  HidePasswordSvg,
} from "./Authorization.styled";

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
      "Password must contain at least one letter and one number"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Required"),
});

const ResetPassword = () => {
  const [error, setError] = useState<string | null>(null);
  const [reset, setReset] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { session } = useParams();
  const navigate = useNavigate();

  interface FormValues {
    newPassword: string;
    confirmPassword: string;
  }

  const initialValues: FormValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const handleSubmit = async (
    values: FormValues,
    { resetForm, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      resetPassword(values);
      resetForm();
      setReset(true);
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  const resetPassword = async (formData: FormValues) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/user/reset-new-password/${session}`;

    try {
      await axios.post(url, {
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword,
      });
      navigate("/login");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data.error) {
            setError("That email is not registered");
          }
        }
        console.error("Request for password reset failed:", error.message);
      } else {
        console.error("Request for password reset failed:", error);
        setError("Request for password reset failed. Please try again later.");
      }
      console.error("Password reset failed:", error);
    }
  };

  return (
    <>
      <AuthorizationTitle>Reset Password</AuthorizationTitle>
      <AuthorizationText>
        Enter your new password below to reset your account password.
      </AuthorizationText>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormWrap>
              <InputsWrap>
                <div style={{ position: "relative" }}>
                  <InputComponent
                    name="newPassword"
                    placeholder="New Password"
                    type={showNewPassword ? "text" : "password"}
                  />
                  <PasswordToggleWrapper
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    <HidePasswordSvg width="20" height="20">
                      <use
                        xlinkHref={`${sprite}#${
                          showNewPassword ? "eye" : "eye-off"
                        }`}
                      />
                    </HidePasswordSvg>
                  </PasswordToggleWrapper>
                </div>
                <div style={{ position: "relative" }}>
                  <InputComponent
                    name="confirmPassword"
                    placeholder="Confirm New Password"
                    type={showConfirmPassword ? "text" : "password"}
                  />
                  <PasswordToggleWrapper
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    <HidePasswordSvg width="20" height="20">
                      <use
                        xlinkHref={`${sprite}#${
                          showConfirmPassword ? "eye" : "eye-off"
                        }`}
                      />
                    </HidePasswordSvg>
                  </PasswordToggleWrapper>
                </div>
                <ErrorMessage name="newPassword" component={ErrorText} />
                <ErrorMessage name="confirmPassword" component={ErrorText} />
                {error && <ErrorText>{error}</ErrorText>}
                <Button
                  btnType="primary"
                  type="submit"
                  text="Reset Password"
                  withIcon={false}
                />
              </InputsWrap>
            </FormWrap>
          </Form>
        )}
      </Formik>
      <SecondaryLink to="/login">Back to Login</SecondaryLink>
    </>
  );
};

export default ResetPassword;
