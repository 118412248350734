import LoadingSpinner from "../LoadingSpinner";
import { PageLoadingWrap } from "./PageLoading.styled";

function PageLoading() {
  return (
    <PageLoadingWrap>
      <LoadingSpinner width="300px" height="300px" />
    </PageLoadingWrap>
  );
}

export default PageLoading;
