import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import type { RootState } from "../../store/store";
import { addTransaction } from "../../store/slices/transactionsSlice";
import { clearCart } from "../../store/slices/cartSlice";
import { trackPurchase } from "../../utils/facebookPixel";
import AboutUs from "../AboutUs";
import Hero from "../Hero";
import OurServices from "../OurServices";
import Section from "../Section";
import Form from "../OrderForm";
import BannerContainer from "../Banners";
import Timeline from "../Timeline";
import Popup from "../Popup";
import CustomerReviews from "../CustomerReviews";
import axios from "axios";
import { ServicePageData } from "../../types/ServicePage";

const timeline = [
  {
    stepNumber: 1,
    stepColor: "#F095FF",
    stepTitle: "Add the service to your cart",
    stepNumberColor: "#000",
    stepTitleWidth: "160px",
  },
  {
    stepNumber: 2,
    stepColor: "#B080ED",
    stepTitle: "Check out in the top right corner",
    stepNumberColor: "#000",
    stepTitleWidth: "140px",
  },
  {
    stepNumber: 3,
    stepColor: "#16EA9E",
    stepTitle: "Easy payment",
    stepNumberColor: "#000",
    stepTitleWidth: "130px",
  },
  {
    stepNumber: 4,
    stepColor: "#6A00B8",
    stepTitle: "Our manager will email you within 1 minute",
    stepNumberColor: "#fff",
    stepTitleWidth: "190px",
  },
  {
    stepNumber: 5,
    stepColor: "#F0F424",
    stepTitle: "Woohoo!",
    stepMainTitle:
      "Fill out the brief and we'll send you the first version of the project within 48 hours.",
    stepNumberColor: "#000",
  },
];

function PageHome() {
  const location = useLocation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const transactions = useSelector(
    (state: RootState) => state.transactions.transactions
  );
  const cartItems = useSelector((state: RootState) => state.cart.products);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const successParam = searchParams.get("success");
    const transactionIdParam = searchParams.get("transaction_id");

    const existingTransaction = transactions.find(
      (transaction) => transaction.transactionId === transactionIdParam
    );

    if (transactionIdParam && successParam && !existingTransaction) {
      dispatch(
        addTransaction({
          transactionId: transactionIdParam,
          status: successParam,
        })
      );
    }

    if (successParam === "true" && !existingTransaction) {
      const fetchData = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/products/get-products-by-ids`,
            {
              ids: cartItems,
            }
          );

          const fetchedData = response.data.products || [];
          const validData = fetchedData.filter(
            (item: ServicePageData) => item && typeof item.mainImg === "string"
          );

          const amount = validData.reduce(
            (acc: number, item: ServicePageData) => acc + item.price,
            0
          );
          const discountedAmount = amount * 0.9;
          const serviceName = validData
            .map((item: ServicePageData) => item.title)
            .join(", ");

          trackPurchase(serviceName, discountedAmount);
          dispatch(clearCart());
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };

      fetchData();
      setIsPopupOpen(true);
    }
  }, [dispatch, location.search, transactions, cartItems]);

  const clickPopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <Section>
        <Hero />
      </Section>

      <Section>
        <AboutUs />
      </Section>

      <Section>
        <Timeline
          timeLine={timeline}
          timelineTitle="5 simple steps to start your project:"
          guideColor="rgba(255, 255, 255, 0.34)"
        />
      </Section>

      <Section marginB="48px" id="services">
        <OurServices />
      </Section>

      <Section marginB="48px" id="services">
        <CustomerReviews />
      </Section>

      <Section>
        <BannerContainer bannerType="test" background={"white"} />
      </Section>

      <Section overflow="hidden" marginB="0">
        <Form />
      </Section>
      {isPopupOpen && (
        <Popup
          popupType="application-successful"
          isOpen={isPopupOpen}
          onClose={clickPopup}
        />
      )}
    </>
  );
}

export default PageHome;
