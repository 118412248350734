import React, { useEffect, useRef, useState } from "react";
import {
  acceptedExt,
  checkType,
  getFileSizeMB,
  checkImageAspectRatio,
} from "./utils";
import sprite from "../../icons/symbol-defs.svg";
import DrawTypes from "./DrawTypes";
import useDragging from "./useDragging";
import {
  Description,
  DescriptionWrapper,
  HoverMsg,
  UploaderWrapper,
} from "./InputFile.styled";

type Props = {
  name?: string;
  hoverTitle?: string;
  types?: Array<string>;
  $uploadertype?: string;
  classes?: string;
  children?: JSX.Element;
  maxSize?: number;
  minSize?: number;
  fileOrFiles?: Array<File> | File | null;
  disabled?: boolean;
  label?: string | undefined;
  multiple?: boolean;
  required?: boolean;
  reset: boolean;
  onSizeError?: (arg0: string) => void;
  onTypeError?: (arg0: string) => void;
  setErrorUploading?: () => void;
  onDrop?: (arg0: File | Array<File>) => void;
  onSelect?: (arg0: File | Array<File> | null) => void;
  handleChange?: (arg0: File | Array<File> | null) => void;
  onDraggingStateChange?: (dragging: boolean) => void;
  onValidationError?: (error: string) => void;
  dropMessageStyle?: React.CSSProperties | undefined;
};
/**
 *
 * Draw a description on the frame
 * @param currFile - The uploaded file
 * @param uploaded - boolean to check if the file uploaded or not yet
 * @param typeError - boolean to check if the file has type errors
 * @param disabled - boolean to check if input is disabled
 * @param label - string to add custom label
 * @returns JSX Element
 *
 * @internal
 *
 */
const drawDescription = (
  currFile: Array<File> | File | null,
  uploaded: boolean,
  typeError: boolean,
  disabled: boolean | undefined,
  label: string | undefined,
  $uploadertype?: string
) => {
  return typeError ? (
    <span>File type/size error, Hovered on types!</span>
  ) : (
    <>
      {$uploadertype === "image" ? (
        <svg width="32px" height="32px">
          <use xlinkHref={`${sprite}#image`} />
        </svg>
      ) : (
        <></>
      )}
      <Description>
        {disabled ? (
          <span>Upload disabled</span>
        ) : !currFile && !uploaded ? (
          $uploadertype === "image" ? (
            <>
              Drop images here or click to <span>upload</span>
            </>
          ) : (
            <>
              {label ? (
                <>
                  <span>{label.split(" ")[0]}</span>{" "}
                  <span>{label.slice(label.indexOf(" ") + 1)}</span>
                </>
              ) : (
                <>
                  <span>Upload</span> or drop a file right here
                </>
              )}
            </>
          )
        ) : (
          <>
            <span>Uploaded Successfully!</span> Upload another?
          </>
        )}
      </Description>
    </>
  );
};

/**
 * File uploading main function
 * @param props - {name,
    hoverTitle,
    types,
    handleChange,
    classes,
    children,
    maxSize,
    minSize,
    fileOrFiles,
    onSizeError,
    onTypeError,
    onSelect,
    onDrop,
    onTypeError,
    disabled,
    label,
    multiple,
    required,
    onDraggingStateChange
  }
 * @returns JSX Element
 */
const FileUploader: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    name,
    hoverTitle,
    types,
    $uploadertype,
    handleChange,
    classes,
    children,
    maxSize,
    minSize,
    fileOrFiles,
    onSizeError,
    onTypeError,
    onSelect,
    onDrop,
    disabled,
    label,
    multiple,
    required,
    reset,
    onValidationError,
    onDraggingStateChange,
    dropMessageStyle,
  } = props;
  const labelRef = useRef<HTMLLabelElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploaded, setUploaded] = useState(false);
  const [currFiles, setFile] = useState<Array<File> | File | null>(null);
  const [error, setError] = useState(false);

  const validateFile = async (file: File): Promise<boolean> => {
    if (types && !checkType(file, types)) {
      setError(true);
      if (onTypeError) onTypeError("File type is not supported");
      return false;
    }

    if (maxSize && getFileSizeMB(file.size) > maxSize) {
      setError(true);
      if (onSizeError) onSizeError("File size is too big");
      return false;
    }

    if (minSize && getFileSizeMB(file.size) < minSize) {
      setError(true);
      if (onSizeError) onSizeError("File size is too small");
      return false;
    }

    if ($uploadertype === "image") {
      try {
        const isSquare = await checkImageAspectRatio(file);

        if (!isSquare) {
          setError(true);
          if (onSizeError) onSizeError("Image aspect ratio is not 1:1");
          if (onValidationError) onValidationError("size-error");
          return false;
        }
      } catch (error) {
        setError(true);
        if (onSizeError) onSizeError("Failed to check image aspect ratio");
        if (onValidationError) onValidationError("size-error");
        return false;
      }
    }

    return true;
  };

  const handleChanges = async (files: File | Array<File>): Promise<boolean> => {
    if (!files) {
      setUploaded(false);
      setFile(null);
      setError(false);
      if (handleChange) handleChange(null);
      return false;
    }

    let checkError = false;

    if (files instanceof File) {
      const isValid = await validateFile(files);
      checkError = !isValid;
    } else {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const isValid = await validateFile(file);
        checkError = !isValid || checkError;
      }
    }

    if (checkError) {
      setFile(null);
      setError(true);
      setUploaded(false);
      if (handleChange) handleChange(null);
      return false;
    }

    if (handleChange) handleChange(files);
    setFile(files);
    setUploaded(true);
    setError(false);
    return true;
  };

  const blockEvent = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
  };
  const handleClick = (ev: any) => {
    ev.stopPropagation();
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.click();
    }
  };

  const handleInputChange = async (ev: any) => {
    const allFiles = ev.target.files;
    const files = multiple ? Array.from(allFiles) : allFiles[0];
    const success = await handleChanges(files);
    if (onSelect && success) onSelect(files);
  };

  const handleChangesSync = (files: File | Array<File>): boolean => {
    let isValid = false;
    handleChanges(files).then((result) => {
      isValid = result;
    });
    return isValid;
  };

  const dragging = useDragging({
    labelRef,
    inputRef,
    multiple,
    handleChanges: handleChangesSync,
    onDrop,
  });

  useEffect(() => {
    onDraggingStateChange?.(dragging);
  }, [dragging, onDraggingStateChange]);

  useEffect(() => {
    if (fileOrFiles) {
      setUploaded(true);
      setFile(fileOrFiles);
    } else {
      if (inputRef.current) inputRef.current.value = "";
      setUploaded(false);
      setFile(null);
    }
  }, [fileOrFiles]);

  useEffect(() => {
    if (!fileOrFiles) {
      setUploaded(false);
      setFile(null);
      setError(false);
    }
  }, [fileOrFiles]);

  const handleFormReset = () => {
    setFile(null);
    setUploaded(false);
    setError(false);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  useEffect(() => {
    if (reset) {
      handleFormReset();
    }
  }, [reset]);
  return (
    <UploaderWrapper
      overRide={children}
      className={`${classes || ""} ${disabled ? "is-disabled" : ""}`}
      ref={labelRef}
      htmlFor={name}
      onClick={blockEvent}
      $uploadertype={$uploadertype}
    >
      <input
        onClick={handleClick}
        onChange={handleInputChange}
        accept={acceptedExt(types)}
        ref={inputRef}
        type="file"
        name={name}
        disabled={disabled}
        multiple={multiple}
        required={required}
      />
      {dragging && (
        <HoverMsg style={dropMessageStyle}>
          <span>{hoverTitle || "Drop Here"}</span>
        </HoverMsg>
      )}
      {!children && (
        <>
          <DescriptionWrapper
            $warning={error ? "true" : "false"}
            $uploadertype={$uploadertype}
          >
            {drawDescription(
              currFiles,
              uploaded,
              error,
              disabled,
              label,
              $uploadertype
            )}
            {$uploadertype ? (
              <></>
            ) : (
              <DrawTypes types={types} minSize={minSize} maxSize={maxSize} />
            )}
          </DescriptionWrapper>
        </>
      )}
      {children}
    </UploaderWrapper>
  );
};
export default FileUploader;
