import styled from "@emotion/styled";
import { Form } from "formik";
import { c1, dashboardText, white } from "../../assets";

export const StyledForm = styled(Form)`
  padding: 40px 40px 64px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: ${white};
  border-radius: 32px;
  @media (max-width: 1024px) {
    padding: 24px 32px 64px 32px;
  }
  @media (max-width: 768px) {
    padding: 16px 16px 24px 16px;
  }
`;

export const BackdropTitle = styled.p`
  text-align: left;
  ${c1}
`;

export const BackdropSubtitle = styled.p`
  ${dashboardText}
  font-weight:400;
  text-align: left;
`;

export const InputsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
