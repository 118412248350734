import React from "react";
import type { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { ServicePageData } from "../../types";
import sprite from "../../icons/symbol-defs.svg";
import {
  Wrap,
  Title,
  Description,
  Img,
  TitleWrap,
  SubTitle,
  Svg,
  PriceWrap,
  PriceText,
  PriceBlock,
  CurrentPrice,
  OldPrice,
  PriceAdditionalWrap,
  // PriceDescription,
} from "./HeroServices.styled";
import { removeProduct, addProduct } from "../../store/slices/cartSlice";
import Button from "../Button";
import BannerContainer from "../Banners/BannerContainer";

type HeroServicesProps = {
  data: ServicePageData;
};

const HeroServices: React.FC<HeroServicesProps> = ({ data }) => {
  const { id, title, mainImg, description, subtitle } = data;

  const dispatch = useDispatch();
  const cartItems = useSelector((state: RootState) => state.cart.products);

  const handleProduct = (productId: string) => {
    if (!cartItems.includes(productId)) {
      dispatch(addProduct(productId));
    } else {
      dispatch(removeProduct(productId));
    }
  };
  const buttonText = cartItems.includes(id) ? "Added to cart" : "Add To Cart";
  const active = cartItems.includes(id);

  const handleClick = () => {
    handleProduct(id);
  };

  const calculateDiscountedPrice = (originalPrice: number): number => {
    const discountRate = 0.1;
    const discountedPrice = originalPrice * (1 - discountRate);
    return discountedPrice;
  };

  return (
    <Wrap>
      <div>
        <TitleWrap>
          <Title>{title}</Title>
          <Svg width="69" height="66">
            <use xlinkHref={`${sprite}#stars`}></use>
          </Svg>
        </TitleWrap>

        {subtitle && <SubTitle>{subtitle}</SubTitle>}
        <Description>{description}</Description>
        <PriceWrap>
          <PriceAdditionalWrap>
            <PriceText>Price per hour:</PriceText>
            <PriceBlock>
            <OldPrice>${(data.price).toFixed()}</OldPrice>
            <CurrentPrice>
              ${(calculateDiscountedPrice(data.price)).toFixed()}
            </CurrentPrice>
          </PriceBlock>
          </PriceAdditionalWrap>
            {/* <PriceDescription>
              Minimum 8 hours, subject to scope and revisions{" "}
            </PriceDescription> */}
        </PriceWrap>
        <BannerContainer
          bannerType="info-timer"
          background="blue"
          marginB="32px;"
        />
        <Button onClick={handleClick} text={buttonText} active={active} />
      </div>

      <Img src={mainImg} alt="mainImg" />
    </Wrap>
  );
};

export default HeroServices;
