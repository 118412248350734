import styled from "@emotion/styled";
import {
  black,
  c1,
  c2,
  c3,
  h3,
  h6,
  oldPriceText,
  orderSubtitle,
  subtotalText,
  titleM,
  white,
} from "../../assets/";
import starPink from "../../assets/image/star-shape.png";

interface OrderAdditionalWrapperProps {
  empty: boolean;
}

export const OrderWrapper = styled.div`
  min-height: 100vh;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    background-image: url(${starPink});
    width: 1340px;
    height: 1180px;
    top: 0;
    right: -400px;
    z-index: 0;
    @media (max-width: 1024px) {
      top: 600px;
      transform: rotate(-10deg);
    }
    @media (max-width: 678px) {
      top: 1000px;
    }
  }
`;

export const OrderPreviewTitle = styled.h3`
  padding-top: 170px;
  margin-bottom: 48px;
  ${h3};
  @media (max-width: 1024px) {
    padding-top: 132px;
    ${titleM}
    font-weight: 500;
    margin-bottom: 33px;
  }
  @media (max-width: 768px) {
    padding-top: 96px;
    ${h6}
    font-weight: 500;
    text-align: left;
  }
`;
export const OrderAdditionalWrapper = styled.div<OrderAdditionalWrapperProps>`
  max-width: 834px;
  width: 100%;
  padding: 48px;
  border-radius: 20px;
  box-shadow: 0px 42px 20px rgba(184, 191, 255, 0.21);
  background-color: ${white};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  margin-top: ${(props) => (props.empty ? "132px" : "0px")};
  position: relative;
  z-index: 1;
  @media (max-width: 1024px) {
    padding: 48px 32px;
  }
  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 48px;
    margin-top: ${(props) => (props.empty ? "95px" : "0px")};
  }
  ${(props) =>
    props.empty
      ? `
        margin-top: 132px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 40px 40px 64px 40px;
        @media (max-width: 768px) {
        gap: 16px;
        padding: 16px 16px 32px 16px;

  }
        `
      : ""}
`;
export const OrderSubtitle = styled.p`
  ${orderSubtitle}
  text-align:left;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    ${c3}
    font-weight: 500;
  }
`;
export const OrderSummary = styled.div`
  padding-top: 32px;
  padding-bottom: 24px;
  @media (max-width: 768px) {
    padding: 48px 16px 32px 16px;
  }
`;
export const ContactInfo = styled.div`
  width: 100%;
`;

export const InputsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const OrderSummaryList = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  min-height: 160px;
  gap: 24px;
  padding-bottom: 8px;
  transition: height 0.3s linear;
  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 0px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bcbadb;
  margin: 24px 0;
`;
export const Subtotal = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
`;
export const SubtotalText = styled.p`
  ${subtotalText}
`;
export const SubtotalPrice = styled.p`
  ${subtotalText}
`;
export const Total = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
`;
export const TotalText = styled.p`
  ${c1}
`;
export const Discount = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const DiscountText = styled.p`
  ${subtotalText}
`;
export const DiscountValue = styled.p`
  ${subtotalText}
`;
export const TotalPriceBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  color: #333333;
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

export const TotalPrice = styled.p`
  ${c1}
  font-weight: 600;
`;
export const OldPrice = styled.p`
  ${oldPriceText}
  text-decoration: line-through;
`;
export const OrderIcon = styled.svg`
  position: absolute;
  z-index: 999;
  background-color: ${black};
`;
export const EmptyCartImg = styled.img`
  margin: auto;
  @media (max-width: 768px) {
    width: 100px;
  }
`;
export const EmptyCartTitle = styled.p`
  ${c1}
  font-weight: 500;
  @media (max-width: 768px) {
    ${c2}
  }
`;
export const EmptyCartText = styled.p`
  ${c2}
  @media (max-width: 768px) {
    ${c3}
  }
`;
