import { useState, FC } from "react";
import axios from "axios";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import InputComponent from "../InputField";
import Button from "../Button";
import Popup from "../Popup";
import {
  StyledForm,
  InputsList,
  BackdropTitle,
  BackdropSubtitle,
} from "./ContactUs.styled";

interface FormValues {
  name: string;
  email: string;
  message: string;
}

const initialValues: FormValues = {
  name: "",
  email: "",
  message: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  message: Yup.string().max(2000, "Too Long!"),
});

const ContactUs: FC = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (
    values: FormValues,
    { resetForm, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setLoading(true);
    try {
      await sendMail(values);
      setSuccessMessage(true);
      resetForm();
    } catch (error) {
      console.error("Error sending email:", error);
      setSuccessMessage(false);
    } finally {
      setLoading(false);
      setIsPopupOpen(true);
      setSubmitting(false);
    }
  };

  const clickPopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const sendMail = async (formData: FormValues) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/nodemailer/send_email`;
    const form = new FormData();
    form.append("name", formData.name);
    form.append("email", formData.email);
    form.append("type", "application");
    form.append("description", formData.message);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    await axios.post(url, form, config);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit}>
            <BackdropTitle>Contact Us</BackdropTitle>
            <BackdropSubtitle>
              If you have any questions or need assistance, please feel free to
              contact us.
              <span>We are here to help!</span>
            </BackdropSubtitle>
            <InputsList>
              <InputComponent name="name" placeholder="Name" />
              <InputComponent name="email" placeholder="Email" />
              <InputComponent name="message" placeholder="Message" />
            </InputsList>
            <Button
              btnType="primary"
              type="submit"
              margin="0px"
              text={loading ? "Loading..." : "Send"}
              withIcon={false}
              disabled={loading}
            />
          </StyledForm>
        )}
      </Formik>
      {isPopupOpen && (
        <Popup
          popupType={
            successMessage ? "application-successful" : "application-error"
          }
          isOpen={isPopupOpen}
          onClose={clickPopup}
        />
      )}
    </>
  );
};

export default ContactUs;
