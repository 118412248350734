import styled, { css } from "styled-components";
import { inputBorder } from "../../assets";
const darkGray = "#666",
  lightGrayOld = "#999";

const defaultStyle = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  border: dashed 1px ${inputBorder};
  padding: 20px;
  border-radius: 16px;
  cursor: pointer;
  flex-grow: 0;
  background-image: linear-gradient(
      to right,
      ${inputBorder},
      rgba(255, 255, 255, 0) 0%
    ),
    linear-gradient(${inputBorder}, rgba(255, 255, 255, 0) 0%);
  background-position: bottom, right;
  background-size: 8px 3px, 8px 3px;
  background-repeat: repeat-x, repeat-y;
  &.is-disabled {
    border: dashed 2px ${darkGray};
    cursor: no-drop;
  }
`;
export const UploaderWrapper = styled.label<any>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  border-radius: 16px;
  border: 1px solid #bcbadb;
  ${(props) =>
    props.$uploadertype
      ? `
      
    `
      : ` ${props.overRide ? "" : defaultStyle};`};
  &:focus-within {
    outline: 2px solid black;
  }
  & > input {
    display: block;
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
`;
/**
 *
 * @internal
 */
export const HoverMsg = styled.div`
  border: dashed 2px ${darkGray};
  border-radius: 16px;
  background-color: ${lightGrayOld};
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;
/**
 *
 * @internal
 */
export const DescriptionWrapper = styled.div<{
  $warning: string;
  $uploadertype?: string;
}>`
  ${(props) =>
    props.$uploadertype
      ? `
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;
`
      : `
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`}
  & > span {
    font-size: 12px;
    color: ${(props) => (props.$warning === "true" ? "red" : darkGray)};
  }
  .file-types {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
  }
`;
/**
 *
 * @internal
 */
export const Description = styled.span`
  font-size: 14px;
  color: ${darkGray};
  span {
    text-decoration: underline;
  }
`;
