import { FC } from "react";
import { StyledHeroCard, Card } from "./HeroSecondCard.styled";

const HeroFirstCard: FC = () => {
  return (
    <StyledHeroCard>
      <Card>300+ projects</Card>
    </StyledHeroCard>
  );
};

export default HeroFirstCard;
