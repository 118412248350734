import React from "react";
import { useNavigate } from "react-router-dom";
import type { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { CardWrap, DescriptionText, Img, CustomLink } from "./ServiceCard.styled";
import Button from "../Button";
import { removeProduct, addProduct } from "../../store/slices/cartSlice";

type ServiceCardProps = {
  img: string;
  text: string;
  id: string;
  listType?: "service" | "developments";
};

const ServiceCard: React.FC<ServiceCardProps> = ({
  img,
  text,
  id,
  listType = "service",
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const cartItems = useSelector((state: RootState) => state.cart.products);

  const handleProduct = (productId: string) => {
    if (!cartItems.includes(productId)) {
      dispatch(addProduct(productId));
    } else {
      dispatch(removeProduct(productId));
    }
  };

  const handleClick = () => {
    handleProduct(id);
  };
  const handleOpenServicePage = () => {
    navigate(`/services/${id}`)
  };

  const buttonText = cartItems.includes(id) ? "Added to cart" : "Add To Cart";
  const active = cartItems.includes(id);

  return (
    <>
      <CardWrap listType={listType}>
        <Img src={img} alt="service" onClick={handleOpenServicePage}/>
        <DescriptionText listType={listType}>{text}</DescriptionText>

        {listType === "service" && (
          <Button onClick={handleClick} text={buttonText} active={active} />
        )}
        {listType === "developments" && (
          <Button
            onClick={handleClick}
            text={buttonText}
            btnType={"normal"}
            active={active}
          />
        )}
        {listType === "service" && (
          <CustomLink
            to={`/services/${id}`}
            color="pink"
            >About this service</CustomLink>
        )}

        {listType === "developments" && (
          <CustomLink
            to={`/services/${id}`}
            color="yellow"
          >About this service</CustomLink>
        )}
      </CardWrap>
    </>
  );
};

export default ServiceCard;
