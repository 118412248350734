import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { TContentPlan, TSocialMediaPost } from "../../types";
import { RootState } from "../../store/store";
import { AIHistoryWrapper, AIHistoryTitle } from "./AIHistory.styled";
import AIHistoryList from "../AIHistoryList";

type DashboarProps = {};

const AIHistory: React.FC<DashboarProps> = () => {
  const user = useSelector((state: RootState) => state.user);
  const [requestsData, setRequestsData] = useState<
    (TContentPlan | TSocialMediaPost)[]
  >([]);

  useEffect(() => {
    if (user && user.id) {
      const getUserRequests = async () => {
        const url = `${process.env.REACT_APP_SERVER_URL}/requests-history/get-user-requests`;
        try {
          const response = await axios.post(url, {
            userId: user.id,
          });
          setRequestsData(response.data);
        } catch (error) {
          console.error("Request failed:", error);
        }
      };

      getUserRequests();
    }
  }, [user]);

  return (
    <AIHistoryWrapper>
      <AIHistoryTitle>History</AIHistoryTitle>
      <AIHistoryList data={requestsData} />
    </AIHistoryWrapper>
  );
};

export default AIHistory;
