import styled from "@emotion/styled";
import {
  timelineStepText,
  black,
  yellow,
  timelineLogo,
  footerTextM,
  white,
} from "../../assets";
import { timelineStepTextM } from "../../assets/typography";

type StepTitleProps = {
  stepTitleWidth?: string;
};
type StepNumberProps = {
  stepNumberColor: string;
  stepBgColor: string;
  stepNumber: number;
};

export const StepWrapper = styled.div`
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    gap: 16px;
  }
`;

export const StepTitle = styled.p<StepTitleProps>`
  ${timelineStepText}
  line-height:25px;
  color: ${black};
  text-align: left;
  margin-top: 11px;
  ${(props) =>
    props.stepTitleWidth
      ? `max-width: ${props.stepTitleWidth};`
      : `max-width: 110px;`}

  max-height: 80px;
  color: ${black};
  text-align: left;
  @media (max-width: 768px) {
    max-width: 100%;
    ${timelineStepTextM}
  }
`;
export const Woohoo = styled.p`
  ${timelineLogo}
  width: 130px;
  margin-top: 11px;
  @media (max-width: 1024px) {
    ${timelineStepText}
  }
  @media (max-width: 768px) {
    text-align: left;
  }
`;
export const WoohooMain = styled.p`
  ${timelineStepText}
  margin-top: 11px;
  text-align: left;
  font-weight: 500;
  line-height: 18px;
  max-width: 200px;
  width: 100%;
  span {
    font-weight: 700;
    color: ${yellow};
  }
  @media (max-width: 768px) {
    max-width: 70%;
  }
`;

export const StepNumber = styled.p<StepNumberProps>`
  ${timelineStepText}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.stepNumberColor};
  max-width: 72px;
  min-width: 72px;
  min-height: 72px;
  max-height: 72px;
  background: #ffffff82;
  border: 1px solid ${white};
  border-radius: 50%;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    background-color: ${white};
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  &:after {
    content: "${(props) => `${props.stepNumber}`}";
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.stepBgColor};
    border-radius: 50%;
    transform: translateY(-50%);
  }
  @media (max-width: 768px) {
    ${footerTextM}

    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    border: 1px solid #fff;
  }
`;
