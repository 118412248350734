import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TMarketingStrategy } from "../../types/index";
import {
  MarketingStrategyResultWrap,
  MarketingStrategyResultBody,
  MarketingStrategyResultPreviewList,
  MarketingStrategyResultPostWrapper,
  PreviewItem,
  PreviewItemWrap,
} from "./MarketingStrategyGenerator.styled";
import PresentationEditorSlide from "../PresentationEditorSlide/PresentationEditorSlide";
import PresentationEditorNavigation from "../PresentationEditorNavigation";

type MarketingStrategyResultProps = {
  data: TMarketingStrategy;
  marketingStrategySlides?: SlideData[];
};

type SlideData = {
  title: string;
  value: string;
  id: number;
};

const MarketingStrategyResult: React.FC<MarketingStrategyResultProps> = ({
  data,
  marketingStrategySlides,
}) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [loadingPDFGenerating, setLoadingPDFGenerating] =
    useState<boolean>(false);
  const quilSlidesRefs = useRef<React.RefObject<ReactQuill>[]>([]);
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const [slideHistory, setSlideHistory] = useState<number[]>([]);

  const [formattedSlides, setFormattedSlides] = useState<
    { htmlContent: string }[]
  >([]);

  const handleToolbarRef = (ref: HTMLDivElement | null) => {
    toolbarRef.current = ref;
  };

  const formatMarketingStrategyHtml = (value: string | undefined): string => {
    if (!value) return "";
    return value
      .split("\n")
      .map((line) => `<p>${line}</p>`)
      .join("");
  };

  useEffect(() => {
    if (marketingStrategySlides)
      quilSlidesRefs.current = marketingStrategySlides.map(() =>
        React.createRef<ReactQuill>()
      );
  }, [marketingStrategySlides]);

  const handleSlideClick = (index: number) => {
    setActiveSlideIndex(index);
    setSlideHistory((prevHistory) => [...prevHistory, index]);
  };

  const generateMarketingStrategyPdf = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/pdf/create-content-plan-pdf`;
    const params = {
      type: "marketing-strategy",
      slides: marketingStrategySlides,
      htmlSlides: formattedSlides,
    };
    setLoadingPDFGenerating(true);
    try {
      const response = await axios.post(url, params, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      });
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "MarketingStrategy.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoadingPDFGenerating(false);
    }
  };

  useEffect(() => {
    if (!slideHistory.includes(activeSlideIndex)) {
      setSlideHistory((prev) => [...prev, activeSlideIndex]);
    }
  }, [activeSlideIndex, slideHistory, marketingStrategySlides]);

  const updateHtmlContent = (id: number, htmlContent: string) => {
    setFormattedSlides((prevPosts) => {
      const updatedPosts = [...prevPosts];
      const index = id - 2;
      if (index >= 0 && index < updatedPosts.length) {
        updatedPosts[index] = { htmlContent };
      }
      return updatedPosts;
    });
  };

  useEffect(() => {
    if (marketingStrategySlides) {
      const formatted = marketingStrategySlides.map((slide, index) => ({
        htmlContent: formatMarketingStrategyHtml(slide.value as string),
      }));
      setFormattedSlides(formatted);
    }
  }, [data, marketingStrategySlides]);

  return (
    <MarketingStrategyResultWrap>
      <PresentationEditorNavigation
        quillRef={quilSlidesRefs.current[activeSlideIndex - 1]}
        generatePdf={generateMarketingStrategyPdf}
        loadingPDFGenerating={loadingPDFGenerating}
        formattedSlides={formattedSlides}
        onToolbarRef={handleToolbarRef}
      />
      <MarketingStrategyResultBody>
        <MarketingStrategyResultPreviewList>
          <PreviewItem
            active={activeSlideIndex === 0}
            onClick={() => handleSlideClick(0)}
          >
            <PreviewItemWrap active={activeSlideIndex === 0} number={1}>
              <PresentationEditorSlide
                id={0}
                type="intro"
                preview={true}
                active={activeSlideIndex === 0}
                updateHtmlContent={updateHtmlContent}
                toolbarRef={toolbarRef}
              />
            </PreviewItemWrap>
          </PreviewItem>
          {marketingStrategySlides?.map((slide, index) => (
            <PreviewItem
              key={index}
              active={activeSlideIndex === index + 1}
              onClick={() => handleSlideClick(index + 1)}
            >
              <PreviewItemWrap
                active={activeSlideIndex === index + 1}
                number={index + 2}
              >
                <PresentationEditorSlide
                  id={index + 2}
                  type="strategy-slide"
                  preview={true}
                  title={slide.title}
                  active={activeSlideIndex === index + 2}
                  updateHtmlContent={updateHtmlContent}
                  quillData={formattedSlides[index]}
                  quillRef={quilSlidesRefs.current[index]}
                  toolbarRef={toolbarRef}
                />
              </PreviewItemWrap>
            </PreviewItem>
          ))}
        </MarketingStrategyResultPreviewList>
        <MarketingStrategyResultPostWrapper>
          <PresentationEditorSlide
            key={0}
            id={0}
            type="intro"
            preview={false}
            active={activeSlideIndex === 0}
            updateHtmlContent={updateHtmlContent}
            toolbarRef={toolbarRef}
          />

          {marketingStrategySlides?.map((slide, index) => (
            <PresentationEditorSlide
              key={index}
              id={index + 2}
              type="strategy-slide"
              preview={false}
              title={slide.title}
              active={activeSlideIndex === index + 1}
              updateHtmlContent={updateHtmlContent}
              quillData={formattedSlides[index]}
              quillRef={quilSlidesRefs.current[index]}
              toolbarRef={toolbarRef}
            />
          ))}
        </MarketingStrategyResultPostWrapper>
      </MarketingStrategyResultBody>
    </MarketingStrategyResultWrap>
  );
};

export default MarketingStrategyResult;
