import React, { useEffect, useState } from "react";
import { TimerWrap, Time, InformerTime } from "./Timer.styled";

const formatTime = (seconds: number, type: string): string => {
  if (type === "informer") {
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${minutes}:${secs}`;
  } else {
    const hours = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${secs}`;
  }
};

interface TimerProps {
  type: string;
}

const Timer: React.FC<TimerProps> = ({ type }) => {
  const initialTime = 30 * 60 - 1;

  const [time, setTime] = useState<number>(() => {
    const storedTime = localStorage.getItem("timer");
    return storedTime ? parseInt(storedTime, 10) : initialTime;
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        const newTime = prevTime - 1;
        localStorage.setItem("timer", newTime.toString());
        return newTime;
      });
    }, 1000);

    const handleUnload = () => {
      localStorage.removeItem("timer");
    };

    window.addEventListener("unload", handleUnload);

    return () => {
      clearInterval(timer);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  if (type === "informer") {
    return <InformerTime>{formatTime(time, type)} minutes!</InformerTime>;
  } else if (type === "sale") {
    return (
      <TimerWrap>
        <Time>{formatTime(time, type)}</Time>
      </TimerWrap>
    );
  }
  return null;
};

export default Timer;
