import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "../../store/store";
import { toggleMenu } from "../../store/slices/menuSlice";
import { NavLinkCss } from "./MobileNavLink.styled";

interface IMobileNavLinkProps {
  to: string;
  text: string;
  scroll?: string;
}

const MobileNavLinkComponent: FC<IMobileNavLinkProps> = ({
  to,
  text,
  scroll,
}) => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state: RootState) => state.menu.isOpen);

  const handleMenuButtonClick = () => {
    if (isMenuOpen) {
      dispatch(toggleMenu());
    }
    if (scroll) {
      smoothScrollTo(scroll);
    }
  };

  const smoothScrollTo = (scrollTargetId: string) => {
    const element = document.getElementById(scrollTargetId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <NavLinkCss onClick={handleMenuButtonClick} to={to}>
      {text}
    </NavLinkCss>
  );
};

export default MobileNavLinkComponent;
