import { FC } from "react";
import howItWorkPreview from "../../assets/image/howItWorkPreview.png";
import {
  HowWorkAiGeneratorTitle,
  HowWorkAiGeneratorText,
} from "./HowWorkAiGenerator.styled";
import VideoPlayerWithPreview from "../VideoPlayerWithPreview";

const HowWorkAiGenerator: FC = () => {
  return (
    <>
      <HowWorkAiGeneratorTitle>How it works</HowWorkAiGeneratorTitle>
      <HowWorkAiGeneratorText>
        Explore a sample content plan to see the quality and detail you can
        expect from our generator.
      </HowWorkAiGeneratorText>
      <VideoPlayerWithPreview
        link="https://www.youtube.com/embed/G4Sx54M93Y0?si=4yO5g62dsGGY9GpX"
        preview={howItWorkPreview}
      />
    </>
  );
};

export default HowWorkAiGenerator;
