import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  TContentPlan,
  TContentPlanHistory,
  TContentPlanPost,
  TContentPlanHistoryPrompt,
} from "../../types/index";
import {
  ContentPlanWrap,
  ContentPlanPromptWrap,
} from "./ContentPlanGenerator.styled";
import ContentPlanPrompt from "./ContentPlanPrompt";
import ContentPlanResult from "./ContentPlanResult";
import Popup from "../Popup";

interface ContentPlanGeneratorProps {
  data: TContentPlanHistory | undefined;
  type: string | undefined;
}

const ContentPlanGenerator: React.FC<ContentPlanGeneratorProps> = ({
  data,
  type,
}) => {
  const [contentPlanData, setContentPlanData] = useState<TContentPlan | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [loadintContentType, setLoadintContentType] =
    useState<string>("content-plan");
  const [errorPopup, setErrorPopupOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [changeImagePopup, setChangeImagePopupOpen] = useState<boolean>(false);
  const [isAvailableGenerator, setIsAvailableGenerator] = useState(false);
  const [uploadImagePopup, setUploadImagePopupOpen] = useState<boolean>(false);
  const [currentPostIndex, setCurrentPostIndex] = useState<number | null>(null);
  const [promptHistory, setPromptHistory] =
    useState<TContentPlanHistoryPrompt | null>(null);

  useEffect(() => {
    if (data && type) {
      if (type === "result" && data.contentPlan?.result) {
        const { result } = data.contentPlan;
        setContentPlanData({
          _id: data._id,
          account_goals: result.accountGoals || "",
          additional_information: result.additionalInformation || "",
          content_types: result.contentTypes || "",
          target_audience: result.targetAudience || "",
          posts: result.posts || [],
          requestGeneratorType: data.requestGeneratorType || "",
          requestDate: data.requestDate || "",
          errors: null,
        });
      } else if (type === "prompt") {
        setPromptHistory({
          contentPlanDuration: data.contentPlan.prompt.contentPlanDuration,
          productDescription: data.contentPlan.prompt.productDescription,
          socialMediaType: data.contentPlan.prompt.socialMediaType,
        });
      }
    }
  }, [data, type]);

  const generateImageWithRetry = async (
    description: string,
    postContentType: string,
    requestId: string,
    index: number,
    customImage: boolean
  ): Promise<{ imageUrl: string; customImage: boolean }> => {
    const url = `${process.env.REACT_APP_SERVER_URL}/openai/image-generator`;
    const initialDelay = 1000;
    const maxAttempts = 100;

    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      try {
        const response = await axios.post<{
          image_url: string;
          customImage: boolean;
        }>(url, {
          imageDescription: description,
          type: postContentType,
          requestId,
          historyType: "Content Plan",
          postIndex: index,
          customImage: customImage,
        });

        if (response.data.image_url) {
          return {
            imageUrl: response.data.image_url,
            customImage: response.data.customImage,
          };
        } else {
          throw new Error("No image URL returned");
        }
      } catch {
        if (attempt === maxAttempts - 1) {
          throw new Error("Max retries exceeded");
        }
        await new Promise((resolve) => setTimeout(resolve, initialDelay));
      }
    }

    throw new Error("Failed to generate image after multiple retries");
  };

  const handleContentPlanData = (data: TContentPlan) => {
    setContentPlanData(data);
    setLoading(false);
    if (data.errors) {
      setErrorMessage("request-error");
      setErrorPopupOpen(true);
    } else if (data.posts) {
      handleImageGenerationForPosts(data.posts, data._id);
    }
  };
  const handleImageChange = useCallback((postIndex: number) => {
    setCurrentPostIndex(postIndex);
    setChangeImagePopupOpen(true);
  }, []);
  const handleUploadImageChange = useCallback((postIndex: number) => {
    setCurrentPostIndex(postIndex);
    setUploadImagePopupOpen(true);
  }, []);

  const setTechnicalError = useCallback((error: boolean) => {
    if (error) {
      setErrorMessage("technical-error");
      setErrorPopupOpen(true);
    }
  }, []);

  const handleImageGenerationForPosts = async (
    posts: TContentPlanPost[],
    requestId: string
  ) => {
    setLoading(true);
    setLoadintContentType("image");
    try {
      for (let index = 0; index < posts.length; index++) {
        const post = posts[index];
        if (
          (post.post_content_type === "Image" &&
            post.visual_example_text &&
            !post.visual_example) ||
          (post.post_content_type === "Video" &&
            post.video_script &&
            !post.visual_example)
        ) {
          const description =
            post.visual_example_text || post.video_script || "";

          try {
            const response = await generateImageWithRetry(
              description,
              post.post_content_type,
              requestId,
              index,
              false
            );
            posts[index] = {
              ...post,
              visual_example: response.imageUrl,
              customImage: response.customImage,
            };

            setContentPlanData((prevData) =>
              prevData
                ? {
                    ...prevData,
                    posts: prevData.posts.map((p, i) =>
                      i === index
                        ? {
                            ...post,
                            visual_example: response.imageUrl,
                            customImage: response.customImage,
                          }
                        : p
                    ),
                  }
                : null
            );
          } catch (error) {
            console.error(`Failed to generate image for post ${index}:`, error);
          }
        }
      }
    } catch (error) {
      console.error("Error generating images for posts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (values: {
    file: File | null;
  }): Promise<string> => {
    const { file } = values;
    setFileLoading(true);
    if (!file) {
      throw new Error("No file provided");
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("requestId", contentPlanData?._id ?? "");
    const postIndex =
      currentPostIndex !== null ? currentPostIndex.toString() : "0";
    formData.append("postIndex", postIndex);

    const url = `${process.env.REACT_APP_SERVER_URL}/requests-history/update-post-with-user-image`;

    try {
      const response = await axios.post<{
        imageUrl: string;
        customImage: boolean;
      }>(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.imageUrl) {
        setUploadImagePopupOpen(false);
        setContentPlanData((prevData) =>
          prevData
            ? {
                ...prevData,
                posts: prevData.posts.map((p, i) =>
                  i === currentPostIndex
                    ? {
                        ...p,
                        visual_example: response.data.imageUrl,
                        customImage: response.data.customImage,
                      }
                    : p
                ),
              }
            : null
        );
        return response.data.imageUrl;
      } else {
        throw new Error("No image URL returned");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      throw new Error("Failed to upload file");
    } finally {
      setFileLoading(false);
    }
  };

  const onValidationError = (error: string) => {
    setErrorMessage(error);
    setErrorPopupOpen(true);
  };

  const handleImageGeneration = async (values: {
    image_description: string;
  }) => {
    setLoading(true);
    setLoadintContentType("image");
    setChangeImagePopupOpen(false);
    try {
      if (contentPlanData?._id !== undefined && currentPostIndex !== null) {
        const response = await generateImageWithRetry(
          values.image_description,
          "UserPromptImage",
          contentPlanData._id,
          currentPostIndex,
          true
        );

        setContentPlanData((prevData) =>
          prevData
            ? {
                ...prevData,
                posts: prevData.posts.map((p, i) =>
                  i === currentPostIndex
                    ? {
                        ...p,
                        visual_example: response.imageUrl,
                        customImage: response.customImage,
                      }
                    : p
                ),
              }
            : null
        );
      }
    } catch (error) {
      console.error(
        `Failed to generate image for post ${currentPostIndex}:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContentPlanWrap>
      {(contentPlanData && !errorMessage) ||
      (contentPlanData && errorMessage === "size-error") ? (
        <ContentPlanResult
          data={contentPlanData}
          handleOpenChangeImagePopup={handleImageChange}
          handleOpenUploadImagePopup={handleUploadImageChange}
        />
      ) : (
        <ContentPlanPromptWrap>
          <ContentPlanPrompt
            onData={handleContentPlanData}
            setLoading={setLoading}
            setGeneratorLimit={setIsAvailableGenerator}
            setTechnicalError={setTechnicalError}
            prompt={promptHistory}
          />
        </ContentPlanPromptWrap>
      )}
      <Popup
        popupType="loading"
        isOpen={loading}
        contentType={loadintContentType}
      />
      <Popup
        popupType="image-prompt"
        isOpen={changeImagePopup}
        contentType="social media post"
        onSubmitPrompt={handleImageGeneration}
        onClose={() => setChangeImagePopupOpen(false)}
      />
      <Popup
        popupType="upload-image"
        isOpen={uploadImagePopup}
        loading={fileLoading}
        onSubmitUpload={handleFileUpload}
        onClose={() => setUploadImagePopupOpen(false)}
        onValidationError={onValidationError}
      />
      <Popup
        popupType="application-error"
        isOpen={isAvailableGenerator}
        onClose={() => setIsAvailableGenerator(false)}
      />
      {errorMessage && (
        <>
          <Popup
            popupType={errorMessage}
            isOpen={errorPopup}
            onClose={() => setErrorPopupOpen(false)}
          />
        </>
      )}
    </ContentPlanWrap>
  );
};

export default ContentPlanGenerator;
