import React from "react";
import { useNavigate } from "react-router-dom";
import aiHero from "../../assets/image/ai_hero.png";
import sprite from "../../icons/symbol-defs.svg";
import {
  TooltipWrap,
  TooltipIcon,
  TooltipText,
  Wrap,
  Title,
  Description,
  Img,
  TitleWrap,
  ImgWrap,
  Svg,
} from "./HeroAiGenerator.styled";
import Button from "../Button";

type HeroCoursesProps = {};

type TooltipProps = {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  text: string;
  icon: string;
  fill: string;
};

const Tooltip: React.FC<TooltipProps> = ({
  text,
  icon,
  top,
  bottom,
  left,
  right,
  fill,
}) => {
  return (
    <TooltipWrap top={top} bottom={bottom} left={left} right={right}>
      <TooltipIcon fill={fill}>
        <use xlinkHref={`${sprite}#${icon}`}></use>
      </TooltipIcon>
      <TooltipText>{text}</TooltipText>
    </TooltipWrap>
  );
};

const HeroCourses: React.FC<HeroCoursesProps> = () => {
  const navigate = useNavigate();
  const tooltips = [
    {
      text: "just 10 minutes",
      icon: "stars",
      bottom: "136px",
      left: "-102px",
      fill: "#B080ED",
    },
    {
      text: "Generating plan",
      icon: "circle",
      top: "71px",
      right: "-69px",
      fill: "none",
    },
    {
      text: "First 3 days free!",
      icon: "star",
      bottom: "-32px",
      right: "-44px",
      fill: "",
    },
  ];

  const handleClick = () => {
    navigate("/dashboard");
  };

  return (
    <Wrap>
      <TitleWrap>
        <Svg width="69" height="66">
          <use xlinkHref={`${sprite}#stars`}></use>
        </Svg>
        <Title>What will you generate today?</Title>
      </TitleWrap>
      <Description>
        Discover a seamless way to elevate your brand's presence with our tool,
        which generates a ready-to-use content plan for your social networks and
        marketing strategy.
      </Description>
      <Button text="Test it now" width="337px" onClick={handleClick} />
      <ImgWrap>
        <Img src={aiHero} alt="mainImg" />
        {tooltips.map((item, index) => (
          <Tooltip
            key={index}
            text={item.text}
            icon={item.icon}
            top={item.top}
            bottom={item.bottom}
            left={item.left}
            right={item.right}
            fill={item.fill}
          />
        ))}
      </ImgWrap>
    </Wrap>
  );
};

export default HeroCourses;
