import styled from "@emotion/styled";
import { c2, h5, h6 } from "../../assets";

export const Wrap = styled.div``;
export const Title = styled.div`
  ${h5}
  font-weight: 500;
  margin-right: 16px;
  @media (max-width: 1024px) {
    ${h6}
  }
  @media (max-width: 768px) {
    ${c2}
  }
`;
export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 64px;
  @media (max-width: 1024px) {
    margin-bottom: 24px;
  }
`;
export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  @media (max-width: 1024px) {
    margin-bottom: 24px;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }
`;

export const Item = styled.li`
  width: 100%;
  height: auto;
  border-radius: 12px;
  overflow: hidden;
  @media (max-width: 1024px) {
    /*height: 447px;*/
    height: auto;
  }
  @media (max-width: 768px) {
    height: auto;
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
`;
