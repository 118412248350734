import styled from "@emotion/styled";
import { h5, h6, violet } from "../../assets";
import { labelText } from "../../assets/typography";

export const ReviewsTitle = styled.div`
  ${h5}
  font-weight: 500;
  margin-right: 16px;
  & > span {
    color: ${violet};
    @media (max-width: 768px) {
      display: block;
    }
  }
  @media (max-width: 1024px) {
    ${h6}
  }
`;
export const ReviewsMainInfo = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  margin: 24px 0;
  @media (max-width: 480px) {
    align-items: center;
  }
`;
export const ReviewsRatingNumber = styled.div`
  ${h5}
  @media (max-width: 768px) {
    ${h6}
  }
`;
export const ReviewsNumber = styled.p`
  ${labelText}
  font-weight: 400;
`;
export const RatingAddittionalWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;
export const ReviewsWrap = styled.div``;
export const ReviewsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const ReviewsMoreButtonBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;
