import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { black, c3, d1, dashboardText, h4, purple, white } from "../../assets";

type PlanDetailsProps = {};

export const PlanDetailsWrapper = styled.div<PlanDetailsProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const PlanDetailsTitle = styled.p<PlanDetailsProps>`
  ${h4}
  text-align:left;
  margin-bottom: 24px;
`;
export const PlanDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
`;
export const PlanDetailsAdditionalWrap = styled.div`
  padding: 16px 16px 32px 16px;
  border-radius: 20px;
  background-color: ${white};
  width: 985px;
  margin-bottom: 24px;
`;
export const PlanDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PlanDetailsCustomRow = styled.div`
  display: flex;
  align-items: center;
  gap: 120px;
`;
export const PlanDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const PlanDetailsCancel = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
`;
export const PlanDetailsSubtitle = styled.p`
  ${d1}
  text-align: left;
`;
export const PlanDetailsText = styled.div`
  ${dashboardText}
  text-align: left;
  font-weight: 400;
  & > span {
    text-decoration: underline;
  }
`;
export const PlanDetailsUpgrateToPro = styled(Link)`
  ${dashboardText}
  color: ${black};
  text-decoration: underline;
  margin-right: 16px;
`;
export const PlanDetailsCardNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 24px 0;
`;
export const PlanDetailsPlanType = styled.div<{ type: string | undefined }>`
  display: flex;
  gap: 16px;
  align-items: center;
  ${c3}
  padding: 12px 32px;
  ${(props) =>
    props.type === "suspended" || props.type === "expired"
      ? `
      color: #EB001B;
  background-color:  #EB001B0D;
`
      : `
  color: ${purple};
  background-color: #b8bfff26;`}
  border-radius: 12px;
`;
export const Divider = styled.div`
  border-bottom: 2px solid #b8bfff6e;
  margin: 24px 0;
`;
export const PlanDetailsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
`;
export const PlanDetailsFeaturesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
`;
export const PlanDetailsListItem = styled.li`
  display: flex;
  ${dashboardText}
  font-weight: 400;
  p > span {
    font-weight: 600;
  }
`;
export const PlanDetailsFeaturesItem = styled.li`
  display: flex;
  ${dashboardText}
  font-weight: 400;
`;
export const DetailSvg = styled.svg`
  fill: #fff;
  margin-right: 16px;
`;
export const UpdateSvg = styled.svg`
  margin-right: 16px;
`;
export const PlanDetailsActionText = styled.p`
  display: inline-block;
  font-weight: 700;
  color: ${purple};
`;
export const Svg = styled.svg`
  fill: ${purple};
  transform: rotate(16deg);
`;
export const TrialDays = styled.p`
  color: ${purple};
  font-weight: 700;
`;
export const NewLine = styled.p`
  display: flex;
  & > span {
    font-weight: 600;
  }
`;
