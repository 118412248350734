import styled from "@emotion/styled";
import { c3, d1, includes } from "../../assets";

export const Wrap = styled.li`
  background-color: ${includes};
  border-radius: 32px;
  padding: 32px;

  display: block;
`;

export const Svg = styled.svg`
  margin-bottom: 16px;
`;

export const Text = styled.p`
  ${d1}
  width:70%;
  @media (max-width: 1024px) {
    width:80%;

  }
  @media (max-width: 768px) {
    ${c3}
  }
`;
