import styled from "@emotion/styled";

export const PageServiceWrap = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(176, 184, 255, 1) 670px
  );
  @media (max-width: 768px) {
    background-image: linear-gradient(
      to bottom,
      #ffffff 0%,
      #b0b8ff 14%,
      #ffffff 100%
    );
  }

  /* padding-bottom: 120px;

  @media (max-width: 1024px) {
    padding-bottom: 80px;
  }

  @media (max-width: 768px) {
    padding-bottom: 48px;
  } */
`;

export const AdditionalWrap = styled.div`
  height: auto;
  overflow-x: hidden;
`;
