import { useState } from "react";
import sprite from "../../icons/symbol-defs.svg";
import {
  PlayButton,
  PreviewImage,
  VideoIframe,
  VideoPreview,
  VideoWrapper,
  PlaySvg,
} from "./VideoPlayerWithPreview.styled";

type AIGeneratorFeaturesProps = {
  preview: string;
  link: string;
};

const VideoPlayerWithPreview: React.FC<AIGeneratorFeaturesProps> = ({
  preview,
  link,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <VideoWrapper>
      {!isPlaying ? (
        <VideoPreview onClick={handlePlay}>
          <PreviewImage src={preview} alt="Video preview" />
          <PlayButton>
            {" "}
            <PlaySvg>
              <use xlinkHref={`${sprite}#play`}></use>
            </PlaySvg>
          </PlayButton>
        </VideoPreview>
      ) : (
        <VideoIframe
          width="560"
          height="315"
          src={link}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          frameBorder="0"
        />
      )}
    </VideoWrapper>
  );
};

export default VideoPlayerWithPreview;
