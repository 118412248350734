import { PageResetPasswordWrap } from "./PageResetPassword.styled";
import Section from "../Section";
import Authorization from "../Authorization";

function PageResetPassword() {
  return (
    <PageResetPasswordWrap>
      <Section overflow="hidden" marginB="0">
        <Authorization type="reset-password" />
      </Section>
    </PageResetPasswordWrap>
  );
}

export default PageResetPassword;
