import sprite from "../../icons/symbol-defs.svg";
import Timer from "../Timer";
import {
  SaleWrap,
  SaleText,
  SaleBannerTimer,
  SaleStrong,
  SaleSvg,
} from "./Banners.styled";
function SaleBanner() {
  return (
    <SaleWrap>
      <SaleText>
        Hurry! <SaleStrong>10%</SaleStrong> Off For The Next 29:59 Minutes
      </SaleText>
      <SaleBannerTimer>
        <Timer type="sale" />
      </SaleBannerTimer>
      <SaleSvg width="52" height="50">
        <use xlinkHref={`${sprite}#stars`}></use>
      </SaleSvg>
    </SaleWrap>
  );
}

export default SaleBanner;
