import styled from "@emotion/styled";
import {
  white,
  lightGray,
  c1,
  c3,
  black,
  structure,
  step,
  substep,
  c2,
  oldPriceText,
} from "../../assets";

type StructureProps = {
  isHovered: boolean;
  maxHeight?: number;
};
type CardProps = {
  isHovered: boolean;
  height?: number;
  lastChild?: boolean;
};
type CardWrappProps = {
  overlay: boolean;
  height?: number;
};

export const CardWrap = styled.div<CardProps>`
  background-color: ${white};
  border-radius: 12px;
  position: relative;
  min-height: ${(props) => `${props.height}px`};
  height: 100%;
  transition: height 0.2s linear;
`;
export const CardAdditionlWrapper = styled.div<CardProps>`
  background-color: ${white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  transition: height 0.2s linear;

  ${(props: { isHovered: boolean }) =>
    props.isHovered &&
    `
    z-index:3;
    margin-bottom: 24px;
    height: auto;

  `}

  @media (max-width: 768px) {
    position: relative;
  }
`;

export const CardBody = styled.div`
  padding: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const CardOverlay = styled.div<CardWrappProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease-out;
  pointer-events: none;
  border-radius: 12px;
  z-index: 0;

  ${(props: { overlay: boolean }) =>
    props.overlay &&
    `
    background-color: rgba(176, 184, 255, 0.5); 
    z-index: 1; 
  `}
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 447px;
  border-radius: 12px 12px 0 0;
  background-color: ${lightGray};
  object-fit: cover;
  object-position: center;
  @media (max-width: 768px) {
    height: 349px;
  }
`;
export const ImgWrap = styled.div`
  width: 100%;
  height: 447px;
  border-radius: 12px 12px 0 0;
  background-color: ${lightGray};
  @media (max-width: 768px) {
    height: 349px;
  }
`;

export const DescriptionText = styled.p`
  ${c3}
  font-weight: 400;
  color: ${black};
  margin: 24px 0 0 0;
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Title = styled.p`
  ${c1}
  max-width: 446px;
  width: 100%;
  @media (max-width: 768px) {
    ${c2}
  }
`;

export const Lessons = styled.p`
  ${c3}
  text-align: right;
  font-weight: 400;
  width: 100px;
  padding-left: 15px;
  white-space: nowrap;
`;

export const Structure = styled.div<StructureProps>`
  margin-bottom: 24px;
  max-height: 0;
  overflow: hidden;
  max-height: ${(props) => (props.isHovered ? `${props.maxHeight}px` : "0")};
  height: 100%;
  transition: max-height 0.15s ease-out;
`;

export const StructureTitle = styled.p`
  ${structure}
`;

export const StructureWrapper = styled.ol`
  padding-left: 25px;
`;

export const StepWrapper = styled.div``;

export const SubstepWrapper = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

export const StepTitle = styled.li`
  ${step}
`;

export const Substep = styled.li`
  ${substep}
`;

export const PriceBlock = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 24px;
  margin-top: 24px;
  color: #333333;
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

export const CurrentPrice = styled.p`
  ${c1}
  font-weight: 600;
`;
export const OldOPrice = styled.p`
  ${oldPriceText}
  text-decoration: line-through;
`;
