import React from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../../store/slices/userSlice";
import {
  DashboardNavigationWrapper,
  DashboardNavigationMenu,
} from "./DashboardNavigation.styled";
import Button from "../Button";

type DashboardNavigationProps = {
  activeItem: string;
  setActiveItem: (item: string) => void;
};

const DashboardNavigation: React.FC<DashboardNavigationProps> = ({
  activeItem,
  setActiveItem,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardNavigationList = [
    {
      text: "Dashboard",
      icon: "dashboard",
    },
    {
      text: "Plan Details",
      icon: "plan",
    },
    // {
    //   text: "Contact Us",
    //   icon: "mail",
    // },
  ];

  const handleLogout = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/user/logout`;

    try {
      await axios.get(url, {
        withCredentials: true,
      });

      dispatch(clearUser());
      navigate("/ai-generator");
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleEmail = () => {
    window.open("mailto:support@woohooooo.com");
  };

  return (
    <DashboardNavigationWrapper>
      <DashboardNavigationMenu>
        {dashboardNavigationList.map((item, index) => (
          <Button
            key={index}
            text={item.text}
            btnType="dashboard"
            width="255px"
            withIcon={true}
            icon={item.icon}
            bgColor="transparent"
            active={activeItem === item.text}
            align="start"
            onClick={() => setActiveItem(item.text)}
          />
        ))}
        <Button
          text="Contact Us"
          btnType="dashboard"
          width="255px"
          withIcon={true}
          icon="mail"
          bgColor="transparent"
          align="start"
          onClick={handleEmail}
        />
      </DashboardNavigationMenu>
      <Button
        text="Logout"
        btnType="dashboard"
        width="255px"
        withIcon={true}
        icon="logout"
        bgColor="#B0B8FF"
        onClick={handleLogout}
        margin="48px"
      />
    </DashboardNavigationWrapper>
  );
};

export default DashboardNavigation;
