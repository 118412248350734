import { PageConfirmEmailWrap } from "./PageConfirmEmail.styled";
import Section from "../Section";
import Authorization from "../Authorization";

function PageConfirmEmail() {
  

  return (
    <PageConfirmEmailWrap>
      <Section overflow="hidden" marginB="0">
      <Authorization type="confirm-email" />
      </Section>
    </PageConfirmEmailWrap>
  );
}

export default PageConfirmEmail;
