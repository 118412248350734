//* headers
export const h1 = `font-family: "Unbounded", sans-serif;font-size: 80px; font-weight: bold; line-height: 142%;  letter-spacing: -1%;`;

export const h2 = `font-family: "Unbounded", sans-serif;font-size: 56px; font-weight: bold; line-height: 72px;  letter-spacing: -1%;`;

export const h3 = `font-family: "Unbounded", sans-serif;font-size: 48px; font-weight: medium; line-height: 140%;  letter-spacing: 0%;`;

export const h4 = `font-family: "Unbounded", sans-serif;font-size: 36px; font-weight: bold; line-height: 140%;  letter-spacing: 0%;`;

export const h5 = `font-family: "Unbounded", sans-serif;font-size: 32px; font-weight: bold; line-height: 140%;  letter-spacing: 0%;`;

export const h6 = `font-family: "Unbounded", sans-serif;font-size: 28px; font-weight: bold; line-height: 150%;  letter-spacing: 0%;`;

//* cards titles
export const c1 = `font-family: "Unbounded", sans-serif;font-size: 24px; font-weight: medium; line-height: 140%; letter-spacing: 0%;`;

export const c2 = `font-family: "Unbounded", sans-serif;font-size: 20px; font-weight: medium; line-height: 140%; letter-spacing: 0%;`;

export const c3 = `font-family: "Unbounded", sans-serif;font-size: 16px; font-weight: regular; line-height: 30px; letter-spacing: 0%;`;

export const c4 = `font-family: "Unbounded", sans-serif;font-size: 14px; font-weight: regular; line-height: 140%; letter-spacing: 0%;`;

//* AI generator titles
export const AIGenratorResultTitle = `font-family: "proxima", sans-serif;font-size: 48px; font-weight: medium; line-height: 74px;  letter-spacing: 0%;`;

export const AIGenratorResultPostTitle = `font-family: "proxima", sans-serif;font-size: 36px; font-weight: bold; line-height: 140%;  letter-spacing: 0%;`;

export const AIGenratorResultText = `font-family: "proxima", sans-serif;font-size: 24px; font-weight: regular; line-height: 150%; letter-spacing: 0%;`;

//* descriptions text
export const d1 = `font-family: "Unbounded", sans-serif; font-size: 20px; font-weight: medium; line-height: 28px; letter-spacing: 0%;`;

export const d2 = `font-family: "proxima", sans-serif; font-size: 20px; font-weight: regular; line-height: 30px; letter-spacing: 0%;`;

export const d3 = `font-family: "Unbounded", sans-serif; font-size: 20px; font-weight: regular; line-height: 28px; letter-spacing: 0%;`;

//* other text
export const buttonText = `font-family: "Unbounded", sans-serif; font-size: 16px; font-weight: regular; line-height: 140%; letter-spacing: 0%;`;

export const dashboardNavButtonText = `font-family: "proxima", sans-serif; font-size: 18px; font-weight: 400; line-height: 24px; letter-spacing: 0%;`;

export const dashboardButtonText = `font-family: "Unbounded", sans-serif; font-size: 16px; font-weight: 400; line-height: 22px; letter-spacing: 0%;`;

export const inputText = `font-family: "proxima", sans-serif; font-size: 16px; font-weight: normal; line-height: 24px; letter-spacing: 0%;`;

export const labelText = `font-family: "Unbounded", sans-serif; font-size: 16px; font-weight: regular; line-height: 24px; letter-spacing: 0%;`;

export const menuText = `font-family: "proxima"; font-size: 18px; font-weight: bold; line-height: 28px; letter-spacing: 0%; text-decoration:none;`;

export const menuTextM = `font-family: "proxima"; font-size: 16px; font-weight: bold; line-height: 28px; letter-spacing: 0%; text-decoration:none;`;

export const partnerText = `font-family: "Unbounded", sans-serif;font-size: 20px; font-weight: medium; line-height: 140%; letter-spacing: 0%;`;

export const titleM = `font-family: "Unbounded", sans-serif;font-size: 40px; font-weight: 700; line-height: 150%; letter-spacing: 0%;`;

export const preventionText = `font-family: "Unbounded", sans-serif;font-size: 20px; font-weight: medium; line-height: 140%; letter-spacing: 0%;`;

export const orderSubtitle = `font-family: "Unbounded", sans-serif;font-size: 20px; font-weight: medium; line-height: 140%; letter-spacing: 0%;`;

export const timelineStepText = `font-family: "Unbounded", sans-serif;font-size: 14px; font-weight: medium; line-height: 100%; letter-spacing: 0%;`;

export const timelineStepTextM = `font-family: "Unbounded", sans-serif;font-size: 12px; font-weight: medium; line-height: 100%; letter-spacing: 0%;`;

export const footerTextT = `font-family: "proxima", sans-serif;font-size: 14px; font-weight: regular; line-height: 120%; letter-spacing: 0%;`;

export const companyAddres = `font-family: "proxima", sans-serif;font-size: 16px; font-weight: regular; line-height: 17px; letter-spacing: 0%;`;

export const footerTextM = `font-family: "proxima", sans-serif;font-size: 12px; font-weight: regular; line-height: 120%; letter-spacing: 0%;`;

export const timelineLogo = `font-family: "Unbounded", sans-serif;font-size: 20px; font-weight: 700; line-height: 140%; letter-spacing: 0%;`;

export const popupTitle = `font-family: "Unbounded", sans-serif;font-size: 24px; font-weight: 500; line-height: 150%; letter-spacing: 0%;`;

export const popupText = `font-family: "proxima", sans-serif;font-size: 24px; font-weight: 400; line-height: 150%; letter-spacing: 0%;`;

export const popupSmallText = `font-family: "proxima", sans-serif;font-size: 16px; font-weight: 400; line-height: 24px; letter-spacing: 0%;`;

export const informerText = `font-family: "proxima", sans-serif;font-size: 24px; font-weight: 400; line-height: 150%; letter-spacing: 0%;`;

export const orderText = `font-family: "proxima"; font-size: 16px; font-weight: normal; line-height: 24px; letter-spacing: 0%;`;

export const orderCardDescription = `font-family: "Unbounded"; font-size: 12px; font-weight: 400; line-height: 16px; letter-spacing: 0%;`;

export const oldPriceText = `font-family: "proxima"; font-size: 16px; font-weight: normal; line-height: 24px; letter-spacing: 0%;`;

export const hoursPriceText = `font-family: "proxima"; font-size: 16px; font-weight: normal; line-height: 24px; letter-spacing: 0%;`;

export const subtotalText = `font-family: "proxima"; font-size: 16px; font-weight: 600; line-height: 24px; letter-spacing: 0%;`;

export const structure = `font-family: "proxima"; font-size: 16px; font-weight: 700; line-height: 24px; letter-spacing: 0%;`;

export const step = `font-family: "proxima"; font-size: 16px; font-weight: 600; line-height: 24px; letter-spacing: 0%;`;

export const substep = `font-family: "proxima"; font-size: 16px; font-weight: 400; line-height: 24px; letter-spacing: 0%;`;

export const accentText = `font-family: "proxima"; font-size: 14px; font-weight: 600; line-height: 20px; letter-spacing: 0%;`;

export const dashboardText = `font-family: "proxima"; font-size: 16px; font-weight: 600; line-height: 24px; letter-spacing: 0%;`;

export const aiGeneratorResultText = `font-family: "proxima"; font-size: 14px; font-weight: 400; line-height: 20px; letter-spacing: 0%;`;

export const buttonAiGenerator = `font-family: "proxima", sans-serif;font-size: 12px; font-weight: regular; line-height: 120%; letter-spacing: 0%;`;

export const requestHistory = `font-family: "proxima", sans-serif;font-size: 14px; font-weight: regular; line-height: 120%; letter-spacing: 0%;`;

export const reviewText = `font-family: "Unbounded", sans-serif;font-size: 18px; font-weight: 400; line-height: 30px; letter-spacing: 0%;`;
