import React, { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import sprite from "../../icons/symbol-defs.svg";
import Button from "../Button";
import {
  PresentationEditorNavigationHeader,
  ActionsButtonsBlock,
  ActionButton,
  QuillEditButtons,
  EditButton,
  Svg,
} from "./PresentationEditorNavigation.styled";

type PresentationEditorNavigationProps = {
  quillRef: React.RefObject<ReactQuill> | undefined;
  generatePdf: () => void;
  loadingPDFGenerating: boolean;
  formattedSlides: { htmlContent: string }[];
  formattedPosts?: { htmlContent: string; imageUrl?: string; week?: string }[];
  onToolbarRef?: (ref: HTMLDivElement | null) => void;
};

const PresentationEditorNavigation: React.FC<
  PresentationEditorNavigationProps
> = ({
  quillRef,
  loadingPDFGenerating,
  generatePdf,
  formattedSlides,
  formattedPosts,
  onToolbarRef,
}) => {
  const toolbarRef = useRef<HTMLDivElement | null>(null);

  const [undoEnabled, setUndoEnabled] = useState<boolean>(false);
  const [redoEnabled, setRedoEnabled] = useState<boolean>(false);

  useEffect(() => {
    const toolbar = toolbarRef.current;

    if (onToolbarRef) {
      onToolbarRef(toolbar);
    }

    const updateStyles = () => {
      if (toolbar) {
        toolbar.style.border = "none";
        const buttons = toolbar.querySelectorAll("button");
        buttons.forEach((button) => {
          button.style.display = "flex";
          button.style.alignItems = "center";
          button.style.justifyContent = "center";
          button.style.width = "64px";
          button.style.height = "64px";
          button.style.borderRadius = "8px";
          button.style.border = "1px solid #bcbadb";
          button.style.backgroundColor = "transparent";
          button.style.cursor = "pointer";

          button.addEventListener("focus", () => {
            button.querySelectorAll("svg").forEach((svg) => {
              svg.style.stroke = "#bcbadb";
              svg.style.fill = "#bcbadb";
            });
          });

          if (button.classList.contains("ql-link")) {
            button.innerHTML =
              '<svg width="24" height="24"><use xlink:href="' +
              sprite +
              '#link"></use></svg>';
          }
        });
      }
    };

    updateStyles();

    return () => {
      if (toolbar) {
        const buttons = toolbar.querySelectorAll("button");
        buttons.forEach((button) => {
          button.removeEventListener("focus", () => {});
        });
      }
    };
  }, [onToolbarRef]);

  useEffect(() => {
    const updateButtonStates = () => {
      const quill = quillRef?.current?.getEditor();
      if (quill) {
        const history = (quill as any).history;
        setUndoEnabled(history?.stack?.undo.length > 1);
        setRedoEnabled(history?.stack?.redo.length > 0);
      }
    };

    updateButtonStates();
  }, [quillRef, formattedSlides, formattedPosts]);

  const toggleFormat = (format: string) => {
    const quill = quillRef?.current?.getEditor();
    if (quill) {
      const isActive = quill.getFormat()[format];
      quill.format(format, !isActive);
    }
  };

  const handleUndo = () => {
    const quill = quillRef?.current?.getEditor();
    if (quill && undoEnabled) {
      (quill as any).history.undo();
    }
  };

  const handleRedo = () => {
    const quill = quillRef?.current?.getEditor();
    if (quill && redoEnabled) {
      (quill as any).history.redo();
    }
  };

  return (
    <PresentationEditorNavigationHeader>
      <ActionsButtonsBlock>
        <ActionButton active={undoEnabled} onClick={handleUndo}>
          <Svg width="24" height="24" active={undoEnabled}>
            <use xlinkHref={`${sprite}#action-row`}></use>
          </Svg>
        </ActionButton>
        <ActionButton active={redoEnabled} onClick={handleRedo}>
          <Svg width="24" height="24" active={redoEnabled}>
            <use xlinkHref={`${sprite}#action-row-reverse`}></use>
          </Svg>
        </ActionButton>
      </ActionsButtonsBlock>
      <QuillEditButtons ref={toolbarRef}>
        <EditButton onClick={() => toggleFormat("bold")}>
          <Svg width="24" height="24">
            <use xlinkHref={`${sprite}#bold`}></use>
          </Svg>
        </EditButton>
        <EditButton onClick={() => toggleFormat("italic")}>
          <Svg width="24" height="24">
            <use xlinkHref={`${sprite}#italic`}></use>
          </Svg>
        </EditButton>
        <EditButton onClick={() => toggleFormat("underline")}>
          <Svg width="24" height="24">
            <use xlinkHref={`${sprite}#underline`}></use>
          </Svg>
        </EditButton>
        <EditButton className="ql-link">
          <Svg width="24" height="24">
            <use xlinkHref={`${sprite}#link`}></use>
          </Svg>
        </EditButton>
      </QuillEditButtons>
      <Button
        text={loadingPDFGenerating ? "Generating.." : "Save to pdf"}
        withIcon={false}
        width="240px"
        bgColor="#B0B8FF"
        onClick={generatePdf}
      />
    </PresentationEditorNavigationHeader>
  );
};

export default PresentationEditorNavigation;
