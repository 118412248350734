import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../store/store";
import { ServicePageData, TCourseCard } from "../../types/";
import axios from "axios";
import { PageServiceWrap } from "./PageCheckout.styled";
import Section from "../Section";
import OrderPreview from "../OrderPreview";
import Popup from "../Popup";

function PageCheckout() {
  const cartItems = useSelector((state: RootState) => state.cart.products);
  const transactions = useSelector(
    (state: RootState) => state.transactions.transactions
  );
  const [data, setData] = useState<Array<ServicePageData>>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/products/get-products-by-ids`,
          {
            ids: cartItems,
          }
        );

        const fetchedData = response.data.products || [];
        const validData = fetchedData.filter(
          (item: ServicePageData | TCourseCard) =>
            item && typeof item.mainImg === "string"
        );

        setData(validData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, [cartItems]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const successParam = searchParams.get("success");
    const transactionIdParam = searchParams.get("transaction_id");

    const existingTransaction = transactions.find(
      (transaction) => transaction.transactionId === transactionIdParam
    );

    if (successParam === "false" && !existingTransaction) {
      setIsPopupOpen(true);
    }
  }, [dispatch, location.search, transactions]);

  const clickPopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <PageServiceWrap>
      <Section overflow="hidden" marginB="0">
        <OrderPreview data={data} />
      </Section>
      {isPopupOpen && (
        <Popup
          popupType="application-error"
          isOpen={isPopupOpen}
          onClose={clickPopup}
        />
      )}
    </PageServiceWrap>
  );
}

export default PageCheckout;
