const paymentPlans = [
  {
    id: 11,
    periodType: "yearly",
    type: "customer",
    features: [
      {
        text: "20 posts",
      },
      {
        text: "3 content plans",
      },
      {
        text: "1 marketing strategy",
      },
    ],
    lookupKey: "standard_yearly",
  },
  {
    id: 21,
    periodType: "yearly",
    type: "pro",
    features: [
      {
        text: "All generations are unlimited",
      },
      {
        text: " of your choise as a gift",
        linkText: "One of our courses",
        link: "/courses",
      },
    ],
    lookupKey: "pro_yearly",
  },
  {
    id: 10,
    periodType: "monthly",
    type: "customer",
    features: [
      {
        text: "20 posts",
      },
      {
        text: "3 content plans",
      },
      {
        text: "1 marketing strategy",
      },
    ],
    lookupKey: "standard_monthly",
  },
  {
    id: 20,
    periodType: "monthly",
    type: "pro",
    features: [
      {
        text: "All generations are unlimited",
      },
      {
        text: " of your choise as a gift",
        linkText: "One of our courses",
        link: "/courses",
      },
    ],
    lookupKey: "pro_monthly",
  },
];
export default paymentPlans;
