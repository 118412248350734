import React, { useState, useRef, useEffect } from "react";
import sprite from "../../icons/symbol-defs.svg";
import {
  SelectContainer,
  SelectHeader,
  Svg,
  SelectBody,
  SelectItem,
} from "./Select.styled";

interface SelectProps {
  data: string[];
  onSelect: (item: string) => void;
  defaultValue: string;
}

const Select: React.FC<SelectProps> = ({ data, onSelect, defaultValue }) => {
  const defaultItem = data.find((item) => item === defaultValue);
  const [isOpen, setOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<string>(
    defaultItem ? defaultItem : defaultValue
  );
  const [selectBodyHeight, setSelectBodyHeight] = useState<number | null>(null);

  const selectBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectBodyRef.current) {
      setSelectBodyHeight(selectBodyRef.current.scrollHeight);
    }
  }, [isOpen, data]);

  const toggleSelect = () => setOpen(!isOpen);

  const handleItemClick = (label: string) => {
    setSelectedLabel(label);
    setOpen(false);
    onSelect(label);
  };

  return (
    <SelectContainer isOpen={isOpen}>
      <SelectHeader onClick={toggleSelect}>
        {selectedLabel}
        <Svg isOpen={isOpen} width="16px" height="16px">
          <use xlinkHref={`${sprite}#row`} />
        </Svg>
      </SelectHeader>
      <SelectBody
        isOpen={isOpen}
        height={selectBodyHeight || 0}
        ref={selectBodyRef}
      >
        {data.map((item) => (
          <SelectItem key={item} onClick={() => handleItemClick(item)}>
            {item}
          </SelectItem>
        ))}
      </SelectBody>
    </SelectContainer>
  );
};

export default Select;
