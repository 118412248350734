import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { TPaymentPlanCardList } from "../../types";
import {
  PaymentPlansListWrap,
  PaymentPlansList,
  PaymentPlansCardListElement,
  PaymentPlansInfo,
  PaymentPlansPeriods,
  PaymentPlansPeriodBtn,
  PaymentPlansText,
} from "./PaymentPlansCardList.styled";
import PaymentPlansCard from "../PaymentPlansCard/PaymentPlansCard";
import LoadingSpinner from "../LoadingSpinner";

type FilterType = "monthly" | "yearly";

type PaymentPlansCardListProps = {
  list: TPaymentPlanCardList;
};

type Prices = {
  [key: string]: {
    monthly: number;
    yearly: number;
  };
};

const PaymentPlansCardList: React.FC<PaymentPlansCardListProps> = ({ list }) => {
  const [activeFilter, setActiveFilter] = useState<FilterType>("yearly");
  const [buttonWidths, setButtonWidths] = useState<{
    [key in FilterType]: number;
  }>({ monthly: 0, yearly: 0 });
  const [offset, setOffset] = useState(0);
  const [planPrices, setPlanPrices] = useState<Prices>({});
  const [loading, setLoading] = useState(true);
  const buttonRefs = {
    monthly: useRef<HTMLButtonElement>(null),
    yearly: useRef<HTMLButtonElement>(null),
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (buttonRefs.monthly.current && buttonRefs.yearly.current) {
        const monthlyWidth = buttonRefs.monthly.current.offsetWidth;
        const yearlyWidth = buttonRefs.yearly.current.offsetWidth;
        setButtonWidths({ monthly: monthlyWidth, yearly: yearlyWidth });
      }
    });

    if (buttonRefs.monthly.current) {
      resizeObserver.observe(buttonRefs.monthly.current);
    }

    if (buttonRefs.yearly.current) {
      resizeObserver.observe(buttonRefs.yearly.current);
    }

    return () => {
      if (buttonRefs.monthly.current) {
        resizeObserver.unobserve(buttonRefs.monthly.current);
      }

      if (buttonRefs.yearly.current) {
        resizeObserver.unobserve(buttonRefs.yearly.current);
      }
    };
  }, [buttonRefs.monthly, buttonRefs.yearly]);

  useEffect(() => {
    setOffset(activeFilter === "monthly" ? 0 : buttonWidths.monthly);
  }, [activeFilter, buttonWidths]);

  useEffect(() => {
    const updatePlanPrices = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/payments/payment-plans`
        );
        const data = response.data;

        const newPrices = data.reduce((acc: Prices, item: any) => {
          const monthlyPrice =
            item.prices.find((price: any) => price.recurring === "month")
              ?.unitAmount || 0;
          const yearlyPrice =
            item.prices.find((price: any) => price.recurring === "year")
              ?.unitAmount || 0;

          acc[item.type] = {
            monthly: monthlyPrice / 100,
            yearly: yearlyPrice / 100 / 12,
          };
          return acc;
        }, {});
        setPlanPrices(newPrices);
        setLoading(false);
      } catch (error) {
        console.error("Error updating plan prices:", error);
        setLoading(false);
      }
    };

    updatePlanPrices();
  }, []);

  const getPlanPrice = (planType: string, periodType: "monthly" | "yearly") => {
    return planPrices[planType]?.[periodType] ?? 0;
  };

  const filteredList = list.filter((item) => {
    return item.periodType === activeFilter.toLowerCase();
  });

  return (
    <PaymentPlansListWrap>
      <PaymentPlansInfo>
        <PaymentPlansPeriods width={buttonWidths[activeFilter]} offset={offset}>
          <PaymentPlansPeriodBtn
            ref={buttonRefs.monthly}
            onClick={() => setActiveFilter("monthly")}
          >
            Monthly
          </PaymentPlansPeriodBtn>
          <PaymentPlansPeriodBtn
            ref={buttonRefs.yearly}
            onClick={() => setActiveFilter("yearly")}
          >
            Yearly
          </PaymentPlansPeriodBtn>
        </PaymentPlansPeriods>
        <PaymentPlansText>
          <span>Save 20% </span>with annual
        </PaymentPlansText>
      </PaymentPlansInfo>
      {loading ? (
        <LoadingSpinner width="100px" height="100px" $margint="80px" />
      ) : (
        <PaymentPlansList>
          {filteredList.map((item) => {
            const price = getPlanPrice(
              item.type,
              activeFilter.toLowerCase() as "monthly" | "yearly"
            );

            return (
              <PaymentPlansCardListElement key={item.id}>
                <PaymentPlansCard data={item} price={price} />
              </PaymentPlansCardListElement>
            );
          })}
        </PaymentPlansList>
      )}
    </PaymentPlansListWrap>
  );
};

export default PaymentPlansCardList;
