import styled from "@emotion/styled";
import { d2, h3 } from "../../assets";

export const PaymentPlansAdditionalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PaymentPlansAdditionalInfo = styled.div`
  text-align: left;
`;

export const PaymentPlansTitle = styled.h3`
  ${h3}
  font-weight: 500;
  @media (max-width: 1024px) {
  }
  @media (max-width: 768px) {
  }
`;
export const PaymentPlansText = styled.h3`
  ${d2}
  padding-top:16px;
  @media (max-width: 1024px) {
  }
  @media (max-width: 768px) {
  }
`;
