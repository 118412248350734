import styled from "@emotion/styled";
import { c2, h5 } from "../../assets";

export const StyledHeroCard = styled.div`
  margin-top: auto;
  padding: 36px;
  border-radius: 32px;
  background-color: #f0f424;
  height: 154px;
  cursor: pointer;
  @media (max-width: 768px) {
    min-width: 155px;
    height: 248px;
    padding: 16px 26px;
  }
`;

export const TextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media (max-width: 1048px) {
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Title = styled.p`
  ${h5}
  max-width: 283px;
  min-width: 213px;
  position: relative;
  @media (max-width: 1048px) {
    max-width: 355px;
    width: 100%;
  }
  @media (max-width: 768px) {
    ${c2}
    font-weight: 700;
    max-width: 250px;
    min-width: 130px;
    margin-bottom: 30px;
  }
`;

export const Svg = styled.svg`
  position: absolute;
  bottom: 12px;
  right: -5px;
  @media (max-width: 768px) {
    left: 0;
    bottom: -40px;
  }
`;
