import styled from "@emotion/styled";
import {
  inputBorder,
  inputText,
  violet,
  darkGrayRGBA,
  inputError,
  white,
} from "../../assets";

type SelectProps = {
  error?: string | undefined;
  isOpen: boolean;
};

interface SelectBodyProps {
  isOpen: boolean;
  height: number;
}

export const SelectContainer = styled.div<SelectProps>`
  ${inputText}
  position: relative;
  width: 100%;
  height: 48px;
  padding: 12px 12px 12px 24px;
  margin: 0;
  border: 1px solid ${({ error }) => (error ? inputError : inputBorder)};
  border-radius: ${({ isOpen }) =>
    isOpen ? "16px 16px 0 0" : "16px 16px 12px 12px"};
  color: #414141;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  ${({ error }) => error && inputError}

  &:focus {
    border-color: ${violet};
    outline: none;
  }

  &:disabled {
    background-color: ${darkGrayRGBA};
    cursor: not-allowed;
  }

  &:hover:not(:focus):not(:disabled) {
    border-color: #b3b3b3;
  }
`;

export const SelectHeader = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SelectBody = styled.div<SelectBodyProps>`
  position: absolute;
  left: -1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: calc(100% + 2px);
  gap: 8px;
  padding: 5px;
  background-color: ${white};
  border-top: 1px solid #e5e8ec;
  height: ${({ isOpen, height }) => (isOpen ? `${height}px` : "15px")};
  overflow: hidden;
  z-index: 2;
  border: 1px solid ${inputBorder};
  border-top: none;
  border-radius: ${(props) =>
    props.isOpen ? " 0 0 16px 16px" : "0 0 56px 56px"};
  transition: height 0.3s linear;
`;

export const SelectItem = styled.div`
  padding: 10px 10px 10px 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const Svg = styled.svg<{ isOpen: boolean }>`
  font-size: 13px;
  color: #91a5be;
  transform: rotate(${(props) => (props.isOpen ? "90deg" : "0deg")});
  transition: all 0.2s ease-in-out;
`;
