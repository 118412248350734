import React from "react";
import { useField } from "formik";
import { InputField, InputWrap, Svg } from "./InputField.styled";
import sprite from "../../icons/symbol-defs.svg";

type InputProps = {
  name: string;
  type?: string;
  placeholder: string;
};

const InputComponent: React.FC<InputProps> = ({
  name,
  placeholder,
  type = "text",
}) => {
  const [field, meta] = useField(name);

  return (
    <InputWrap>
      <InputField
        {...field}
        placeholder={placeholder}
        error={meta.error}
        type={type}
        autoComplete={type === "password" ? "current-password" : "on"} // Corrected casing
        aria-describedby={meta.error ? `${name}-error` : undefined}
      />

      {meta.touched && meta.error && (
        <Svg width="24" height="24">
          <use xlinkHref={`${sprite}#info-circle`}></use>
        </Svg>
      )}
    </InputWrap>
  );
};

export default InputComponent;
