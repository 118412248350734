import styled from "@emotion/styled";
import {
  inputBorder,
  inputText,
  violet,
  darkGrayRGBA,
  inputError,
} from "../../assets";

type InputFieldProps = {
  error?: string | undefined;
};

export const InputWrap = styled.div`
  position: relative;
`;

export const InputField = styled.input<InputFieldProps>`
  ${inputText}
  width: 100%;
  height: 64px;
  padding: 12px;
  margin: 0;
  border: 1px solid ${({ error }) => (error ? inputError : inputBorder)};

  border-radius: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  ${({ error }) => error && inputError}

  &:focus {
    border-color: ${violet};
    outline: none;
  }

  &:disabled {
    background-color: ${darkGrayRGBA};
    cursor: not-allowed;
  }

  &:hover:not(:focus):not(:disabled) {
    border-color: #b3b3b3;
  }
`;

export const Svg = styled.svg`
  position: absolute;
  fill: ${inputError};
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
`;
