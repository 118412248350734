import { InfoWrap, InfoText } from "./Banners.styled";
function InfoBanner() {
  return (
    <InfoWrap>
      <InfoText>Make it easy and save some 🤑💸</InfoText>
    </InfoWrap>
  );
}

export default InfoBanner;
