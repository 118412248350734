import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import sprite from "../../icons/symbol-defs.svg";
import { TPaymentPlanCard } from "../../types";
import { RootState } from "../../store/store";
import {
  PaymentPlanCardWrap,
  PaymentPlanCardType,
  PaymentPlanCardSvg,
  PaymentPlanCardBody,
  PaymentPlanCardPrice,
  PaymentPlanCardBilling,
  PaymentPlanCardTrial,
  PaymentPlanCardTrialSvg,
  PaymentPlanCardTrialText,
  PaymentPlanCardFeatures,
  PaymentPlanCardFeatureItem,
  PaymentPlanCardFeatureSvg,
  PaymentPlanCardLink,
  PaymentPlanCardFeatureText,
} from "./PaymentPlansCard.styled";

import Button from "../Button";

type PaymentPlansCardProps = {
  data: TPaymentPlanCard;
  price: number | null;
};

const PaymentPlansCard: React.FC<PaymentPlansCardProps> = ({ data, price }) => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: RootState) => state.user);

  const handleChoosePlan = async () => {
    const url =
      `${process.env.REACT_APP_SERVER_URL}/payments/create-subscription-checkout-session`;
    try {
      setLoading(true);
      const response = await axios.post(url, {
        lookupKey: data.lookupKey,
        email: user.email,
      });

      window.location.href = response.data.redirectUrl;
    } catch (error) {
      setLoading(false);

      console.error("Error creating subscription checkout session:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PaymentPlanCardWrap>
      <PaymentPlanCardType>
      {data.type === "pro" ? "Pro": "Customer"}{" "}
        {data.type === "pro" ? (
          <PaymentPlanCardSvg width="23" height="22">
            <use xlinkHref={`${sprite}#stars`}></use>
          </PaymentPlanCardSvg>
        ) : (
          <></>
        )}
      </PaymentPlanCardType>
      <PaymentPlanCardBody>
        <PaymentPlanCardPrice>${price}*/month</PaymentPlanCardPrice>
        <PaymentPlanCardBilling>
          {data.periodType === "monthly" ? "billed monthly" : "billed annually"}
        </PaymentPlanCardBilling>
        <PaymentPlanCardTrial>
          <PaymentPlanCardTrialSvg width="23" height="22">
            <use xlinkHref={`${sprite}#star`}></use>
          </PaymentPlanCardTrialSvg>
          <PaymentPlanCardTrialText>
            *First 3 days free!
          </PaymentPlanCardTrialText>
        </PaymentPlanCardTrial>
        <PaymentPlanCardFeatures>
          {data.features.map((feature, index) => {
            return (
              <PaymentPlanCardFeatureItem key={index}>
                <PaymentPlanCardFeatureSvg width="23" height="22">
                  <use xlinkHref={`${sprite}#lightning`}></use>
                </PaymentPlanCardFeatureSvg>
                <PaymentPlanCardFeatureText>
                  {feature.link && (
                    <PaymentPlanCardLink to={feature.link}>
                      {feature.linkText}
                    </PaymentPlanCardLink>
                  )}
                  {feature.text}
                </PaymentPlanCardFeatureText>
              </PaymentPlanCardFeatureItem>
            );
          })}
        </PaymentPlanCardFeatures>
      </PaymentPlanCardBody>
      <Button
        text={loading ? "Loading..." : "Select plan"}
        onClick={handleChoosePlan}
      />
    </PaymentPlanCardWrap>
  );
};

export default PaymentPlansCard;
