import React from "react";
import { TUserSubscriptionInfo } from "../../types";
import sprite from "../../icons/symbol-defs.svg";
import {
  PlanDetailsWrapper,
  PlanDetailsTitle,
  PlanDetailsContent,
  PlanDetailsAdditionalWrap,
  PlanDetailsSubtitle,
  PlanDetailsText,
  PlanDetailsUpgrateToPro,
  PlanDetailsPlanType,
  PlanDetailsCardNumber,
  Divider,
  PlanDetailsRow,
  PlanDetailsCustomRow,
  PlanDetailsColumn,
  PlanDetailsCancel,
  PlanDetailsList,
  PlanDetailsListItem,
  PlanDetailsFeaturesList,
  PlanDetailsFeaturesItem,
  DetailSvg,
  UpdateSvg,
  Svg,
  NewLine,
  TrialDays,
  PlanDetailsActionText,
} from "./PlanDetails.styled";
import Button from "../Button";

type DashboarProps = {
  userPlanInfo: TUserSubscriptionInfo | null;
};

const PlanDetails: React.FC<DashboarProps> = ({ userPlanInfo }) => {
  const handleBillingPortal = () => {
    window.location.href = `${process.env.REACT_APP_BILLING_PORTAL}`;
  };

  function capitalizeFirstLetter(string: string | undefined): string {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function formatDateToDesiredFormat(dateString: string | undefined): string {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return date.toLocaleDateString("en-US", options);
  }

  const calculateRemainingDays = (
    endDate: string | Date | undefined
  ): number => {
    if (!endDate) {
      return 0;
    }
    const today = new Date();
    const end = new Date(endDate);

    if (isNaN(end.getTime())) {
      return 0;
    }
    const diffTime = end.getTime() - today.getTime();
    const daysRemaining = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return daysRemaining > 0 ? daysRemaining : 0;
  };

  return (
    <PlanDetailsWrapper>
      <PlanDetailsTitle>Plan Details</PlanDetailsTitle>
      <PlanDetailsContent>
        <PlanDetailsAdditionalWrap>
          <PlanDetailsRow>
            <PlanDetailsSubtitle>Plan</PlanDetailsSubtitle>
            <PlanDetailsRow>
              {userPlanInfo?.subscriptionType === "pro" ? (
                <></>
              ) : (
                <PlanDetailsUpgrateToPro
                  to={`${process.env.REACT_APP_BILLING_PORTAL}`}
                >
                  Upgrade to Pro
                </PlanDetailsUpgrateToPro>
              )}
              <PlanDetailsPlanType type={userPlanInfo?.status}>
                {userPlanInfo?.status === "trial" ? (
                  "Trial Period"
                ) : userPlanInfo?.status === "suspended" ? (
                  "Suspended"
                ) : userPlanInfo?.status === "expired" ? (
                  "Cancelled"
                ) : (
                  <>
                    {`${capitalizeFirstLetter(userPlanInfo?.subscriptionType)}`}
                    <Svg width="24" height="24">
                      <use xlinkHref={`${sprite}#stars`}></use>
                    </Svg>
                  </>
                )}
              </PlanDetailsPlanType>
            </PlanDetailsRow>
          </PlanDetailsRow>
          <PlanDetailsText>
            A look at your subscription and billing information.
          </PlanDetailsText>
          <PlanDetailsCustomRow>
            <PlanDetailsList>
              <PlanDetailsListItem>
                <DetailSvg width="24" height="24">
                  <use xlinkHref={`${sprite}#clock`} />
                </DetailSvg>
                {userPlanInfo?.status === "trial" ? (
                  <p>
                    You are currently <span>on a trial </span>
                    of the{" "}
                    {capitalizeFirstLetter(userPlanInfo?.subscriptionType)} plan
                    <NewLine>
                      Days remaining:{" "}
                      <TrialDays>
                        {calculateRemainingDays(userPlanInfo?.endDate)} days
                      </TrialDays>
                    </NewLine>
                  </p>
                ) : userPlanInfo?.status === "expired" ? (
                  `Frequency: -`
                ) : (
                  `Frequency: ${capitalizeFirstLetter(
                    userPlanInfo?.subscriptionPeriodType
                  )}`
                )}
              </PlanDetailsListItem>
              <PlanDetailsListItem>
                <DetailSvg width="24" height="24">
                  <use xlinkHref={`${sprite}#date`}></use>
                </DetailSvg>
                {userPlanInfo?.status === "trial" ? (
                  <p>
                    After the trial ends, the amount for the tariff will be
                    debited.
                    <NewLine>
                      <span>
                        {formatDateToDesiredFormat(userPlanInfo?.endDate)}
                      </span>
                    </NewLine>
                  </p>
                ) : userPlanInfo?.status === "expired" ? (
                  ` Next billing date: -`
                ) : userPlanInfo?.status === "suspended" ? (
                  <>
                    End date: {formatDateToDesiredFormat(userPlanInfo?.endDate)}
                  </>
                ) : (
                  <>
                    Next billing date:{" "}
                    {formatDateToDesiredFormat(userPlanInfo?.endDate)}
                  </>
                )}
              </PlanDetailsListItem>
              <PlanDetailsListItem>
                <DetailSvg width="24" height="24">
                  <use xlinkHref={`${sprite}#price`}></use>
                </DetailSvg>
                {userPlanInfo?.status === "expired" ? (
                  <>Amount: -</>
                ) : (
                  `Amount: ${userPlanInfo?.subscriptionPrice}.00`
                )}
              </PlanDetailsListItem>
            </PlanDetailsList>
            <PlanDetailsFeaturesList>
              {userPlanInfo?.subscriptionType === "pro" ? (
                <>
                  <PlanDetailsFeaturesItem>
                    <svg width="24" height="24">
                      <use xlinkHref={`${sprite}#lightning`}></use>
                    </svg>
                    All generations are unlimited
                  </PlanDetailsFeaturesItem>
                  <PlanDetailsFeaturesItem>
                    <svg width="24" height="24">
                      <use xlinkHref={`${sprite}#lightning`}></use>
                    </svg>
                    One of our courses of your choise as a gift
                  </PlanDetailsFeaturesItem>
                </>
              ) : (
                <>
                  <PlanDetailsFeaturesItem>
                    <svg width="24" height="24">
                      <use xlinkHref={`${sprite}#lightning`}></use>
                    </svg>
                    20 posts
                  </PlanDetailsFeaturesItem>
                  <PlanDetailsFeaturesItem>
                    <svg width="24" height="24">
                      <use xlinkHref={`${sprite}#lightning`}></use>
                    </svg>
                    3 content plans
                  </PlanDetailsFeaturesItem>
                  <PlanDetailsFeaturesItem>
                    <svg width="24" height="24">
                      <use xlinkHref={`${sprite}#lightning`}></use>
                    </svg>
                    1 marketing strategy
                  </PlanDetailsFeaturesItem>
                </>
              )}
            </PlanDetailsFeaturesList>
          </PlanDetailsCustomRow>
          {userPlanInfo?.paymentType === "yearly" ? (
            <></>
          ) : (
            <>
              <Divider />
              <PlanDetailsRow>
                <PlanDetailsRow>
                  <UpdateSvg width="24" height="24">
                    <use xlinkHref={`${sprite}#effect-star`}></use>
                  </UpdateSvg>
                  <PlanDetailsText>
                    Upgrade to Yearly and{" "}
                    <PlanDetailsActionText>Save 20% </PlanDetailsActionText>{" "}
                    with annual.
                  </PlanDetailsText>
                </PlanDetailsRow>
                <Button
                  text="Upgrade"
                  color="##000000"
                  bgColor="rgba(176, 184, 255, 1);"
                  width="131px"
                  btnType="dashboard"
                  withIcon={false}
                  onClick={handleBillingPortal}
                />
              </PlanDetailsRow>
            </>
          )}
        </PlanDetailsAdditionalWrap>
        <PlanDetailsAdditionalWrap>
          <PlanDetailsSubtitle>Payment Method</PlanDetailsSubtitle>
          <PlanDetailsText>
            Here’s the billing card we currently have for your subscription.
          </PlanDetailsText>
          <PlanDetailsCardNumber>
            <svg width="24" height="8">
              <use xlinkHref={`${sprite}#visa`}></use>
            </svg>
            <PlanDetailsText>
              **** **** **** {userPlanInfo?.lastNumsOfCard}
            </PlanDetailsText>
          </PlanDetailsCardNumber>

          <PlanDetailsRow>
            <PlanDetailsText>
              To update your billing information, go to{" "}
              <span>billing portal.</span>
            </PlanDetailsText>
            <Button
              text="Update"
              color="##000000"
              bgColor="rgba(176, 184, 255, 1);"
              width="131px"
              btnType="dashboard"
              withIcon={false}
              onClick={handleBillingPortal}
            />
          </PlanDetailsRow>
        </PlanDetailsAdditionalWrap>
        <PlanDetailsAdditionalWrap>
          <PlanDetailsCancel>
            <PlanDetailsColumn>
              <PlanDetailsSubtitle>Cancel Subscription</PlanDetailsSubtitle>
              <PlanDetailsText>
                If you wish to discontinue your subscription, you can cancel at
                anytime.
              </PlanDetailsText>
            </PlanDetailsColumn>
            <Button
              text="Cancel"
              color="#EB001B"
              bgColor=" rgba(235, 0, 27, 0.1)"
              width="131px"
              btnType="dashboard"
              withIcon={false}
              onClick={handleBillingPortal}
            />
          </PlanDetailsCancel>
        </PlanDetailsAdditionalWrap>
      </PlanDetailsContent>
    </PlanDetailsWrapper>
  );
};

export default PlanDetails;
