import styled from "@emotion/styled";
import {
  d2,
  subtotalText,
  white,
  dashboardText,
  requestHistory,
} from "../../assets";

type InfoElementProps = {
  width: string;
};

export const AIHistoryWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 325px;
  max-height: 437px;
  padding: 16px;
  margin-bottom: 56px;
  border-radius: 12px;
  background-color: ${white};
`;
export const AIHistoryInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
export const AIHistoryInfoElement = styled.p<InfoElementProps>`
  ${dashboardText}
  width:${(props) => props.width};
  text-align: left;
`;

export const AIHistoryListElementNumber = styled.p`
  ${requestHistory};
`;

export const AIHistoryListElementDate = styled.p`
  ${requestHistory};
`;
export const AIHistoryListElementType = styled.p<{ type: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 44px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.type === "SocialMediaPost"
      ? "rgba(240, 149, 255, 0.13)"
      : props.type === "ContentPlan"
      ? "rgba(184, 191, 255, 0.15)"
      : "rgba(176, 128, 237, 0.13)"};

  color: ${(props) =>
    props.type === "SocialMediaPost"
      ? "#F095FF"
      : props.type === "ContentPlan"
      ? "#B0B8FF"
      : "##B080ED"};
`;

export const AIHistoryListElementActionButtons = styled.div`
  display: flex;
  gap: 12px;
`;
export const AIHistoryListElementActionButton = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background-color: #414141;
  cursor: pointer;
`;
export const ButtonSvg = styled.svg``;
export const AIHistoryListWrap = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  margin: 16px;
  overflow: scroll;
`;

export const AIHistoryListElement = styled.li`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const EmptySvg = styled.svg`
  margin-top: 64px;
`;
export const AIHistoryEmptyTitle = styled.p`
  ${d2}
  margin: 24px 0 8px 0;
`;
export const AIHistoryEmptySubtitle = styled.p`
  ${subtotalText}
`;
