import {
  plan,
  social,
  strategy,
} from "../../assets/image/ai/featureCards/index";

import { TAIGeneratorCard } from "../../types";

const aiGeneratorPageData: TAIGeneratorCard[] = [
  { id: 25, img: social, text: "Generate social media posts", link:"/social-post-generator" },
  { id: 47, img: plan, text: "Generate content plan", link:"/content-plan-generator" },
  { id: 29, img: strategy, text: "Generate marketing strategy", link:"/marketing-strategy-generator" },
];

export default aiGeneratorPageData;
