import styled from "@emotion/styled";
import { white } from "../../assets";

export const PageDashboardWrap = styled.div`
  text-align: center;
  background-color: ${white};
  padding-top: 92px;
`;
export const PageDashboardAdditionalWrap = styled.div`
  display: flex;
  min-height: 100vh
`;
