import { FC } from "react";
import {
  StyledHeroCard,
  CardContent,
  CardText,
  Icon,
} from "./HeroFirstCard.styled";
import sprite from "../../icons/symbol-defs.svg";
import { useNavigate } from "react-router-dom";

const HeroFirstCard: FC = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/ai-generator");
  };
  return (
    <StyledHeroCard>
      <CardContent onClick={handleClick}>
        <CardText>Try our AI content plan generator</CardText>
        <Icon width="41" height="20">
          <use xlinkHref={`${sprite}#vector`}></use>
        </Icon>
      </CardContent>x
    </StyledHeroCard>
  );
};

export default HeroFirstCard;
