import { TNavList } from "../types";

export const navLinks: TNavList = [
  {
    to: "/",
    text: "Services",
    scroll: "services"
  },
  {
    to: "/courses",
    text: "Courses",
  },
  {
    to: "/ai-generator",
    text: "AI Generator",
  },
];
