import styled from "@emotion/styled";

export const StarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Star = styled.div<{ filled: number; width: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  background: ${(props) =>
    `linear-gradient(90deg, #f0f424 ${props.filled}%, #e4e5e9 ${props.filled}%)`};
  mask: url('data:image/svg+xml;utf8,<svg width="34" height="33" viewBox="0 0 34 33" xmlns="http://www.w3.org/2000/svg"><path fill="white" d="M12.767 5.91726C14.6301 2.43256 15.5617 0.690213 17 0.690213C18.4383 0.690213 19.3699 2.43256 21.233 5.91726L22.0626 7.46893C22.6016 8.47717 22.8712 8.98129 23.3171 9.30527C23.763 9.62926 24.3257 9.72981 25.4512 9.93092L27.1833 10.2404C31.0732 10.9355 33.0181 11.2831 33.4626 12.651C33.9071 14.0189 32.5378 15.4433 29.7994 18.292L28.5801 19.5605C27.7877 20.3848 27.3916 20.7969 27.2213 21.3211C27.0509 21.8453 27.1292 22.4116 27.2857 23.5441L27.5266 25.2871C28.0676 29.2013 28.3381 31.1585 27.1744 32.0039C26.0108 32.8493 24.233 31.9873 20.6775 30.2632L19.0943 29.4955C18.0656 28.9967 17.5512 28.7473 17 28.7473C16.4488 28.7473 15.9344 28.9967 14.9057 29.4955L13.3225 30.2632C9.76696 31.9873 7.98919 32.8493 6.82556 32.0039C5.66193 31.1585 5.93242 29.2013 6.47339 25.2871L6.71428 23.5441C6.8708 22.4116 6.94906 21.8453 6.77874 21.3211C6.60841 20.7969 6.21225 20.3848 5.41994 19.5605L4.20057 18.292C1.46215 15.4433 0.0929468 14.0189 0.537414 12.651C0.981882 11.2831 2.92682 10.9355 6.81669 10.2404L8.54878 9.93092C9.67426 9.72981 10.237 9.62926 10.6829 9.30527C11.1288 8.98129 11.3984 8.47717 11.9374 7.46893L12.767 5.91726Z"/></svg>')
    no-repeat center;
  mask-size: contain;
  margin-right: 4px;
`;
