import { useState, useEffect } from "react";
import sprite from "../../icons/symbol-defs.svg";
import Button from "../Button";
import { TestWrap, TextWrapper, TestText, TestSvg } from "./Banners.styled";

function TestBanner() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = () => {
    window.open("https://woohooooo.typeform.com/course-test", "_blank", "noopener noreferrer");
  };
  return (
    <TestWrap>
      <TextWrapper>
        <TestText>Think you know modern marketing?</TestText>
        <TestSvg width="52" height="50">
          <use xlinkHref={`${sprite}#stars`}></use>
        </TestSvg>
      </TextWrapper>

      <Button
        onClick={handleClick}
        withIcon={false}
        width={isMobile ? "295px" : "380px;"}
        margin="0px"
        text="Test it now"
      />
    </TestWrap>
  );
}

export default TestBanner;
