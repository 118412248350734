import { FC } from "react";
import previewImage from "../../assets/image/Video.png";
import { CustomerReviewsTitle } from "./CustomerReviews.styled";
import VideoPlayerWithPreview from "../VideoPlayerWithPreview";

const CustomerReviews: FC = () => {
  return (
    <>
      <CustomerReviewsTitle>Customer Reviews</CustomerReviewsTitle>
      <VideoPlayerWithPreview
        link="https://www.youtube.com/embed/r15kODOrUwU?si=lDsZosLLqRc58o4_"
        preview={previewImage}
      />
    </>
  );
};

export default CustomerReviews;
