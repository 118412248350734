import styled from "@emotion/styled";

export const SwitchContainer = styled.div<{ isOn: boolean }>`
  display: flex;
  align-items: center;
  border: 1px ${({ isOn }) => (isOn ? "#B8BFFF54" : "#41414130")} solid;
  width: 47px;
  height: 24px;
  border-radius: 20px;
  transition: border-color 0.3s linear;

`;

export const SwitchLabel = styled.label<{ isOn: boolean }>`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

export const SwitchButton = styled.span<{ isOn: boolean }>`
  position: absolute;
  top: 2px;
  left: ${({ isOn }) => (isOn ? "23px" : "2px")};
  width: 20px;
  height: 20px;
  background-color: ${({ isOn }) => (isOn ? "#B080ED" : "#333333")};
  border-radius: 50%;
  transition: left 0.3s, color 0.3s linear;
`;