import styled from "@emotion/styled";
import { c2, h3, h4, h6, titleM } from "../../assets/";

export const OurServiceTitle = styled.h3`
  margin-bottom: 64px;
  ${h3};
  @media (max-width: 1024px) {
    ${titleM}
    margin-bottom: 32px;
  }
  @media (max-width: 768px) {
    ${h6}
  }
`;

export const ServiceTitle = styled.h4`
  ${h4}
  margin-bottom: 48px;
  @media (max-width: 1024px) {
    ${h6}
    margin-bottom: 24px;
  }
  @media (max-width: 768px) {
    ${c2}
  }
`;
