import { useDispatch } from "react-redux";
import {
  OrderCardWrapper,
  OrderCardBody,
  OrderCardImg,
  OrderCardTitle,
  OrderCardText,
  OrderCardInfo,
  OrderCardCost,
  OrderCardRemoveIcon,
  OrderPriceBlock,
  CurrentOrderPrice,
  OldOrderPrice,
  HoursCoastBlock,
  HoursCoastPrice,
  HoursCoastText,
  OrderCardRow,
} from "./OrderCard.styled";
import { removeProduct } from "../../store/slices/cartSlice";
import sprite from "../../icons/symbol-defs.svg";

type OrderCardProps = {
  id: string;
  img: string;
  title: string;
  description?: string | undefined;
  price: number;
};

const OrderCard: React.FC<OrderCardProps> = ({
  id,
  img,
  title,
  description,
  price,
}) => {
  const dispatch = useDispatch();
  const handleProduct = (productId: string) => {
    dispatch(removeProduct(productId));
  };

  const handleClick = () => {
    handleProduct(id);
  };

  const calculateDiscountedPrice = (originalPrice: number): number => {
    const discountRate = 0.1;
    const discountedPrice = (originalPrice * (1 - discountRate)).toFixed();
    return Number(discountedPrice);
  };
  return (
    <OrderCardWrapper>
      <OrderCardBody>
        <OrderCardImg src={img} alt="" />
        <OrderCardInfo>
          <OrderCardRow>
            <OrderCardTitle>{title}</OrderCardTitle>
            <OrderCardRemoveIcon onClick={handleClick}>
              <use xlinkHref={`${sprite}#delete`}></use>
            </OrderCardRemoveIcon>
          </OrderCardRow>
          {description ? <OrderCardText>{description}</OrderCardText> : <></>}
          <OrderCardCost>
            <HoursCoastBlock>
            <HoursCoastPrice>${(calculateDiscountedPrice(price)).toFixed()}</HoursCoastPrice>
            <HoursCoastText>Per hour</HoursCoastText>
            </HoursCoastBlock>
            <OrderPriceBlock>
              <OldOrderPrice>${price}</OldOrderPrice>
              <CurrentOrderPrice>
                ${calculateDiscountedPrice(price)}
              </CurrentOrderPrice>
            </OrderPriceBlock>
          </OrderCardCost>
        </OrderCardInfo>
      </OrderCardBody>
    </OrderCardWrapper>
  );
};

export default OrderCard;
