import { marketMain, marketingMain } from "../assets/image/developments/index";

const planing = [
  {
    img: marketMain,
    text: "Market and competitor research and analysis",
    id: "market_research",
  },
  {
    img: marketingMain,
    text: "Marketing strategy development",
    id: "marketing_strategy",
  },
];
export default planing;
