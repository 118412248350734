import React from "react";
import { DashboardWrapper, DashboardTitle } from "./Dashboard.styled";
import { TUserSubscriptionInfo } from "../../types";
import DashboardFeaturesList from "../DashboardFeaturesList";
import AIHistory from "../AIHistory";

type DashboarProps = {
  userInfo: TUserSubscriptionInfo | null;
};

const Dashboard: React.FC<DashboarProps> = ({userInfo}) => {
  return (
    <DashboardWrapper>
      <DashboardTitle>Dashboard</DashboardTitle>
      <DashboardFeaturesList userInfo={userInfo} />
      <AIHistory />
    </DashboardWrapper>
  );
};

export default Dashboard;
