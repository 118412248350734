import React, { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { TContentPlan, TContentPlanHistoryPrompt } from "../../types/index";
import sprite from "../../icons/symbol-defs.svg";
import Button from "../Button";
import {
  AIGeneratorProptLabel,
  PromptWrap,
  StyledForm,
  AIGeneratorPromptFormWrap,
  AIGeneratorPromptInputWrap,
  AiGeneratorNotificationRow,
  AiGeneratorNotification,
  NotificationSvg,
  PromptFooter,
} from "./ContentPlanGenerator.styled";
import TextareaField from "../TextareaField/TextareaField";
import Select from "../Select";

interface ContentPlanPromptProps {
  onData: (data: TContentPlan) => void;
  setLoading: (loading: boolean) => void;
  setTechnicalError: (error: boolean) => void;
  setGeneratorLimit: (isLimited: boolean) => void;
  prompt: TContentPlanHistoryPrompt | null;
}

interface FormValues {
  product_description: string;
}

const validationSchema = Yup.object().shape({
  product_description: Yup.string().required("Required"),
});

const socialTypeData: string[] = [
  "Instagram",
  "Facebook",
  "Twitter",
  "LinkedIn",
];

const durationData: string[] = ["1 week", "2 weeks", "3 weeks", "4 weeks"];

const ContentPlanPrompt: React.FC<ContentPlanPromptProps> = ({
  onData,
  setLoading,
  setTechnicalError,
  setGeneratorLimit,
  prompt,
}) => {
  const initialValues: FormValues = {
    product_description: prompt?.productDescription || "",
  };

  const user = useSelector((state: RootState) => state.user);
  const [isAvailableGenerator, setIsAvailableGenerator] = useState(false);
  const [socialType, setSocialType] = useState<string>(
    prompt?.socialMediaType || "Instagram"
  );
  const [duration, setDuration] = useState<string>(
    prompt?.contentPlanDuration || "1 week"
  );

  useEffect(() => {
    if (prompt) {
      setSocialType(prompt.socialMediaType || "Instagram");
      setDuration(prompt.contentPlanDuration || "1 week");
    }
  }, [prompt]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
         `${process.env.REACT_APP_SERVER_URL}/user/get-user-info`,
          {},
          {
            withCredentials: true,
          }
        );
        if (response.data.usageLimits.socialMediaPosts) {
          setIsAvailableGenerator(response.data.usageLimits.socialMediaPosts);
        }
      } catch (error) {
        console.error("Error fetching user information:", error);
      } finally {
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    if (!isAvailableGenerator) {
      try {
        setLoading(true);
        const result = await generateContentPlan(values);
        onData(result);
      } catch (error) {
        console.error("Error:", error);
        setTechnicalError(true);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    } else {
      setGeneratorLimit(true);
    }
  };

  const generateContentPlan = async (formData: FormValues) => {
    const url =`${process.env.REACT_APP_SERVER_URL}/openai/content-plan-generator`;
    try {
      const response = await axios.post(url, {
        contentPlanPlatformName: socialType,
        contentPlanProductDescription: formData.product_description,
        contentPlanDuration: duration,
        userId: user.id,
      });
      return response.data as TContentPlan;
    } catch (error) {
      console.error("Request failed:", error);
      setTechnicalError(true);
      return {
        _id: "",
        account_goals: "",
        additional_information: "",
        content_types: "",
        target_audience: "",
        posts: [],
        errors: "Request failed",
        requestGeneratorType: "",
        requestDate: "",
      } as TContentPlan;
    }
  };

  return (
    <PromptWrap>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ handleSubmit, setValues }) => (
          <StyledForm onSubmit={handleSubmit}>
            <AIGeneratorPromptFormWrap>
              <AIGeneratorPromptInputWrap>
                <AIGeneratorProptLabel>Choose platform:</AIGeneratorProptLabel>
                <Select
                  data={socialTypeData}
                  onSelect={setSocialType}
                  defaultValue={socialType}
                />
              </AIGeneratorPromptInputWrap>
              <AIGeneratorPromptInputWrap>
                <AIGeneratorProptLabel>
                  Describe your product:
                </AIGeneratorProptLabel>
                <TextareaField
                  name="product_description"
                  placeholder="Describe your product"
                />
              </AIGeneratorPromptInputWrap>
              <AIGeneratorPromptInputWrap>
                <AIGeneratorProptLabel>Choose duration:</AIGeneratorProptLabel>
                <Select
                  data={durationData}
                  onSelect={setDuration}
                  defaultValue={duration}
                />
              </AIGeneratorPromptInputWrap>
            </AIGeneratorPromptFormWrap>
            <PromptFooter>
              <Button
                btnType="primary"
                type="submit"
                text="Generate"
                withIcon={false}
              />
              <AiGeneratorNotificationRow>
                <NotificationSvg width="24" height="24">
                  <use xlinkHref={`${sprite}#clock`} />
                </NotificationSvg>
                <AiGeneratorNotification>
                  Please note that the personalized model training process
                  initiated by clicking this button will take approximately{" "}
                  <span>2 minutes</span>
                </AiGeneratorNotification>
              </AiGeneratorNotificationRow>
            </PromptFooter>
          </StyledForm>
        )}
      </Formik>
    </PromptWrap>
  );
};

export default ContentPlanPrompt;
