import { FC } from "react";
import { TNavList } from "../../types";
import { List, Element } from "./NavList.styled";
import NavLinkComponent from "../NavLinkComponent";

interface INavListProps {
  navList: TNavList;
  color?: "pink" | "black" | "yellow" | "white";
}

const NavList: FC<INavListProps> = ({ navList, color = "black" }) => {
  return (
    <nav>
      <List>
        {navList.map(({ to, text, scroll }) => (
          <Element key={text}>
            <NavLinkComponent
              to={to}
              text={text}
              color={color}
              scroll={scroll}
            />
          </Element>
        ))}
      </List>
    </nav>
  );
};

export default NavList;
