import styled, { keyframes } from "styled-components";

type SpinnerContainerProps = {
  $margint?: string;
  $marginb?: string;
};

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.img`
  animation: ${spin} 1s linear infinite;
`;

export const SpinnerContainer = styled.div<SpinnerContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: ${({ $margint }) => $margint};
  margin-bottom: ${({ $marginb }) => $marginb};
`;
