import styled from "@emotion/styled";
import {h5, partnerText, green } from "../../assets";

export const PartnersWrap = styled.div`
  position: relative;
`;
export const PartnersTitle = styled.h4`
  ${h5}
  margin-bottom: 48px;
`;
export const Partner = styled.p`
  ${partnerText}
`;
export const PartnerSvg = styled.svg`
  position: absolute;
  top: -25px;
  left: 280px;
  margin-right: 69px;
  fill: ${green};
`;
