import styled from "@emotion/styled";
import image from "../../assets/image/firstCardImage.png";
import { c1, h5, whiteRGBA } from "../../assets/";

export const StyledHeroCard = styled.div`
  padding: 55px 20px 17px 20px;
  background-image: url(${image});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 32px;

  @media (max-width: 768px) {
    padding: 20px;
    height: 190px;
  }
`;

export const CardContent = styled.div`
  background-color: ${whiteRGBA};
  padding: 24px 43px 41px 16px;
  backdrop-filter: blur(4px);
  border-radius: 32px;
  height: 100%;
  display: flex;
  cursor: pointer;
  @media (max-width: 1048px) {
    padding: 24px 10px 17px 16px;
    flex-direction: row;
    align-items: flex-end;
  }
  @media (max-width: 768px) {
    padding: 24px 9px 25px 16px;
    flex-direction: row;
  }
`;

export const CardText = styled.p`
  ${h5}
  font-weight: 500;
  max-width: 90%;
  @media (max-width: 1048px) {
    line-height: 40px;
    max-width: 200px;
  }
  @media (max-width: 768px) {
    ${c1}
    max-height:117px;
    max-width: 600px;
  }
`;

export const Icon = styled.svg`
  margin-top: auto;
  min-width: 41px;
  max-width: 41px;
  margin-left: 10px;
  @media (max-width: 768px) {
    margin-right: 25px;
    margin-left: 20px;
  }
`;
