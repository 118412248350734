import styled from "@emotion/styled";
import {
  c2,
  c3,
  c4,
  d2,
  green,
  greenRGBA,
  h5,
  h6,
  informerText,
  violet,
  white,
} from "../../assets";
import starP from "../../assets/image/star-p.png";
import starG from "../../assets/image/star-g.png";
import starGT from "../../assets/image/star-g-t.png";
import starGM from "../../assets/image/star-g-m.png";
import starPM from "../../assets/image/star-p-m.png";
import starPT from "../../assets/image/star-p-t.png";

export const BannerWrap = styled.div<{
  background: "green" | "white" | "blue";
  bannerType: "sale" | "test" | "info" | "info-timer" | "checkout-informer";
  marginB: string;
}>`
  display: flex;
  justify-content: ${({ bannerType }) =>
    bannerType === "info" ? "center" : "center"};
  align-items: center;
  width: 100%;
  height: ${({ bannerType }) =>
  bannerType === "info-timer"
    ? "56px"
    : bannerType === "checkout-informer"
    ? "72px"
    : "176px"};
  margin-bottom: ${({ marginB }) => marginB};
  border-radius: ${({ bannerType }) =>
    bannerType === "info-timer" ? "10px" : "36px"};
  position: relative;
  overflow: hidden;

  background-color: ${({ background }) =>
    background === "white"
      ? white
      : background === "green"
      ? greenRGBA
      : "#B8BFFF26"};
  @media (max-width: 768px) {
    ${({ bannerType }) => bannerType === "checkout-informer" && `
      height: 100px;
    `}
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  ${({ bannerType }) =>
    bannerType === "sale"
      ? `
      &:before {
        content: "";
        background-position: left;
        background-image: url('${starG}');
        left: 280px;
        z-index: 0;
      }
      @media (max-width: 1024px) {
        &:before {
          background-image: url('${starGT}');
          left: 0px;
        }
          justify-content: flex-end;
      }
      @media (max-width: 768px) {
        &:before {
          background-image: url('${starGM}');
        }
      }
    `
      : bannerType === "test"
      ? `
      &:before {
        content: "";
        background-position: right;
        background-image: url('${starP}');
        z-index: 0;
      }
      @media (max-width: 1024px) {
        &:before {
          background-image: url('${starPT}');
        }
        height: 256px;
      }
      @media (max-width: 768px) {
        &:before {
          background-image: url('${starPM}');
        }
        height: 202px;
      }
    `
      : bannerType === "info"
      ? `
      &:before {
        content: "";
        background-image: none;
        background-color: #B8BFFF;
        z-index: 0;
      }
    `
      : bannerType === "info-timer"
      ? `
       @media (max-width: 768px) {
          height:auto;
          width:auto;
          padding:16px 24px;
          &:before {
            display:none
            }
          &:after {
              display:none
          }
              }
    `
      : ""}
`;

export const SaleWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 41px;
  padding: 43px 83px 43px 65px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
    gap: 13px;
    padding: 15px 13px 43px 78px;
  }
`;

export const SaleText = styled.p`
  ${h5}
  text-align:left;
  width: 567px;
  font-weight: bold;
  z-index: 2;

  @media (max-width: 1024px) {
    ${h6}
    width: 567px;
    text-align: right;
  }
  @media (max-width: 768px) {
    ${c3}
    min-width: 260px;
    width: 100%;
    padding-right: 13px;
  }
`;

export const SaleStrong = styled.span`
  color: ${green};
`;
export const SaleBannerTimer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const SaleSvg = styled.svg`
  position: absolute;
  right: -40px;
  top: 20px;
  margin-right: 69px;
  fill: ${green};
  @media (max-width: 768px) {
    margin-right: 34px;
    top: initial;
    right: 20px;
    bottom: -10px;
  }
`;

export const InfoWrap = styled.div`
  display: flex;
  gap: 41px;
  @media (max-width: 768px) {
  }
`;

export const InfoText = styled.p`
  ${h5}
  text-align:center;

  font-weight: bold;
  z-index: 2;

  @media (max-width: 1024px) {
    ${h6}
    width: 567px;
  }
  @media (max-width: 768px) {
    ${c3}
    min-width: 260px;
    width: 100%;
    padding-right: 13px;
  }
`;

export const TestWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-left: 70px;
  margin-right: 20px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 28px;
  }
  @media (max-width: 768px) {
    margin: 0 16px;
  }
`;
export const TextWrapper = styled.div`
  display: flex;
`;

export const TestText = styled.p`
  ${h5}
  max-width: 500px;
  width: 100%;
  font-weight: bold;
  z-index: 2;
  @media (max-width: 1024px) {
    ${h5}
  }
  @media (max-width: 768px) {
    ${c2}
    max-width: 276px;
  }
`;

export const TestSvg = styled.svg`
  margin-right: 69px;
  transform: rotate(50deg);
  fill: ${violet};
  z-index: 2;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const InformerWrap = styled.div`
  display: flex;
  gap: 6px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const InformerText = styled.p`
  ${informerText}
  text-align:center;

  font-weight: 600;
  z-index: 2;
  & > span {
    color: ${violet};
  }
  @media (max-width: 768px) {
    ${d2}
    width: 100%;
  }
`;

export const Icon = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 33px;
  height: 33px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  background: #b080ed;
  border-radius: 50%;
  margin-left: 32px;
  @media (max-width: 768px) {
    margin-left: 15px;
  }
`;
export const CheckoutInformerWrap = styled.div`
  display: flex;
  gap: 24px;
`;
export const SummaryText = styled.p`
  ${c4}
  font-weight: 500;
  text-align: left;
  margin-right: 16px;
`;