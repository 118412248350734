import React from "react";
import { useField } from "formik";
import { Input, Label, Indicator } from "./Checkbox.styled";

type CheckboxProps = {
  name: string;
};

const Checkbox: React.FC<CheckboxProps> = ({ name }) => {
  const [field, , helpers] = useField(name);

  const handleChange = () => {
    helpers.setValue(!field.value);
  };

  return (
    <Label>
      <Input
        {...field}
        type="checkbox"
        checked={field.value}
        onChange={handleChange}
      />
      <Indicator />
    </Label>
  );
};

export default Checkbox;
