import styled from "@emotion/styled";

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  list-style: none;
  padding: 0;
  margin: 150px 0 0 0;
`;

export const Element = styled.li`
  padding: 0;
  margin: 0;
  z-index: 3;
`;
