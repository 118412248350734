import styled from "@emotion/styled";

type DashboardContentProps = {};

export const DashboardContentWrapper = styled.div<DashboardContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-top: 32px;
  margin-left: 32px;
  z-index: 2;
`;
