import React, { useEffect, useState } from "react";
import sprite from "../../icons/symbol-defs.svg";
import NavList from "../NavList";
import { navLinks } from "../../assets";
import {
  FooterCss,
  Logo,
  FooterWrap,
  LogoWrap,
  CredoWrap,
  FooterHeader,
  FooterBody,
  FooterCompanyAddres,
  FooterAdressColumn,
  FooterInfoTitle,
  MainInfoWrap,
  SocialMediaList,
  SocialMediaWrap,
  SocialMediaColumn,
  SocialMediaSvgWrap,
  SocialMediaSvg,
  EmailWrap,
  Email,
  LinkWrap,
  CustomLink,
} from "./Footer.styled";

const Footer: React.FC = () => {
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 1024);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <FooterWrap>
      <FooterCss>
        <MainInfoWrap>
          <FooterHeader>
            {isMobile ? (
              <LogoWrap>
                <Logo width="440" height="72">
                  <use xlinkHref={`${sprite}#woohoo`}></use>
                </Logo>
              </LogoWrap>
            ) : (
              <></>
            )}
            {isMobile ? (
              <></>
            ) : (
              <FooterInfoTitle>Our Locations</FooterInfoTitle>
            )}
            <NavList navList={navLinks} color="white" />
            {isMobile ? (
              <></>
            ) : (
              <FooterInfoTitle>Our Social Media</FooterInfoTitle>
            )}
          </FooterHeader>
          <FooterBody>
            <FooterAdressColumn>
              {isMobile ? (
                <FooterInfoTitle>Our Locations</FooterInfoTitle>
              ) : (
                <></>
              )}
              <FooterCompanyAddres>
                <span>USA - Silicon Valley</span> WOOHOO! AGENCY! LLC 211, Hi
                Vista Road, Sausalito, CA, USA, 94965
              </FooterCompanyAddres>
              <FooterCompanyAddres>
                <span>UAE - Dubai</span> WOOHOO SOCIAL MEDIA MARKETING CO.
                L.L.C. Dubai, Empire Heights Business Centre, Tower B, 7 office,
                8th floor, 27206
              </FooterCompanyAddres>
              <FooterCompanyAddres>
                <span>Europe</span> Woohoo Branding Design OU Estonia, Tallinn,
                Harju maakond, Vesivarava tn 50-201, 10152
              </FooterCompanyAddres>
            </FooterAdressColumn>
            <EmailWrap>
              <SocialMediaSvgWrap to="">
                <SocialMediaSvg type="email">
                  <use xlinkHref={`${sprite}#email`}></use>
                </SocialMediaSvg>
              </SocialMediaSvgWrap>
              <Email href="mailto:support@woohooooo.com">
                ilove@woohooooo.com
              </Email>
            </EmailWrap>
            <SocialMediaColumn>
              {isMobile ? (
                <FooterInfoTitle>Our Social Media</FooterInfoTitle>
              ) : (
                <></>
              )}
              <SocialMediaWrap>
                <SocialMediaList>
                  <SocialMediaSvgWrap to="https://www.linkedin.com/company/89602408">
                    <SocialMediaSvg type="linkedin">
                      <use xlinkHref={`${sprite}#linkedin`}></use>
                    </SocialMediaSvg>
                  </SocialMediaSvgWrap>
                  <SocialMediaSvgWrap to="https://www.instagram.com/woohoo.branding">
                    <SocialMediaSvg width="48px" height="48px" type="instagram">
                      <use xlinkHref={`${sprite}#instagram`}></use>
                    </SocialMediaSvg>
                  </SocialMediaSvgWrap>
                </SocialMediaList>
                {isTablet ? (
                  <Email href="mailto:support@woohooooo.com">
                    ilove@woohooooo.com
                  </Email>
                ) : (
                  <></>
                )}
              </SocialMediaWrap>
              {isMobile ? (
                <></>
              ) : (
                <LogoWrap>
                  <Logo width="440" height="72">
                    <use xlinkHref={`${sprite}#woohoo`}></use>
                  </Logo>
                </LogoWrap>
              )}
            </SocialMediaColumn>
          </FooterBody>
        </MainInfoWrap>

        <CredoWrap>
          <span>COPYRIGHT © 2024 WOOHOO</span>
          <LinkWrap>
            <CustomLink to="https://app.termly.io/policy-viewer/policy.html?policyUUID=dcafb90d-90c6-4b07-955d-1ac447f91a5f">
              TERMS & CONDITIONS |{" "}
            </CustomLink>
            <CustomLink to="https://app.termly.io/policy-viewer/policy.html?policyUUID=997ef8e0-9529-40fa-a573-2e87e6149829">PRIVACY POLICY | </CustomLink>
            <CustomLink to="/cookies-policy">COOKIES POLICY</CustomLink>
          </LinkWrap>
        </CredoWrap>
      </FooterCss>
    </FooterWrap>
  );
};

export default Footer;
