import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

type LayoutProps = {
  type?: string;
};

const Layout: React.FC<LayoutProps> = ({ type }) => {
  return (
    <div>
      <Header type={type} />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
