import styled from "@emotion/styled";
import { buttonText, d1, reviewText, violet, white } from "../../assets";

export const ReviewCardWrap = styled.div`
  padding: 50px;
  border-radius: 30px;
  box-shadow: 0px 15px 40px 0px #b8bfff36;
  @media (max-width: 768px) {
    padding: 32px 24px;
  }
`;
export const CustomerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  margin-right: 8px;
  border-radius: 50%;
  background: ${violet};
`;
export const CustomerName = styled.div`
  ${d1}
`;
export const CustomerMainInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
export const RatingWrap = styled.div``;
export const ReviewDate = styled.div``;
export const ReviewText = styled.p<{
  height?: number | null;
  isExpanded?: boolean;
}>`
  ${reviewText}
  margin-top: 24px;
  max-height: ${({ isExpanded }) => (isExpanded ? "1000px" : "150px")};
  overflow: hidden;
  overflow-wrap: break-all;
  word-break: break-word;
  transition: max-height 0.5s ease-in-out;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ isExpanded }) => (isExpanded ? "none" : "4")}; */
`;
export const ReviewCustomerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const ReviewInfoRow = styled.div`
  display: flex;
  gap: 8px;
`;
export const User = styled.svg`
  cursor: pointer;
  fill: transparent;
  stroke: ${white};
`;
export const ReviewTextBlock = styled.div``;
export const ViewMoreButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;
export const ViewMoreButton = styled.button`
  all: unset;
  ${buttonText}
  color: ${violet};
  text-decoration: underline;
`;
