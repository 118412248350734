import { useState, useEffect } from "react";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import InputComponent from "../InputField";
import Button from "../Button";
import InputFile from "../InputFile";
import Popup from "../Popup";
import {
  StyledForm,
  InputsWrap,
  FormTitle,
  TitleMod,
  InputsList,
  FormWrap,
  Backdrop,
  BackdropTitle,
  BackdropSubtitle,
} from "./OrderForm.styled";

interface FormValues {
  name: string;
  email: string;
  description: string;
  file: File | null;
}

const initialValues: FormValues = {
  name: "",
  email: "",
  description: "",
  file: null,
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  description: Yup.string().max(2000, "Too Long!"),
  file: Yup.mixed().nullable(),
});

const fileTypes = [
  "jpeg",
  "img",
  "jpg",
  "png",
  "pdf",
  "pptx",
  "ppt",
  "pptm",
  "rtf",
  "odt",
  "odp",
  "doc",
  "docx",
  "txt",
  "zip",
];
const OrderForm = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);

  const handleSubmit = async (
    values: FormValues,
    {
      resetForm,
      setSubmitting,
    }: { resetForm: () => void; setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      setLoading(true);
      await sendMail(values);
      setSuccessMessage(true);
      setIsPopupOpen(true);
      resetForm();
      setReset(true);
    } catch (error) {
      console.error("Error sending email:", error);
      setSuccessMessage(false);
      setIsPopupOpen(true);
      setLoading(false);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (reset) {
      handleFormReset();
    }
  }, [reset]);

  const handleFormReset = () => {
    setReset(false);
  };

  const clickPopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const sendMail = async (formData: FormValues) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/nodemailer/send_email`;
    const form = new FormData();
    form.append("name", formData.name);
    form.append("email", formData.email);
    form.append("type", "application");
    if (formData.description) {
      form.append("description", formData.description);
    }
    if (formData.file) {
      form.append("file", formData.file);
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    await axios.post(url, form, config);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, setFieldValue }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Backdrop>
              <BackdropTitle>
                If you haven’t found the service you need on our website,
                describe your project, and we’ll prepare a custom offer for you
              </BackdropTitle>
              <BackdropSubtitle>
                Customize your experience with our tailored solutions.
              </BackdropSubtitle>
              <FormWrap>
                <InputsWrap>
                  <FormTitle>
                    Empower Your Brand: <TitleMod>Order Now</TitleMod>!
                  </FormTitle>

                  <InputsList>
                    <InputComponent name="name" placeholder="Name" />
                    <InputComponent name="email" placeholder="Email" />
                    <InputComponent
                      name="description"
                      placeholder="Describe your project"
                    />
                    <InputFile
                      handleChange={(file) => setFieldValue("file", file)}
                      name="file"
                      types={fileTypes}
                      label="Drag and drop or click here"
                      reset={reset}
                    />
                  </InputsList>

                  <Button
                    btnType="primary"
                    type="submit"
                    //disabled={loading}
                    text={loading ? "Loading..." : "Get Started"}
                    withIcon={false}
                  />
                </InputsWrap>
              </FormWrap>
            </Backdrop>
          </StyledForm>
        )}
      </Formik>
      {isPopupOpen && (
        <Popup
          popupType={
            successMessage ? "application-successful" : "application-error"
          }
          isOpen={isPopupOpen}
          onClose={clickPopup}
        />
      )}
    </>
  );
};

export default OrderForm;
