import spinner from "../../assets/image/Spinner.png";

import { SpinnerContainer, Spinner } from "./LoadingSpinner.styled";

type LoadingSpinnerProps = {
  width: string;
  height: string;
  $margint?: string;
  $marginb?: string;
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  height,
  width,
  $margint,
  $marginb,
}) => {
  return (
    <SpinnerContainer $margint={$margint}  $marginb={ $marginb}>
      <Spinner width={width} height={height} src={spinner}></Spinner>
    </SpinnerContainer>
  );
};

export default LoadingSpinner;
