/**
 * Converting the file size to MB
 * @param size : Size to be converted;
 * @returns number
 *
 * @internal
 */
export const getFileSizeMB = (size: number): number => {
  return size / (1024 * 1024); // переводимо байти в мегабайти
};

/**
 *
 * Check if the file uploaded is in the type list or not
 * @param file - The File uploaded
 * @param types - Available types
 * @returns boolean
 *
 * @internal
 */
export const checkType = (file: File, types: Array<string>): boolean => {
  const extension: string = file.name.split(".").pop()?.toLowerCase() || ""; // Отримуємо розширення файлу
  const loweredTypes = types.map((type) => type.toLowerCase()); // Перетворюємо всі типи на нижній регістр для порівняння
  const fileSizeMB = getFileSizeMB(file.size); // Отримуємо розмір файлу в мегабайтах

  return loweredTypes.includes(extension) && fileSizeMB <= 25; // Перевіряємо, чи є розширення в списку і чи не перевищує файл розмір 25 MB
};

/**
 * Get the files for input "accept" attribute
 * @param types - Allowed types
 * @returns string
 *
 * @internal
 */
export const acceptedExt = (types: Array<string> | undefined) => {
  if (types === undefined) return "";
  return types.map((type) => `.${type.toLowerCase()}`).join(",");
};

export const checkImageAspectRatio = (file: File): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    img.onload = () => {
      const isSquare = img.width === img.height;
      resolve(isSquare); // resolve з boolean значенням
    };

    img.onerror = () => reject(new Error("Failed to load image"));

    reader.onload = (event) => {
      if (event.target?.result) {
        img.src = event.target.result as string;
      } else {
        reject(new Error("Failed to read file"));
      }
    };

    reader.readAsDataURL(file);
  });
};
