import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CartState {
  products: string[];
}

const initialState: CartState = {
  products: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addProduct(state, action: PayloadAction<string>) {
      const newItemId = action.payload;
      const isItemExist = state.products.some(product => product === newItemId);
      if (!isItemExist) {
        state.products.push(newItemId);
      }
    },
    removeProduct(state, action: PayloadAction<string>) {
      const itemIdToRemove = action.payload;
      state.products = state.products.filter(product => product !== itemIdToRemove);
    },
    clearCart(state) {
      state.products = [];
    },
  },
});

export const { addProduct, removeProduct, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
