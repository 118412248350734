import React from "react";
import {
  DashboardFeaturesWrap,
  DashboardFeaturesListElement,
} from "./DashboardFeaturesList.styled";
import DashboardFeatureCard from "../DashboardFeatureCard";
import { TUserSubscriptionInfo } from "../../types";

type DashboardFeaturesListProps = {
  userInfo: TUserSubscriptionInfo | null;
};

const DashboardFeaturesList: React.FC<DashboardFeaturesListProps> = ({
  userInfo,
}) => {
  const limits = {
    socialMediaPosts: 20,
    contentPlans: 3,
    marketingStrategies: 1,
  };
  const list = [
    {
      id: 111,
      title: "Generate social media posts",
      creditsNumber: limits.socialMediaPosts,
      remainingCreditsNumber:
        limits.socialMediaPosts -
        (userInfo?.generatorUsages?.socialMediaPosts ?? 0),
      usedCreditsNumber: userInfo?.generatorUsages?.socialMediaPosts ?? 0,
      generatorLink: "/social-post-generator",
      isAttemptsAvailable: userInfo?.usageLimits.socialMediaPosts ?? false,
      subscriptionType: userInfo?.subscriptionType ?? "",
    },
    {
      id: 112,
      title: "Generate content plan",
      creditsNumber: limits.contentPlans,
      remainingCreditsNumber:
        limits.contentPlans - (userInfo?.generatorUsages?.contentPlans ?? 0),
      usedCreditsNumber: userInfo?.generatorUsages?.contentPlans ?? 0,
      generatorLink: "/content-plan-generator",
      isAttemptsAvailable: userInfo?.usageLimits.contentPlans ?? false,
      subscriptionType: userInfo?.subscriptionType ?? "",
    },
    {
      id: 113,
      title: "Generate marketing strategy",
      creditsNumber: 20,
      remainingCreditsNumber:
        limits.marketingStrategies -
        (userInfo?.generatorUsages?.marketingStrategies ?? 0),
      usedCreditsNumber: userInfo?.generatorUsages?.marketingStrategies ?? 0,
      generatorLink: "/marketing-strategy-generator",
      isAttemptsAvailable: userInfo?.usageLimits.marketingStrategies ?? false,
      subscriptionType: userInfo?.subscriptionType ?? "",
    },
  ];
  return (
    <DashboardFeaturesWrap>
      {list.map(
        ({
          title,
          id,
          remainingCreditsNumber,
          usedCreditsNumber,
          generatorLink,
          isAttemptsAvailable,
          subscriptionType,
        }) => {
          return (
            <DashboardFeaturesListElement key={id}>
              <DashboardFeatureCard
                title={title}
                remainingCreditsNumber={remainingCreditsNumber}
                usedCreditsNumber={usedCreditsNumber}
                generatorLink={generatorLink}
                isAttemptsAvailable={isAttemptsAvailable}
                subscriptionType={subscriptionType}
              />
            </DashboardFeaturesListElement>
          );
        }
      )}
    </DashboardFeaturesWrap>
  );
};

export default DashboardFeaturesList;
