import styled from "@emotion/styled";
import { lavender } from "../../assets";

type CardProps = {};

export const DashboardNavigationWrapper = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  border-right: 1px solid ${lavender};
`;
export const DashboardNavigationMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
`;
