import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ServicePageData } from "../../types/";
import { PageServiceWrap } from "./PageService.styled";
import Section from "../Section";
import HeroServices from "../HeroServices";
import ServiceIncList from "../ServiceIncList";
import Timeline from "../Timeline";
import Banners from "../Banners";
import OrderForm from "../OrderForm";
import ImageList from "../ImageList";
import Partners from "../Partners";
import Reviews from "../Reviews";

function PageService() {
  const { serviceId } = useParams<{ serviceId?: string }>();
  const [data, setData] = useState<ServicePageData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (serviceId) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/products/get-service-by-id`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ id: serviceId }),
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const service = await response.json();

          if (service && typeof service.product.mainImg === "string") {
            setData(service.product);
          } else {
            console.error(
              `Main image is not defined for service with ID ${serviceId}`
            );
          }
        } catch (error: any) {
          console.error(`Failed to fetch service: ${error.message}`);
        }
      }
    };

    fetchData();
  }, [serviceId]);

  return (
    <PageServiceWrap>
      {data ? (
        <>
          <Section>
            <HeroServices data={data} />
          </Section>

          {data.includesServices ? (
            <Section type="services">
              <ServiceIncList services={data.includesServices} />
            </Section>
          ) : (
            <></>
          )}
          <Section type="services">
            <Timeline
              timeLine={data.timeline}
              timlinePreventionText={data.timlinePreventionText}
              guideColor="rgba(217, 220, 255, 1);"
            />
          </Section>

          <Section type="services">
            <Banners bannerType="sale" background="green" marginB="120px" />
          </Section>

          <Section>
            <ImageList
              images={data.worksExmples}
              title={data.worksExmplesTitle}
              link={data.worksExmplesLink}
            />
          </Section>

          {data.partner ? (
            <Section>
              <Partners partner={data.partner} />
            </Section>
          ) : (
            <></>
          )}

          <Section>
            <Reviews title={data.title} reviews={data.reviews} />
          </Section>

          <Section overflow="hidden" marginB="0">
            <OrderForm />
          </Section>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </PageServiceWrap>
  );
}

export default PageService;
