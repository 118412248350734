import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { TMarketingStrategyHistory } from "../../types";
import LoadingSpinner from "../LoadingSpinner";
import Popup from "../Popup";
import StyledPageWrapper from "../StyledPageWrapper";
import MarketingStrategyGenerator from "../MarketingStrategyGenerator";

function PageMarketingStrategyGenerator() {
  const { requestId, type } = useParams();

  const [requestData, setRequestData] = useState<
    TMarketingStrategyHistory | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchContentPlan = async () => {
      if (requestId && type) {
        const url = `${process.env.REACT_APP_SERVER_URL}/requests-history/get-request`;
        try {
          const response = await axios.post(url, { requestId: requestId });
          setRequestData(response.data as TMarketingStrategyHistory);
        } catch (error) {
          console.error("Request failed:", error);
          setError("Failed to fetch content plan");
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchContentPlan();
  }, [requestId, type]);
  if (loading) {
    return (
      <StyledPageWrapper title="Marketing strategy generator">
        <LoadingSpinner
          width="300px"
          height="300px"
          $marginb="100px"
          $margint="100px"
        />
      </StyledPageWrapper>
    );
  }
  return (
    <>
      <StyledPageWrapper title="Marketing strategy generator">
        <MarketingStrategyGenerator data={requestData} type={type} />
      </StyledPageWrapper>
      <Popup popupType="technical-error" isOpen={error ? true : false} />
    </>
  );
}

export default PageMarketingStrategyGenerator;
