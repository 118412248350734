import { OurServiceTitle, ServiceTitle } from "./OurServices.styled";
import dataS from "../../data/Services";
import dataD from "../../data/Developments";
import dataP from "../../data/Planing";
import ServiceCardList from "../ServiceCardList";
import ServicePlaningCardList from "../ServicePlaningCardList";
import BannerContainer from "../Banners";

function OurServices() {
  return (
    <>
      <OurServiceTitle>Our services</OurServiceTitle>

      <ServiceTitle>Creative services</ServiceTitle>
      <ServiceCardList list={dataS} marginB="48px" listType="service" />

      <BannerContainer bannerType="sale" background={"white"} marginB="64px" />

      <ServiceTitle>Digital development</ServiceTitle>
      <ServiceCardList list={dataD} marginB="48px" listType="developments" />

      <ServiceTitle>Strategic planning and consulting</ServiceTitle>
      <ServicePlaningCardList list={dataP} marginB="0px" listType="service" />
    </>
  );
}

export default OurServices;
