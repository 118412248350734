import React from "react";
import { useField } from "formik";
import { TextareaFieldField, TextareaFieldWrap, Svg } from "./TextareaField.styled";
import sprite from "../../icons/symbol-defs.svg";

type TextareaFieldProps = {
  name: string;
  placeholder: string;
};

const TextareaField: React.FC<TextareaFieldProps> = ({
  name,
  placeholder,
}) => {
  const [field, meta] = useField(name);

  return (
    <TextareaFieldWrap>
      <TextareaFieldField {...field} placeholder={placeholder} error={meta.error}/>

      {meta.touched && meta.error && (
        <Svg width="24" height="24">
          <use xlinkHref={`${sprite}#info-circle`}></use>
        </Svg>
      )}
    </TextareaFieldWrap>
  );
};

export default TextareaField;
