import styled from "@emotion/styled";

type ListProps = {
};

export const DashboardFeaturesWrap = styled.ul<ListProps>`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  /* @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 32px;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 24px;
  } */
`;

export const DashboardFeaturesListElement = styled.li`
  padding: 0;
  margin: 0;
`;
