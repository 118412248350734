import React from "react";
import { CoursesPageData } from "../../types";
import { coursesMain } from "../../assets/image/courses";
import sprite from "../../icons/symbol-defs.svg";
import {
  Wrap,
  Info,
  Title,
  Description,
  Img,
  TitleWrap,
  Svg,
} from "./HeroCourses.styled";
import Button from "../Button";

type HeroCoursesProps = {
  data: CoursesPageData;
};

const HeroCourses: React.FC<HeroCoursesProps> = ({ data }) => {
  const clickPopup = () => {
    window.open("https://woohooooo.typeform.com/course-test", "_blank", "noopener noreferrer");
  };

  return (
    <Wrap>
      <Img src={coursesMain} alt="mainImg" />
      <Info>
        <TitleWrap>
          <Svg width="69" height="66">
            <use xlinkHref={`${sprite}#stars`}></use>
          </Svg>
          <Title>{data.title}</Title>
        </TitleWrap>
        <Description>{data.text}</Description>
        <Button onClick={clickPopup} text="Test it now" />
      </Info>
    </Wrap>
  );
};

export default HeroCourses;
