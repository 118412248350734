import styled from "@emotion/styled";
import { white } from "../../assets";

export const PageLoadingWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  background-color: ${white};
`;
