import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, FormikHelpers } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { setUser } from "../../store/slices/userSlice";
import { RootState } from "../../store/store";
import InputComponent from "../InputField";
import Popup from "../Popup";
import Button from "../Button";
import {
  AuthorizationText,
  InputsWrap,
  FormWrap,
  AuthorizationTitle,
  ErrorText,
  ResendButton,
} from "./Authorization.styled";

interface FormValues {
  code: string;
}

const validationSchema = Yup.lazy((values: FormValues) =>
  Yup.object().shape({
    code: Yup.string().required("Required"),
  })
);

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user);
  const [error, setError] = useState<string | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const initialValues: FormValues = {
    code: "",
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      await verify(values);
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const verify = async (formData: FormValues) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/user/verify`;
    const { email } = user;

    try {
      const response = await axios.post(
        url,
        {
          email: email,
          code: formData.code,
        },
        { withCredentials: true }
      );
      const userData = {
        email: response.data.user.email,
        firstName: response.data.user.first_name,
        lastName: response.data.user.last_name,
        id: response.data.user._id,
      };
      dispatch(setUser(userData));
      navigate("/payment-plans");
    } catch (error) {
      console.error("Verification failed:", error);
      setError("Verification code is wrong.");
    }
  };

  const resendCode = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/user/resend-code`;
    const { email } = user;

    try {
      await axios.post(url, { email }, { withCredentials: true });
    } catch (error) {
      console.error("Verification code resend failed:", error);
      setError("Verification code resend failed");
    }
    setIsPopupOpen(true);
  };

  const clickPopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <AuthorizationTitle>Check your email</AuthorizationTitle>
      <AuthorizationText>
        Confirm your email address by entering the eight-digit code sent to{" "}
        {user.email}
      </AuthorizationText>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormWrap>
              <InputsWrap>
                <InputComponent name="code" placeholder="Enter code" />
                {error && <ErrorText>{error}</ErrorText>}
                <Button
                  btnType="primary"
                  type="submit"
                  text="Confirm"
                  withIcon={false}
                />
              </InputsWrap>
            </FormWrap>
          </Form>
        )}
      </Formik>
      <AuthorizationText>
        Didn’t get the code?{" "}
        <ResendButton onClick={resendCode}>Resend</ResendButton>
      </AuthorizationText>
      <Popup
        popupType="confirm-email"
        isOpen={isPopupOpen}
        onClose={clickPopup}
      />
    </>
  );
};

export default ConfirmEmail;
