import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useLocation } from "react-router-dom";
import PageLoading from "../components/PageLoading";

export const RestrictedRoute = ({
  component: Component,
  //type: string,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userStatus, setUserStatus] = useState("");
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/user/get-user-info`,
          {},
          {
            withCredentials: true,
          }
        );

        if (response && response.data && response.data.status) {
          setUserStatus(response.data.status);
        } else {
          setUserStatus(null);
        }
      } catch (error) {
        console.error("Error fetching user information:", error);
        setUserStatus(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <PageLoading />;
  }

  if (userStatus === null) {
    return <Navigate to="/signup" state={{ from: location }} />;
  }

  if (userStatus === "none") {
    return <Navigate to="/payment-plans" state={{ from: location }} />;
  }

  return <Component {...rest} />;
};
