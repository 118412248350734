import {
  StyledPageWrapperWrap,
  PaymentPlansTitle,
} from "./StyledPageWrapper.styled";
import Section from "../Section";

type StyledPageWrapperProps = {
  title?: string;
  children: JSX.Element;
  backgroundColor?: string;
  type?: string;
};

const StyledPageWrapper: React.FC<StyledPageWrapperProps> = ({
  children,
  title,
  backgroundColor = "linear-gradient(to bottom, rgba(176, 184, 255, 1) 0%,rgba(255, 255, 255, 1) 100%)",
  type,
}) => {
  return (
    <StyledPageWrapperWrap backgroundColor={backgroundColor} type={type}>
      {type === "dashboard" ? (
        children
      ) : (
        <Section overflow="hidden" zIndex="1">
          {title && <PaymentPlansTitle>{title}</PaymentPlansTitle>}
          {children}
        </Section>
      )}
    </StyledPageWrapperWrap>
  );
};

export default StyledPageWrapper;
