import styled from "@emotion/styled";
import { d1, d3, h2, h3, h6, white, yellow } from "../../assets";

type TooltipWrapProps = {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
};
type TooltipIconProps = {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
};

export const TooltipWrap = styled.div<TooltipWrapProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 252px;
  height: 123.37px;
  padding: 24px 16px 24px 16px;
  background-color: ${white};
  border-radius: 20px;
  ${(props) => props.top && `top: ${props.top};`}
  ${(props) => props.bottom && `bottom: ${props.bottom};`}
  ${(props) => props.left && `left: ${props.left};`}
  ${(props) => props.right && `right: ${props.right};`}
`;
export const TooltipIcon = styled.svg<TooltipIconProps>`
  width: 38px;
  ${(props) => props.fill && `fill: ${props.fill};`}

`;
export const TooltipText = styled.p`
  ${d1}
`;
export const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 123px;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const Title = styled.h2`
  ${h2}
  text-align:center;
  @media (max-width: 1024px) {
    ${h3}
  }
  @media (max-width: 768px) {
    ${h6}
  }
`;

export const TitleWrap = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-right: 70px;
  @media (max-width: 1024px) {
    margin-bottom: 20px;
    margin-right: 70px;
  }
  @media (max-width: 768px) {
    margin-bottom: 32px;
    margin-right: 0px;
  }
`;

export const Svg = styled.svg`
  position: absolute;
  right: -75px;
  top: -35px;
  fill: ${yellow};
  transform: rotate(27deg);
  @media (max-width: 768px) {
    top: -50px;
  }
`;

export const Description = styled.p`
  ${d3}
  margin-bottom:35px;
  text-align: center;
  @media (max-width: 1024px) {
    margin: 24px 0;
  }
  @media (max-width: 1024px) {
    margin-bottom: 32px;
  }
`;

export const ImgWrap = styled.div`
  position: relative;
`;

export const Img = styled.img`
  height: auto;
  margin-top: 14px;
  @media (max-width: 1024px) {
    right: -150px;
    top: 170px;
    width: 415px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
