import { Form } from "formik";
import styled from "@emotion/styled";
import {
  accentText,
  aiGeneratorResultText,
  c3,
  c4,
  d2,
  dashboardText,
  labelText,
} from "../../assets/typography";
import { purple, white } from "../../assets";

export const SocialMediaPostWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;
export const AIGeneratorPromptInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AIGeneratorPromptFormWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 48px;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 746px;
`;
export const PromptWrap = styled.div<{ type?: string }>`
  padding: 40px 20px 40px 20px;
  background-color: ${white};
  border-radius: ${(props) =>
    props.type === "media-post" ? "20px 0 0 20px" : "20px"};
  min-height: 746px;
`;
export const PromptFooter = styled.div``;
export const AIGeneratorProptLabel = styled.p`
  ${labelText}
  text-align: left;
  color: #414141;
`;

export const AIGeneratorIncludeInPrompt = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PromptIncludeLabel = styled.p`
  ${dashboardText}
  font-weight: 500;
  text-align: left;
  color: #414141;
`;

export const AiGeneratorNotificationRow = styled.div`
  display: flex;
`;
export const AiGeneratorNotification = styled.p`
  margin-left: 16px;
  ${accentText}
  font-weight: 400;
  color: #414141;
  text-align: left;
  & > span {
    color: ${purple};
    font-weight: 700;
  }
`;
export const Svg = styled.svg`
  fill: ${white};
`;

export const ResultWrap = styled.div<{ empty: boolean }>`
  display: flex;
  flex-direction: column;

  justify-content: ${(props) => (props.empty ? "space-between" : "flex-start")};
  padding: 40px 20px 40px 20px;
  background-color: ${white};
  border-radius: 0 20px 20px 0;
  border-left: 1px solid #b0b8ff;
`;
export const ResultTitle = styled.p`
  ${c3}
  text-align: left;
  margin-bottom: 16px;
`;
export const ResultContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const EmptyResultWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
export const ResultImageWrap = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
`;
export const ImageButtons = styled.div`
  position: absolute;
  display: flex;
  gap: 16px;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
`;
export const ResultImage = styled.img`
  height: 350px;
  margin: auto;
`;
export const ResultText = styled.p`
  ${aiGeneratorResultText}
  text-align: left;
  white-space: pre-line;
`;
export const EmptyResulContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;
`;
export const EmptyResultIcon = styled.svg`
  margin: auto;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    width: 100px;
  }
`;
export const EmptyResultTitle = styled.p`
  ${d2}
  font-weight: 600;
  margin-bottom: 8px;
`;
export const EmptyResultText = styled.p`
  ${accentText}
  font-weight: 400;
`;
export const EmptyResulHelp = styled.div``;

export const EmptyResulHelpTitle = styled.p`
  ${c4}
  font-weight: 400;
  text-align: left;
`;
export const EmptyResulHelpText = styled.p`
  ${accentText}
  font-weight: 400;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;
`;
export const ImageTooltipWrap = styled.div`
  display: flex;
  position: absolute;
  top: 16px;
  left: 10px;
`;
export const ImageTooltipMark = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 8px;
  background-color: #fff;
`;
export const ImageTooltipBox = styled.p<{ visible: boolean }>`
  display: flex;
  padding: 12px;
  margin-left: 6px;
  max-width: 290px;
  width: 100%;
  text-align: left;
  border-radius: 10px;
  background-color: #fff;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 12px;
    left: -13px;
    transform: translateX(-50%);
    transform: rotate(90deg);
    border-width: 7px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
`;
