import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { clearUser } from "../../store/slices/userSlice";
import { TPaymentPlanCardList } from "../../types/";
import { paymentPlans } from "../../data";
import StyledPageWrapper from "../StyledPageWrapper";
import Button from "../Button";
import PaymentPlansCardList from "../PaymentPlansCardList";
import {
  PaymentPlansAdditionalHeader,
  PaymentPlansAdditionalInfo,
  PaymentPlansText,
  PaymentPlansTitle,
} from "./PagePaymentPlans.styled";

function PagePaymentPlans() {
  const [plans, setPlans] = useState<TPaymentPlanCardList | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = () => {
      if (paymentPlans.length > 0) {
        setPlans(paymentPlans);
      }
    };

    fetchData();
  }, []);

  const handleLogout = async () => {
    const url =`${process.env.REACT_APP_SERVER_URL}/user/logout`;

    try {
      await axios.get(url, {
        withCredentials: true,
      });

      dispatch(clearUser());
      navigate("/dashboard");
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <StyledPageWrapper
      backgroundColor="linear-gradient(
    to bottom,
    rgba(176, 184, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  )"
    >
      <>
        <PaymentPlansAdditionalHeader>
          <PaymentPlansAdditionalInfo>
            <PaymentPlansTitle>Choose a plan</PaymentPlansTitle>
            <PaymentPlansText>
              You can upgrade your plan anytime to enjoy more advanced features!
            </PaymentPlansText>
          </PaymentPlansAdditionalInfo>
          <Button
            text="Sign out"
            withIcon={false}
            width="143px"
            onClick={handleLogout}
          />
        </PaymentPlansAdditionalHeader>
        {plans && <PaymentPlansCardList list={plans} />}
      </>
    </StyledPageWrapper>
  );
}

export default PagePaymentPlans;
