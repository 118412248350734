import { PageSignUpWrap } from "./PageSignUp.styled";
import Authorization from "../Authorization";
import Section from "../Section";

function PageSignUp() {
  return (
    <PageSignUpWrap>
      <Section>
        <Authorization type="signup" />
      </Section>
    </PageSignUpWrap>
  );
}

export default PageSignUp;
