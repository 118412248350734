import React from "react";
import {
  StyledGoogleeButton,
  GoogleButtonWrap,
  GoogleImg,
} from "./Authorization.styled";
import googleIcon from "../../assets/image/google.png";

function generateNonce() {
  return Math.random().toString(36).substring(2, 15);
}

type ButtonProps = {
  type: "signup" | "signin";
};

const Button: React.FC<ButtonProps> = ({ type }) => {
  const handleGoogleSignIn = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIEN_ID;

    const redirectUri = `${
      type === "signin"
        ? `${process.env.REACT_APP_URL}/login`
        : `${process.env.REACT_APP_URL}/signup`
    }`;

    const scope = "profile email";
    const responseType = "id_token";
    const nonce = generateNonce();

    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_type=${responseType}&scope=${encodeURIComponent(
      scope
    )}&nonce=${encodeURIComponent(nonce)}`;

    window.location.href = url;
  };

  return (
    <GoogleButtonWrap>
      <StyledGoogleeButton id="googleBtn" onClick={handleGoogleSignIn}>
        <GoogleImg src={googleIcon} />
        Continue with Google
      </StyledGoogleeButton>
    </GoogleButtonWrap>
  );
};

export default Button;
