import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { menuText, pink, black, yellow, white, menuTextM } from "../../assets/";

interface INavLinkCssProps {
  color: "pink" | "black" | "yellow" | "white";
}

export const NavLinkCss = styled(NavLink)<INavLinkCssProps>`
  ${menuText}
  color: ${(props) => {
    switch (props.color) {
      case "pink":
        return pink;
      case "black":
        return black;
      case "yellow":
        return yellow;
      case "white":
        return white;
      default:
        return "inherit";
    }
  }};

  text-align: center;
  @media (max-width: 1024px) {
    ${menuTextM}
  }
`;
