import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes, useLocation } from "react-router-dom";
import { PrivateRoute } from "../../routes/PrivateRoute";
import { RestrictedRoute } from "../../routes/RestrictedRoute";
import Layout from "../Layout";
import HomePage from "../../pages/HomePage";
import ServicesPage from "../../pages/ServicePage";
import CoursesPage from "../../pages/CoursesPage";
import CheckoutPage from "../../pages/CheckoutPage";
import AIGeneratorPage from "../../pages/AIGeneratorPage";
import SignUpPage from "../../pages/SignUpPage";
import LoginPage from "../../pages/LoginPage";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import ConfirmEmailPage from "../../pages/ConfirmEmailPage";
import ForgotPasswordPage from "../../pages/ForgotPasswordPage";
import PaymentPlansPage from "../../pages/PaymentPlansPage";
import SocialPostGeneratorPage from "../../pages/SocialPostGeneratorPage";
import DashboardPage from "../../pages/DashboardPage";
import ContentPlanGeneratorPage from "../../pages/ContentPlanGeneratorPage";
import MarketingStrategyGeneratorPage from "../../pages/MarketingStrategyGeneratorPage";

declare global {
  interface Window {
    fbq?: (...args: any[]) => void;
  }
}

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (window.fbq) return;

    (function (
      f: any,
      b: any,
      e: string,
      v: string,
      n?: any,
      t?: any,
      s?: any
    ) {
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s?.parentNode?.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );

    if (window.fbq) {
      (window.fbq as (...args: any[]) => void)("init", "2710873199071130");
      (window.fbq as (...args: any[]) => void)("track", "PageView");
    }
  }, []);

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
            (function(w,d,s,l,i){
              w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WFT95PHL');
          `}
        </script>
      </Helmet>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-WFT95PHL"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="services/:serviceId" element={<ServicesPage />} />
          <Route path="courses" element={<CoursesPage />} />
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="signup" element={<SignUpPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="confirm-email" element={<ConfirmEmailPage />} />
          <Route
            path="reset-password/:session"
            element={<ResetPasswordPage />}
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route
            path="payment-plans"
            element={
              <PrivateRoute
                redirectTo="/dashboard"
                component={PaymentPlansPage}
              />
            }
          />
        </Route>
        <Route path="/" element={<Layout type="dashboard" />}>
          <Route path="ai-generator" element={<AIGeneratorPage />} />
          <Route
            path="dashboard"
            element={
              <RestrictedRoute redirectTo="/signup" component={DashboardPage} />
            }
          />
          <Route
            path="social-post-generator"
            element={
              <RestrictedRoute
                redirectTo="/dashboard"
                component={SocialPostGeneratorPage}
              />
            }
          />
          <Route
            path="content-plan-generator"
            element={
              <RestrictedRoute
                redirectTo="/dashboard"
                component={ContentPlanGeneratorPage}
              />
            }
          />
          <Route
            path="marketing-strategy-generator"
            element={
              <RestrictedRoute
                redirectTo="/dashboard"
                component={MarketingStrategyGeneratorPage}
              />
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
