import styled from "@emotion/styled";
import ReactQuill from "react-quill";
import {
  AIGenratorResultPostTitle,
  AIGenratorResultText,
  AIGenratorResultTitle,
} from "../../assets/typography";
import { lavender, white, yellow } from "../../assets";
import starGenrator from "../../assets/image/star_shape_generator.png";

export const PresentationEditorSlideTypeWrap = styled.div<{
  type?: string;
  active: boolean;
  preview: boolean;
}>`
  display: ${(props) =>
    props.preview ? "flex" : props.active ? "flex" : "none"};
  padding: 64px 33px 64px 64px;
  background-color: ${(props) => (props.type === "intro" ? yellow : white)};
  border-radius: 16px;
  min-height: 610px;
  max-width: 1080px;
  width: 100%;
`;
export const PresentationEditorSlideWrap = styled.div`
  display: flex;
  gap: 16px;
`;
export const PresentationEditorSlideInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 550px;
  width: 100%;
  text-align: left;
`;
export const PresentationEditorSlideInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
`;
export const PresentationEditorSlideImageWrap = styled.div`
  position: relative;
  height: fit-content;
`;
export const PresentationEditorSlideImage = styled.img`
  max-width: 420px;
  width: 100%;
  height: auto;
`;
export const PresentationEditorSlideImageButtons = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
`;
export const CommonWrap = styled.div<{ type: string; preview: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow: hidden;
  ${(props) =>
    props.type === "post"
      ? ""
      : `
    &:after {
    content: "";
    position: absolute;
    background-image: url(${starGenrator});
    width: 770px;
    height: 657px;
    z-index: 0;
      ${
        props.preview
          ? `bottom: -55px;
    right: -100px;`
          : `bottom: 140px;
    right: 0px;`
      }
    // @media (max-width: 1024px) {
    //   top: 600px;
    //   transform: rotate(-10deg);
    // }
    // @media (max-width: 678px) {
    //   top: 1000px;
    // }
  }
  `}
`;
export const PresentationEditorSlideInfoTitle = styled.p`
  ${AIGenratorResultTitle}
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 700;
`;
export const IntroLogoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
export const IntroLogo = styled.svg``;
export const TitleSvg = styled.svg`
  fill: ${lavender};
  transform: rotate(20deg);
`;
export const PresentationEditorSlideText = styled.p`
  ${AIGenratorResultText};
  & > span {
    font-weight: 700;
  }
`;
export const PresentationEditorSlideTitle = styled.h4`
  ${AIGenratorResultPostTitle};
`;
export const CustomReactQuill = styled(ReactQuill)`
  ${AIGenratorResultText};

  .ql-editor {
    ${AIGenratorResultText};
    padding: 0;
    //padding: 16px 8px;
  }
  .ql-container.ql-snow {
    border: none;
    //background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23B080EDFF' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  /* .ql-container.ql-snow .ql-editor.active-block {
    border: 2px solid #b080ed; 
    padding: 16px 8px;
  } */
`;
export const PresentationEditorSlideImageTooltipWrap = styled.div`
  display: flex;
  position: absolute;
  top: 16px;
  left: 16px;
`;
export const PresentationEditorSlideImageTooltipMark = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background-color: #fff;
`;
export const PresentationEditorSlideImageTooltipBox = styled.p<{
  visible: boolean;
}>`
  display: flex;
  padding: 12px;
  margin-left: 6px;
  max-width: 290px;
  width: 100%;
  text-align: left;
  border-radius: 10px;
  background-color: #fff;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 12px;
    left: -13px;
    transform: translateX(-50%);
    transform: rotate(90deg);
    border-width: 7px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
`;
