import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from "react";
import { TCourseCard } from "../../types/index";
import {
  CardWrap,
  CardAdditionlWrapper,
  CardOverlay,
  CardBody,
  TitleWrap,
  Title,
  Lessons,
  DescriptionText,
  Img,
  Structure,
  StructureWrapper,
  SubstepWrapper,
  StructureTitle,
  StepWrapper,
  StepTitle,
  Substep,
  PriceBlock,
  OldOPrice,
  CurrentPrice,
  ImgWrap,
} from "./CourseCard.styled";
import Button from "../Button";

type CourseCardProps = {
  data: TCourseCard;
  overlay: boolean;
  lastChild: boolean;
  onHover: (id: number) => void;
  onMouseLeave: () => void;
};

const CourseCard: React.FC<CourseCardProps> = ({
  data,
  overlay,
  onHover,
  onMouseLeave,
  lastChild,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [structureHeight, setStructureHeight] = useState<number | undefined>(undefined);
  const [cardHeight, setCardHeight] = useState<number>(0);
  const structureRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const updateHeights = useCallback(() => {
    if (cardRef.current) {
      setCardHeight(cardRef.current.scrollHeight);
    }
    if (structureRef.current) {
      setStructureHeight(structureRef.current.scrollHeight);
    }
  }, []);

  useEffect(() => {
    const currentImageRef = imageRef.current;

    const handleLoad = () => {
      if (currentImageRef) {
        setCardHeight(currentImageRef.scrollHeight);
        updateHeights();
      }
    };

    if (currentImageRef) {
      currentImageRef.addEventListener("load", handleLoad);

      return () => {
        currentImageRef.removeEventListener("load", handleLoad);
      };
    }
  }, [updateHeights]);

  useLayoutEffect(() => {
    if (cardRef.current && imageRef.current) {
      setCardHeight(cardRef.current.scrollHeight);
      updateHeights();
    }
  }, [updateHeights]);

  useEffect(() => {
    updateHeights();
  }, [data.id, data.mainImg, updateHeights]);

  useEffect(() => {
    const handleResize = () => updateHeights();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [updateHeights]);

  const handleClick = (link: string) => {
    window.open(link, "_blank", "noopener noreferrer");
  };


  const handleMouseEnter = () => {
    setIsHovered(true);
    onHover(data.id);
    if (cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const calculateDiscountedPrice = (originalPrice: number): number => {
    const discountRate = 0.1;
    return originalPrice * (1 - discountRate);
  };

  return (
    <CardWrap
      ref={cardRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        setIsHovered(false);
        onMouseLeave();
      }}
      isHovered={isHovered}
      lastChild={lastChild}
      height={cardHeight}
      style={{ transformOrigin: "top center" }}
    >
      <CardAdditionlWrapper isHovered={isHovered} lastChild={lastChild}>
        <ImgWrap>
          <Img src={data.mainImg} alt="courses" ref={imageRef} />
        </ImgWrap>
        <CardBody>
          <TitleWrap>
            <Title>{data.title}</Title>
            <Lessons>{data.lessonsNumber}</Lessons>
          </TitleWrap>
          {data.description && (
            <DescriptionText>{data.description}</DescriptionText>
          )}
          <Structure
            isHovered={isHovered}
            ref={structureRef}
            maxHeight={structureHeight}
          >
            <StructureTitle>Course Structure</StructureTitle>
            <StructureWrapper>
              {data.structure.map((step, index) => (
                <StepWrapper key={index}>
                  <StepTitle>{step.step}</StepTitle>
                  <SubstepWrapper>
                    {step.subStep.map((substep, subIndex) => (
                      <Substep key={subIndex}>{substep}</Substep>
                    ))}
                  </SubstepWrapper>
                </StepWrapper>
              ))}
            </StructureWrapper>
            <PriceBlock>
              <CurrentPrice>
                ${calculateDiscountedPrice(data.price)}
              </CurrentPrice>
              <OldOPrice>${data.price}</OldOPrice>
            </PriceBlock>
          </Structure>
          <Button onClick={() => handleClick(data.couseLink)} text="Proceed to Checkout" />
        </CardBody>
      </CardAdditionlWrapper>
      {overlay && <CardOverlay overlay={true} />}
    </CardWrap>
  );
};

export default CourseCard;
