import React from "react";
import { TUserSubscriptionInfo } from "../../types";
import { DashboardContentWrapper } from "./DashboardContent.styled";
import Dashboard from "../Dashboard/Dashboard";
import ContactUs from "../ContactUs";
import PlanDetails from "../PlanDetails";

type DashboardContentProps = {
  activeItem: string;
  userInfo: TUserSubscriptionInfo | null;
};

const DashboardContent: React.FC<DashboardContentProps> = ({ activeItem, userInfo}) => {
  if (activeItem === "Dashboard") {
    return (
      <DashboardContentWrapper>
        <Dashboard userInfo={userInfo} />
      </DashboardContentWrapper>
    );
  }

  if (activeItem === "Contact Us") {
    return (
      <DashboardContentWrapper>
        <ContactUs />
      </DashboardContentWrapper>
    );
  }
  if (activeItem === "Plan Details") {
    return (
      <DashboardContentWrapper>
        <PlanDetails  userPlanInfo={userInfo}/>
      </DashboardContentWrapper>
    );
  }

  return null;
};

export default DashboardContent;
