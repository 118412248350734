import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { black, violet, white } from "../../assets";

interface IMenuProps {
  isMenuOpen: boolean;
}
interface HeaderProps {
  type?: string;
}

export const HeaderCss = styled.header<HeaderProps>`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  /* ${(props) =>
    props.type &&
    `background-color: #fff; border-bottom: 1px solid rgba(176, 184, 255, 1);`} */
`;

export const HeaderContainer = styled.div`
  padding: 30px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding: 32px;
  }
  @media (max-width: 768px) {
    padding: 24px;
  }
`;
export const Cart = styled.div`
  position: relative;
  @media (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
  }
`;
export const HeaderNavList = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
export const CustomLink = styled(Link)`
  @media (max-width: 768px) {
    margin-right: 48px;
  }
`;
export const Logo = styled.svg`
  fill: ${black};
  width: 208px;
  @media (max-width: 1024px) {
    width: 143px;
  }
  @media (max-width: 768px) {
    width: 118px;
  }
`;
export const ProductsInCart = styled.p`
  position: absolute;
  top: -6px;
  right: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 18px;
  font-weight: 600;
  color: ${white};
  background-color: ${violet};
  border-radius: 50%;
`;

export const MenuButton = styled.div<IMenuProps>`
  display: none;
  position: absolute;
  right: 2rem;
  width: 18px;
  height: 14px;
  cursor: pointer;
  z-index: 8;
  background: none;
  span,
  &::before,
  &::after {
    content: "";
    transition: all 0.3s ease 0s;
    right: 0;
    position: absolute;
    height: 2px;
    background-color: #000;
    border-radius: 5px;
  }
  &::before {
    top: 0;
    width: 18px;
  }
  &::after {
    bottom: 0;
    width: 18px;
  }
  span {
    width: 18px;
    height: 2px;
    top: calc(50% - 1px);
  }
  ${(props) =>
    props.isMenuOpen &&
    `
      span {
        width: 0;
      }
      &::before,
      &::after {
        width: 100%;
      }
      &::before {
        top: calc(50% - 1px);
        transform: rotate(-45deg);
      }
      &::after {
        bottom: calc(50% - 1px);
        transform: rotate(45deg);
      }
  `}
  @media (max-width: 768px) {
    display: block;
  }
`;
export const Panel = styled.div`
  display: flex;
  align-items: center;
  width: 112px;
  justify-content: flex-end;
`;
export const UserWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border-radius: 50%;
  background: #ffffff26;
`;
export const User = styled.svg`
  cursor: pointer;
  fill: ${white};
  stroke: ${white};
`;
export const MobileMenu = styled.div<IMenuProps>`
  @media (max-width: 768px) {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: -100%;
    transition: all 0.3s ease 0s;
    overflow: hidden;
    z-index: 3;

    &::before {
      content: "";
      position: fixed;
      left: -100%;
      top: 0;
      width: 100%;
      z-index: 2;
      height: 100%;
      backdrop-filter: blur(15px);
      transition: all 0.3s ease 0s;
      overflow: hidden;
    }
    left: ${(props) => (props.isMenuOpen ? "0" : "-100%")};
    &::before {
      left: ${(props) => (props.isMenuOpen ? "0" : "-100%")};
    }
  }
`;
