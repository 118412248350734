import React, { useEffect, useState } from "react";
import {
  TMarketingStrategy,
  TMarketingStrategyHistory,
  TMarketingStrategyHistoryPrompt,
} from "../../types/index";
import {
  MarketingStrategyWrap,
  MarketingStrategyPromptWrap,
} from "./MarketingStrategyGenerator.styled";
import MarketingStrategyPrompt from "./MarketingStrategyPrompt";
import MarketingStrategyResult from "./MarketingStrategyResult";
import Popup from "../Popup";

interface MarketingStrategyGeneratorProps {
  data: TMarketingStrategyHistory | undefined;
  type: string | undefined;
}
type SlideData = {
  title: string;
  value: string;
  id: number;
};

const MarketingStrategyGenerator: React.FC<MarketingStrategyGeneratorProps> = ({
  data,
  type,
}) => {
  const [marketingStrategyData, setMarketingStrategyData] =
    useState<TMarketingStrategy | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorPopup, setErrorPopupOpen] = useState<boolean>(false);
  const [isAvailableGenerator, setIsAvailableGenerator] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [promptHistory, setPromptHistory] =
    useState<TMarketingStrategyHistoryPrompt | null>(null);

  useEffect(() => {
    if (data && type) {
      if (type === "result" && data.marketingStrategy?.result) {
        const { result } = data.marketingStrategy;
        setMarketingStrategyData({
          _id: data._id,
          target_market: result.target_market || "",
          target_audience: result.target_audience || "",
          key_competitors: result.key_competitors || "",
          smart: result.smart || "",
          unique_selling_proposition: result.unique_selling_proposition || "",
          marketing_mix_4p: result.marketing_mix_4p || "",
          digital_strategy: result.digital_strategy || "",
          implementation_plan: result.implementation_plan || "",
          budget: result.budget || "",
          measurements: result.measurements || "",
          crisis_plan: result.crisis_plan || "",
          errors: null,
        });
      } else if (type === "prompt") {
        setPromptHistory({
          productDescription: data.marketingStrategy.prompt.productDescription,
        });
      }
    }
  }, [data, type]);

  const handleMarketingStrategyData = (data: TMarketingStrategy) => {
    setMarketingStrategyData(data);
    setLoading(false);
    if (data.errors) {
      setErrorMessage("request-error");
      setErrorPopupOpen(true);
    }
  };
  const setTechnicalError = (error: boolean) => {
    if (error) {
      setErrorMessage("technical-error");
      setErrorPopupOpen(true);
    }
  };

  const formatMarketingStrategyHtml = (value: string | undefined): string => {
    if (!value) return "";
    return value
      .split("\n")
      .map((line) => `<p>${line}</p>`)
      .join("");
  };

  const handleUpdatePlan = () => {
    window.location.href = `${process.env.REACT_APP_BILLING_PORTAL}`;
    setIsAvailableGenerator(false);
  };

  const marketingStrategySlides: SlideData[] = marketingStrategyData
    ? [
        {
          title: "Market Analysis",
          value: formatMarketingStrategyHtml(
            marketingStrategyData.target_market
          ),
          id: 2,
        },
        {
          title: "Target Audience",
          value: formatMarketingStrategyHtml(
            marketingStrategyData.target_audience
          ),
          id: 3,
        },
        {
          title: "Competitor Analysis",
          value: formatMarketingStrategyHtml(
            marketingStrategyData.key_competitors
          ),
          id: 4,
        },
        {
          title: "SMART Goals",
          value: formatMarketingStrategyHtml(marketingStrategyData.smart),
          id: 5,
        },
        {
          title: "Unique Selling Proposition",
          value: formatMarketingStrategyHtml(
            marketingStrategyData.unique_selling_proposition
          ),
          id: 6,
        },
        {
          title: "Marketing Mix (4P)",
          value: formatMarketingStrategyHtml(
            marketingStrategyData.marketing_mix_4p
          ),
          id: 7,
        },
        {
          title: "Digital Strategy",
          value: formatMarketingStrategyHtml(
            marketingStrategyData.digital_strategy
          ),
          id: 8,
        },
        {
          title: "Implementation Plan",
          value: formatMarketingStrategyHtml(
            marketingStrategyData.implementation_plan
          ),
          id: 9,
        },
        {
          title: "Budget",
          value: formatMarketingStrategyHtml(marketingStrategyData.budget),
          id: 10,
        },
        {
          title: "Measurement and Analysis Methods",
          value: formatMarketingStrategyHtml(
            marketingStrategyData.measurements
          ),
          id: 11,
        },
        {
          title: "Crisis Management Plan",
          value: formatMarketingStrategyHtml(marketingStrategyData.crisis_plan),
          id: 12,
        },
      ]
    : [];

  return (
    <MarketingStrategyWrap>
      {marketingStrategyData && !errorMessage ? (
        <MarketingStrategyResult
          data={marketingStrategyData}
          marketingStrategySlides={marketingStrategySlides}
        />
      ) : (
        <MarketingStrategyPromptWrap>
          <MarketingStrategyPrompt
            onData={handleMarketingStrategyData}
            setLoading={setLoading}
            setTechnicalError={setTechnicalError}
            setGeneratorLimit={setIsAvailableGenerator}
            prompt={promptHistory}
          />
        </MarketingStrategyPromptWrap>
      )}
      <Popup
        popupType="loading"
        isOpen={loading}
        contentType="marketing strategy"
      />
      <Popup
        popupType="credits-error"
        isOpen={isAvailableGenerator}
        onClose={handleUpdatePlan}
      />
      {errorMessage && (
        <>
          <Popup
            popupType={errorMessage}
            isOpen={errorPopup}
            onClose={() => setErrorPopupOpen(false)}
          />
          <Popup
            popupType={errorMessage}
            isOpen={errorPopup}
            onClose={() => setErrorPopupOpen(false)}
          />
        </>
      )}
    </MarketingStrategyWrap>
  );
};

export default MarketingStrategyGenerator;
