import styled from "@emotion/styled";
import { titleM, c1, c2, h2, h5, h6, yellow } from "../../assets/";
import starY from "../../assets/image/star_shape.png";

export const Wrapp = styled.div`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    background-image: url(${starY});
    background-repeat: no-repeat;
    background-position: top 0px right 0px;
    width: 1440px;
    height: 950px;
    top: -150px;
    right: -70px;
    z-index: -1;
  }
  @media (max-width: 1024px) {
    &:after {
      top: -220px;
      right: -30px;
    }
  }
  @media (max-width: 768px) {
    &:after {
      display: none;
    }
  }
`;
export const MainTitle = styled.h2`
  ${h2}
  margin-bottom: 48px;
  padding-right: 60px;
  position: relative;
  @media (max-width: 1024px) {
    ${titleM}
    margin-bottom: 32px;
  }
  @media (max-width: 768px) {
    ${c1}
    font-weight: 700;
    margin-bottom: 16px;
  }
`;

export const Svg = styled.svg`
  position: absolute;
  right: 40px;
  top: -10px;
  fill: ${yellow};
  @media (max-width: 1024px) {
    right: -10px;
  }
`;

export const Strong = styled.strong`
  color: ${yellow};
  text-transform: uppercase;
  @media (max-width: 1024px) {
    ${titleM}
  }
  @media (max-width: 768px) {
    ${c1}
    font-weight: 700;
  }
`;

export const Text = styled.span`
  ${h5}
  line-height: 200%;
  font-style: italic;
  @media (max-width: 1024px) {
    ${h6}
  }
  @media (max-width: 768px) {
    ${c2}
    font-weight: 700;
  }
`;

export const TextNormal = styled.span`
  font-weight: normal;
  font-style: normal;

  @media (max-width: 1024px) {
    ${c1}
  }
  @media (max-width: 768px) {
    ${c2}
  }
`;
