import styled from "@emotion/styled";
import { lavender } from "../../assets";

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1300px;
  height: auto;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 15px;
`;

export const VideoPreview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  cursor: pointer;
  border-radius: 15px;
`;

export const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
`;

export const PlayButton = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 103px;
  height: 103px;
  transform: translate(-50%, -50%);
  background-color: ${lavender};
  border-radius: 50%;
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 120px;
    height: 120px;
    background-color: #b8bfff26;
    border-radius: 50%;
  }
`;
export const PlaySvg = styled.svg`
  width: 27px;
  height: 24px;
`;

export const VideoIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
`;
