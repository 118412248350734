import styled from "@emotion/styled";
import { h3, h6, titleM } from "../../assets/";

export const CustomerReviewsTitle = styled.h3`
  margin-bottom: 64px;
  ${h3};
  @media (max-width: 1024px) {
    ${titleM}
    margin-bottom: 32px;
  }
  @media (max-width: 768px) {
    ${h6}
  }
`;
