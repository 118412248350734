import styled from "@emotion/styled";
import { white, c1, c2 } from "../../assets";

export const CardWrap = styled.div`
  background-color: ${white};
  border-radius: 12px;
  height: 100%;
`;
export const CardAdditionlWrapper = styled.div`
  background-color: ${white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
`;

export const CardBody = styled.div`
  padding: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Img = styled.img`
  width: 100%;
  height: 300px;
  border-radius: 12px 12px 0 0;
  object-fit: cover;
  object-position: center;
  @media (max-width: 768px) {
    height: 349px;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Title = styled.p`
  ${c1}
  max-width: 446px;
  width: 100%;
  min-height: 68px;
  @media (max-width: 768px) {
    ${c2}
  }
`;
