import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import emptyCart from "../../assets/image/empty_cart.png";
import { ServicePageData } from "../../types/";
import Button from "../Button";
import {
  OrderPreviewTitle,
  OrderWrapper,
  OrderAdditionalWrapper,
  OrderSubtitle,
  ContactInfo,
  InputsList,
  OrderSummary,
  OrderSummaryList,
  Divider,
  Subtotal,
  SubtotalText,
  SubtotalPrice,
  Total,
  TotalText,
  EmptyCartImg,
  EmptyCartTitle,
  EmptyCartText,
  Discount,
  DiscountText,
  DiscountValue,
  TotalPriceBlock,
  OldPrice,
  TotalPrice,
} from "./OrderPreview.styled";
import InputComponent from "../InputField";
import OrderCard from "../OrderCard";
import BannerContainer from "../Banners/BannerContainer";

type OrderPreviewProps = {
  data: Array<ServicePageData>;
};

interface FormValues {
  name: string;
  email: string;
}

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const OrderPreview: React.FC<OrderPreviewProps> = ({ data }) => {
  const [subtotalPrice, setSubtotalPrice] = useState<number>(0);
  const [priceWithDiscount, setPriceWithDiscoun] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const calculateSubtotalPrice = () => {
      let subtotal = 0;
      data.forEach((product) => {
        subtotal += product.price;
      });
      return Number(subtotal.toFixed(2));
    };

    if (data.length > 0) {
      const calculatedSubtotalPrice = calculateSubtotalPrice();
      const priceWithDiscount = calculateDiscountedPrice(
        calculatedSubtotalPrice
      );
      setSubtotalPrice(calculatedSubtotalPrice);
      setPriceWithDiscoun(priceWithDiscount);
    }
  }, [data]);

  const calculateDiscountedPrice = (originalPrice: number): number => {
    const discountRate = 0.1;
    const discountedPrice = originalPrice * (1 - discountRate);
    return discountedPrice;
  };

  const handleSubmit = async (values: FormValues) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/payments/create-checkout-session`;
    try {
      setLoading(true);
      const response = await axios.post(url, {
        name: values.name,
        email: values.email,
        type: "purchase",
        items: data.map((item) => ({
          id: item.id,
          mainImg: item.mainImg,
          price: calculateDiscountedPrice(item.price),
          title: item.title,
        })),
      });

      window.location.href = response.data.redirectUrl;
    } catch (error) {
      setLoading(false);
      console.error("Error creating checkout session:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    navigate("/");
  };

  return (
    <OrderWrapper>
      {data.length > 0 ? (
        <OrderPreviewTitle>Please review your order</OrderPreviewTitle>
      ) : (
        ""
      )}

      <OrderAdditionalWrapper empty={data.length > 0 ? false : true}>
        {data.length > 0 ? (
          <>
            <BannerContainer
              bannerType="info-timer"
              background="blue"
              marginB="32px;"
            />
            <ContactInfo>
              <OrderSubtitle>Contact information</OrderSubtitle>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit }) => (
                  <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
                    <InputsList>
                      <InputComponent name="name" placeholder="Name" />
                      <InputComponent name="email" placeholder="Email" />
                    </InputsList>
                    <OrderSummary>
                      <OrderSubtitle>Order summary</OrderSubtitle>
                      {/* <BannerContainer
                        bannerType="checkout-informer"
                        background="blue"
                        marginB="32px;"
                      /> */}
                      <OrderSummaryList>
                        {data.map((product) => (
                          <OrderCard
                            key={product.id}
                            id={product.id}
                            img={product.mainImg}
                            price={product.price}
                            description={
                              product.description
                                ? product.description
                                : undefined
                            }
                            title={product.title}
                          />
                        ))}
                      </OrderSummaryList>
                      <Divider />
                      <Subtotal>
                        <SubtotalText>Subtotal</SubtotalText>
                        <SubtotalPrice>
                          ${subtotalPrice.toFixed()}
                        </SubtotalPrice>
                      </Subtotal>
                      <Discount>
                        <DiscountText>Discount</DiscountText>
                        <DiscountValue>-10%</DiscountValue>
                      </Discount>
                      <Divider />
                      <Total>
                        <TotalText>Total</TotalText>
                        <TotalPriceBlock>
                          <OldPrice>${subtotalPrice}</OldPrice>
                          <TotalPrice>
                            ${priceWithDiscount.toFixed()}
                          </TotalPrice>
                        </TotalPriceBlock>
                      </Total>

                      <Button
                        type="submit"
                        btnType="primary"
                        text={loading ? "Loading..." : "Make payment"}
                        withIcon={false}
                      />
                    </OrderSummary>
                  </Form>
                )}
              </Formik>
            </ContactInfo>
          </>
        ) : (
          <>
            <EmptyCartImg src={emptyCart} alt="empty cart" />
            <EmptyCartTitle>Your Cart is Empty</EmptyCartTitle>
            <EmptyCartText>
              It looks like you haven't added any services or courses to your
              cart yet. Explore our offerings and add the ones you like to get
              started!
            </EmptyCartText>
            <Button
              btnType="primary"
              text="Browse Services and Courses"
              withIcon={false}
              onClick={handleButtonClick}
            />
          </>
        )}
      </OrderAdditionalWrapper>
    </OrderWrapper>
  );
};

export default OrderPreview;
