import { dev1, dev2, dev3, dev4 } from "../assets/image/developments/index";

const developments = [
  {
    text: "Landing page design and development",
    id: "landingpage_development",
    img: dev4,
  },
  {
    text: "Multi-page website design and development",
    id: "website_development",
    img: dev1,
  },
  {
    text: "Online store design and development",
    id: "onlinestore_development",
    img: dev2,
  },
  {
    text: "Mobile application design and development",
    id: "mobileapp_development",
    img: dev3,
  },
];

export default developments;
