import { FC, useState, useRef, useEffect } from "react";
import { TReview } from "../../types";
import sprite from "../../icons/symbol-defs.svg";
import {
  ReviewCardWrap,
  CustomerIcon,
  CustomerName,
  RatingWrap,
  ReviewDate,
  ReviewText,
  User,
  ReviewInfoRow,
  ReviewCustomerInfo,
  ReviewTextBlock,
  ViewMoreButton,
  ViewMoreButtonWrap,
} from "./ReviewCard.styled";
import RatingStars from "../RatingStars";

interface ReviewCardProps {
  review: TReview;
}

const MAX_HEIGHT_MOBILE = 180;
const MAX_HEIGHT_DESKTOP = 160;

const ReviewCard: FC<ReviewCardProps> = ({ review }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowed, setIsOverflowed] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const viewportWidth = window.innerWidth;
      const maxHeight =
        viewportWidth < 768 ? MAX_HEIGHT_MOBILE : MAX_HEIGHT_DESKTOP;
      const scrollHeight = textRef.current.scrollHeight;
      setIsOverflowed(scrollHeight + 10 > maxHeight);
    }

    const handleResize = () => {
      if (textRef.current) {
        const viewportWidth = window.innerWidth;
        const maxHeight =
          viewportWidth < 768 ? MAX_HEIGHT_MOBILE : MAX_HEIGHT_DESKTOP;
        const scrollHeight = textRef.current.scrollHeight;
        setIsOverflowed(scrollHeight > maxHeight);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [review.reviewText]);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <ReviewCardWrap>
      <ReviewInfoRow>
        <CustomerIcon>
          <User width="26" height="26">
            <use xlinkHref={`${sprite}#customer`}></use>
          </User>
        </CustomerIcon>
        <ReviewCustomerInfo>
          <CustomerName>{review.customerName}</CustomerName>
          <ReviewInfoRow>
            <RatingWrap>
              <RatingStars rating={review.rating} width="20px" />
            </RatingWrap>
            <ReviewDate>{review.reviewDate}</ReviewDate>
          </ReviewInfoRow>
        </ReviewCustomerInfo>
      </ReviewInfoRow>
      <ReviewTextBlock>
        <ReviewText ref={textRef} isExpanded={isExpanded}>
          {review.reviewText}
        </ReviewText>
        {isOverflowed && (
          <ViewMoreButtonWrap>
            <ViewMoreButton onClick={toggleExpand}>
              {isExpanded ? "Show Less" : "Read More"}
            </ViewMoreButton>
          </ViewMoreButtonWrap>
        )}
      </ReviewTextBlock>
    </ReviewCardWrap>
  );
};

export default ReviewCard;
