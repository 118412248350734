import Timer from "../Timer";
import { InformerWrap, InformerText } from "./Banners.styled";
function InfoTimerBanner() {
  return (
    <InformerWrap>
      <InformerText>
        Hurry! <span>10% </span>
        off for the next 
      </InformerText>
      <Timer type="informer" />
    </InformerWrap>
  );
}

export default InfoTimerBanner;
