import React, { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import sprite from "../../icons/symbol-defs.svg";
import {
  TSocialMediaPost,
  TSocialMediaPostHistoryPrompt,
} from "../../types/index";
import {
  AIGeneratorProptLabel,
  PromptWrap,
  StyledForm,
  AIGeneratorPromptFormWrap,
  AIGeneratorPromptInputWrap,
  AIGeneratorIncludeInPrompt,
  PromptIncludeLabel,
  AiGeneratorNotificationRow,
  AiGeneratorNotification,
  PromptFooter,
  Svg,
} from "./SocialMediaPostGenerator.styled";
import Button from "../Button";
import TextareaField from "../TextareaField/TextareaField";
import Select from "../Select";
import Switch from "../Switch";

interface SocialMediaPostProps {
  onData: (data: TSocialMediaPost) => void;
  setLoading: (loading: boolean) => void;
  setTechnicalError: (error: boolean) => void;
  setGeneratorLimit: (isLimited: boolean) => void;
  prompt: TSocialMediaPostHistoryPrompt | null;
}

interface FormValues {
  product_description: string;
  post_description: string;
}

const validationSchema = Yup.object().shape({
  product_description: Yup.string().required("Required"),
  post_description: Yup.string().required("Required"),
});

const socialTypeData: string[] = [
  "Instagram",
  "Facebook",
  "Twitter",
  "LinkedIn",
];

const SocialMediaPostPrompt: React.FC<SocialMediaPostProps> = ({
  onData,
  setLoading,
  setTechnicalError,
  setGeneratorLimit,
  prompt,
}) => {
  const [includeHashtags, setIncludeHashtags] = useState<boolean>(false);
  const [includeEmoji, setIncludeEmoji] = useState<boolean>(false);
  const [socialType, setSelectedItem] = useState<string>("Instagram");
  const [isAvailableGenerator, setIsAvailableGenerator] = useState(false);

  const initialValues: FormValues = {
    product_description: prompt?.productDescription || "",
    post_description: prompt?.postIdea || "",
  };

  useEffect(() => {
    if (prompt) {
      setIncludeHashtags(prompt.includeHashtags || false);
      setIncludeEmoji(prompt.includeEmoji || false);
      setSelectedItem(prompt.socialMediaType || "Instagram");
    }
  }, [prompt]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
         `${process.env.REACT_APP_SERVER_URL}/user/get-user-info`,
          {},
          {
            withCredentials: true,
          }
        );
        if (response.data.usageLimits.socialMediaPosts) {
          setIsAvailableGenerator(response.data.usageLimits.socialMediaPosts);
        }
      } catch (error) {
        console.error("Error fetching user information:", error);
      } finally {
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    if (!isAvailableGenerator) {
      try {
        setLoading(true);
        const result = await social_post_generator(values);
        onData(result);
      } catch (error) {
        setTechnicalError(true);
        console.error("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      setGeneratorLimit(true);
    }
  };

  const user = useSelector((state: RootState) => state.user);

  const handleToggleHashtags = () => {
    setIncludeHashtags(!includeHashtags);
  };

  const handleToggleEmoji = () => {
    setIncludeEmoji(!includeEmoji);
  };

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  const social_post_generator = async (formData: FormValues) => {
    const url =`${process.env.REACT_APP_SERVER_URL}/openai/social-media-post-generator`;

    try {
      const response = await axios.post(url, {
        socialMediaPlatformName: socialType,
        postProductDescription: formData.product_description,
        postIdea: formData.post_description,
        includeEmoji,
        includeHashtags,
        userId: user.id,
      });
      return response.data;
    } catch (error) {
      setTechnicalError(true);
      console.error("Request failed:", error);
      return { post: null, image_description: null, errors: "Request failed" };
    }
  };

  return (
    <PromptWrap type="media-post">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit}>
            <AIGeneratorPromptFormWrap>
              <AIGeneratorPromptInputWrap>
                <AIGeneratorProptLabel>Choose platform:</AIGeneratorProptLabel>
                <Select
                  data={socialTypeData}
                  onSelect={handleSelect}
                  defaultValue={socialType}
                />
              </AIGeneratorPromptInputWrap>
              <AIGeneratorPromptInputWrap>
                <AIGeneratorProptLabel>
                  Describe your product:
                </AIGeneratorProptLabel>
                <TextareaField
                  name="product_description"
                  placeholder="Describe your product"
                />
              </AIGeneratorPromptInputWrap>
              <AIGeneratorPromptInputWrap>
                <AIGeneratorProptLabel>
                  What do you want to write a post about?:
                </AIGeneratorProptLabel>
                <TextareaField
                  name="post_description"
                  placeholder="What you want to post about?"
                />
              </AIGeneratorPromptInputWrap>
              <AIGeneratorPromptInputWrap>
                <AIGeneratorIncludeInPrompt>
                  <PromptIncludeLabel>Generate hashtags</PromptIncludeLabel>
                  <Switch
                    isOn={includeHashtags}
                    handleToggle={handleToggleHashtags}
                  />
                </AIGeneratorIncludeInPrompt>
                <AIGeneratorIncludeInPrompt>
                  <PromptIncludeLabel>Include emoji</PromptIncludeLabel>
                  <Switch
                    isOn={includeEmoji}
                    handleToggle={handleToggleEmoji}
                  />
                </AIGeneratorIncludeInPrompt>
              </AIGeneratorPromptInputWrap>
            </AIGeneratorPromptFormWrap>
            <PromptFooter>
              <Button
                btnType="primary"
                type="submit"
                text="Generate"
                withIcon={false}
              />
              <AiGeneratorNotificationRow>
                <Svg width="24" height="24">
                  <use xlinkHref={`${sprite}#clock`} />
                </Svg>
                <AiGeneratorNotification>
                  Please note that the personalized model training process
                  initiated by clicking this button will take approximately{" "}
                  <span>2 minutes</span>
                </AiGeneratorNotification>
              </AiGeneratorNotificationRow>
            </PromptFooter>
          </StyledForm>
        )}
      </Formik>
    </PromptWrap>
  );
};

export default SocialMediaPostPrompt;
