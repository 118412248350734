import { TimelineType } from "../../types";
import TimelineStep from "../TimelineStep";
import {
  TimelineWrapper,
  TimelineTitle,
  TimelineGuide,
  TimelineList,
  TimlinePrevention,
  Icon,
  TimlinePreventionText,
} from "./Timeline.styled";

type TimelineProps = {
  timeLine: TimelineType[];
  timlinePreventionText?: string;
  timelineTitle?: string;
  guideColor?: string;
};

const Timeline: React.FC<TimelineProps> = ({
  timeLine,
  timlinePreventionText,
  timelineTitle,
  guideColor,
}) => {
  if (!Array.isArray(timeLine) || timeLine.length === 0) {
    return null;
  }
  return (
    <TimelineWrapper>
      <TimelineTitle>
        {timelineTitle ? timelineTitle : "Project Timeline:"}
      </TimelineTitle>
      <TimelineList>
        {timeLine &&
          timeLine.map((step, index) => (
            <TimelineStep
              key={step.stepNumber}
              stepIndex={index}
              stepNumber={step.stepNumber}
              stepColor={step.stepColor}
              stepTitle={step.stepTitle}
              stepNumberColor={step.stepNumberColor}
              stepTitleWidth={step.stepTitleWidth}
              stepMainTitle={step.stepMainTitle}
            />
          ))}
        <TimelineGuide guideColor={guideColor}></TimelineGuide>
      </TimelineList>
      {timlinePreventionText ? (
        <TimlinePrevention>
          <Icon>!</Icon>
          <TimlinePreventionText>{timlinePreventionText}</TimlinePreventionText>
        </TimlinePrevention>
      ) : (
        <></>
      )}
    </TimelineWrapper>
  );
};

export default Timeline;
