import { useState } from "react";
import { ErrorMessage, Formik, Form, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import InputComponent from "../InputField";
import Button from "../Button";
import {
  AuthorizationText,
  InputsWrap,
  FormWrap,
  ResendButton,
  AuthorizationTitle,
  ErrorText,
} from "./Authorization.styled";
import Popup from "../Popup";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  interface FormValues {
    email: string;
  }

  const initialValues: FormValues = {
    email: "",
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setError(null);
      await resetPassword(values);
    } catch (error) {
      console.error("Password reset failed:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const resetPassword = async (formData: FormValues) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/user/reset-password`;

    try {
      await axios.post(url, {
        email: formData.email,
      });
      setIsPopupOpen(true);
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.data.error
      ) {
        setError("That email is not registered");
      } else {
        setError("Request for password reset failed. Please try again later.");
      }
      console.error("Request for password reset failed:", error);
    }
  };

  const clickPopup = () => {
    navigate("/login");
  };

  return (
    <>
      <AuthorizationTitle>Forgot Password?</AuthorizationTitle>
      <AuthorizationText>
        Oops, forgot your password? No worries! Enter the email address
        associated with your account below, and we'll send you a link to reset
        your password. Just follow the instructions in the email, and you'll be
        back on track in no time.
      </AuthorizationText>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormWrap>
              <InputsWrap>
                <InputComponent name="email" placeholder="Email" />
                <ErrorMessage name="email" component={ErrorText} />
                {error && <ErrorText>{error}</ErrorText>}
                <Button
                  btnType="primary"
                  type="submit"
                  text="Submit"
                  withIcon={false}
                />
              </InputsWrap>
              <AuthorizationText>
                Didn’t get the link?{" "}
                <ResendButton type="submit">Resend</ResendButton>
              </AuthorizationText>
            </FormWrap>
          </Form>
        )}
      </Formik>
      <Popup
        popupType="reset-password"
        isOpen={isPopupOpen}
        onClose={clickPopup}
      />
    </>
  );
};

export default ForgotPassword;
