import { FC } from "react";
import { TNavList } from "../../types";
import { List, Element } from "./MobileNavList.styled";
import MobileNavLinkComponent from "../MobileNavLinkComponent";

interface IMobileNavListProps {
  navList: TNavList;
}

const MobileNavList: FC<IMobileNavListProps> = ({
  navList,
}) => {
  return (
    <nav>
      <List>
        {navList.map(({ to, text, scroll }) => (
          <Element key={text}>
            <MobileNavLinkComponent to={to} text={text} scroll={scroll}/>
          </Element>
        ))}
      </List>
    </nav>
  );
};

export default MobileNavList;
