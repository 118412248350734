import { Form } from "formik";
import styled from "@emotion/styled";
import { accentText, labelText } from "../../assets/typography";
import { purple, violet, white } from "../../assets";

export const AIGeneratorPromptInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AIGeneratorPromptFormWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 48px;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 746px;
`;
export const PromptWrap = styled.div<{ type?: string }>`
  padding: 40px 20px 40px 20px;
  background-color: ${white};
  border-radius: ${(props) =>
    props.type === "media-post" ? "20px 0 0 20px" : "20px"};
  min-height: 746px;
`;
export const PromptFooter = styled.div``;
export const AIGeneratorProptLabel = styled.p`
  ${labelText}
  text-align: left;
  color: #414141;
`;

export const AiGeneratorNotificationRow = styled.div`
  display: flex;
`;
export const AiGeneratorNotification = styled.p`
  margin-left: 16px;
  ${accentText}
  font-weight: 400;
  color: #414141;
  text-align: left;
  & > span {
    color: ${purple};
    font-weight: 700;
  }
`;

export const NotificationSvg = styled.svg`
  fill: ${white};
`;
export const ContentPlanResultWrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 838px;
  height: 100%;
  width: 100%;
  background-color: ${white};
  border-radius: 20px;
`;

export const ContentPlanWrap = styled.div`
  display: flex;
`;

export const ContentPlanPromptWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const QuillEditButtons = styled.div`
  display: flex;
  gap: 24px;
`;

export const ContentPlanResultBody = styled.div`
  display: flex;
  height: 100%;
`;

export const ContentPlanResultPreviewList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 40px 0px 20px 0px;
  border-right: 1px solid #f5f6f8;
  max-height: 710px;
  height: 100%;
  min-width: 193px;
  width: 193px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #888 ${white};

  &::-webkit-scrollbar {
    width: 2px;
    transition: opacity 0.3s;
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    background: ${white};
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &:hover::-webkit-scrollbar {
    opacity: 1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &::-webkit-scrollbar-thumb:active {
    background: #333;
  }
`;

export const ContentPlanResultPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 20px 60px 20px;
  background-color: #f5f6f8;
`;
export const PreviewItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  background-color: ${(props) =>
    props.active ? "rgba(176, 128, 237, 0.1)" : ""};
  height: 116px;
  margin-right: 8px;
  border-radius: 8px;
  transition: background-color 0.3s linear;
`;
export const PreviewItemWrap = styled.div<{
  active: boolean;
  number: number;
}>`
  position: relative;
  &::before {
    content: "";
    height: 100px;
    width: 4px;
    background-color: ${violet};
    position: absolute;
    left: -25px;
    border-radius: 0px 5px 5px 0px;
  }
  &::after {
    content: "${(props) => (props.active ? props.number : "")}";
    position: absolute;
    font-size: 10px;
    font-weight: 500;
    left: -13px;
    top: 0;
  }

  margin: 8px;
  width: ${(props) => (props.active ? "153px" : "167px")};
  height: 100px;
  border: 1px solid;
  border-radius: 12px;
  border-color: ${(props) =>
    props.active ? "rgba(176, 128, 237, 1)" : "rgba(245, 246, 248, 1)"};
  transform: translateX(${(props) => (props.active ? "16px" : "px")});
  transition: transform 0.3s linear;
  & > div {
    transform: scale(0.19);
    transform-origin: top left;
    border-radius: 60px;
    min-height: 515px;
    width: ${(props) => (props.active ? "795px" : "870px")};
    height: 515px;
    overflow: hidden;
  }
`;
