import { PartnersWrap, PartnersTitle, Partner, PartnerSvg} from "./Partners.styled";
import sprite from "../../icons/symbol-defs.svg";


type PartnersProps = {
  partner: string;
};

const Partners: React.FC<PartnersProps> = ({ partner }) => {
  return (
    <PartnersWrap>
      <PartnersTitle>Our partners</PartnersTitle>
      <Partner>{partner}</Partner>
      <PartnerSvg width="52" height="50">
        <use xlinkHref={`${sprite}#stars`}></use>
      </PartnerSvg>
    </PartnersWrap>
  );
};

export default Partners;
