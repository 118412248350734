import styled from "@emotion/styled";
import { h4 } from "../../assets";

type DashboardProps = {};

export const DashboardWrapper = styled.div<DashboardProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const DashboardTitle = styled.p<DashboardProps>`
  ${h4}
  text-align:left;
  margin-bottom: 24px;
`;
