import styled from "@emotion/styled";
import { c2, oldPriceText, orderCardDescription } from "../../assets/";
import { informerText, inputText } from "../../assets/typography";
export const OrderCardWrapper = styled.div`
  width: 100%;
  height: 147px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    width: 296px;
  }
`;
export const OrderCardBody = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const OrderCardImg = styled.img`
  width: 147px;
  height: 147px;
  border-radius: 10px;
  @media (max-width: 768px) {
    height: 122.57px;
    width: 296px;
  }
`;
export const OrderCardInfo = styled.div`
  width: 100%;
  text-align: left;
  margin-left: 24px;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 16px;
  }
`;
export const OrderCardRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const OrderCardTitle = styled.p`
  ${c2}
  font-weight:500;
`;
export const OrderCardText = styled.p`
  ${orderCardDescription}
  padding-top:24px;
  max-height: 58px;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 16px 0;
  }
`;
export const OrderCardCost = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  /* flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 768px) {
    flex-direction: row-reverse;
  } */
`;
export const OrderCardRemoveIcon = styled.svg`
  width: 24px;
  height: 24px;
`;
export const OrderPriceBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  color: #333333;
  @media (max-width: 768px) {
    gap: 8px;
  }
`;
export const HoursCoastBlock = styled.div`
  display: flex;
  gap: 8px;
`;
export const HoursCoastPrice = styled.p`
  ${oldPriceText}
  font-weight: 500;
`;
export const HoursCoastText = styled.p`
  ${inputText}
  color: #878787;
`;

export const CurrentOrderPrice = styled.p`
  ${informerText}
  font-weight: 600;
`;
export const OldOrderPrice = styled.p`
  ${oldPriceText}
  text-decoration: line-through;
`;
