import React from "react";
import sprite from "../../icons/symbol-defs.svg";
import { TContentPlan, TSocialMediaPost } from "../../types";
import {
  AIHistoryWrap,
  AIHistoryInfoRow,
  AIHistoryInfoElement,
  AIHistoryListElement,
  AIHistoryListWrap,
  EmptySvg,
  AIHistoryEmptyTitle,
  AIHistoryEmptySubtitle,
  AIHistoryListElementNumber,
  AIHistoryListElementDate,
  AIHistoryListElementType,
  AIHistoryListElementActionButtons,
  AIHistoryListElementActionButton,
  ButtonSvg,
} from "./AIHistoryList.styled";
import { useNavigate } from "react-router-dom";

type AIHistoryListProps = {
  data: (TContentPlan | TSocialMediaPost)[];
};

const AIHistoryList: React.FC<AIHistoryListProps> = ({ data }) => {
  const navigate = useNavigate();

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      throw new Error("Invalid Date");
    }

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    return `${month}/${day}/${year} ${formattedHours}:${minutes}${ampm}`;
  };

  const handleViewPrompt = (requestId: string, requestType: string) => {
    if (requestType === "SocialMediaPost") {
      navigate(`/social-post-generator/${requestId}/prompt`);
    } else if (requestType === "ContentPlan") {
      navigate(`/content-plan-generator/${requestId}/prompt`);
    } else if (requestType === "MarketingStrategy") {
      navigate(`/marketing-strategy-generator/${requestId}/prompt`);
    }
  };
  const handleViewResult = (requestId: string, requestType: string) => {
    if (requestType === "SocialMediaPost") {
      navigate(`/social-post-generator/${requestId}/result`);
    } else if (requestType === "ContentPlan") {
      navigate(`/content-plan-generator/${requestId}/result`);
    } else if (requestType === "MarketingStrategy") {
      navigate(`/marketing-strategy-generator/${requestId}/result`);
    }
  };

  return (
    <AIHistoryWrap>
      <AIHistoryInfoRow>
        <AIHistoryInfoElement width="20px">№</AIHistoryInfoElement>
        <AIHistoryInfoElement width="145px">Date and time</AIHistoryInfoElement>
        <AIHistoryInfoElement width="166px">Type</AIHistoryInfoElement>
        <AIHistoryInfoElement width="108px">Action</AIHistoryInfoElement>
      </AIHistoryInfoRow>

      {data && data.length > 0 ? (
        <AIHistoryListWrap>
          {data.map((request, index) => {
            return (
              <AIHistoryListElement key={index}>
                <AIHistoryListElementNumber>
                  {index + 1}
                </AIHistoryListElementNumber>
                <AIHistoryListElementDate>
                  {formatDate(request.requestDate)}
                </AIHistoryListElementDate>
                <AIHistoryListElementType type={request.requestGeneratorType}>
                  {request.requestGeneratorType === "SocialMediaPost"
                    ? "Social Media Post"
                    : request.requestGeneratorType === "ContentPlan"
                    ? "Content Plan"
                    : "Marketing strategy"}
                </AIHistoryListElementType>
                <AIHistoryListElementActionButtons>
                  <AIHistoryListElementActionButton
                    onClick={() =>
                      handleViewResult(
                        request._id,
                        request.requestGeneratorType
                      )
                    }
                  >
                    <ButtonSvg width="24" height="24">
                      <use xlinkHref={`${sprite}#watch`}></use>
                    </ButtonSvg>
                  </AIHistoryListElementActionButton>
                  <AIHistoryListElementActionButton
                    onClick={() =>
                      handleViewPrompt(
                        request._id,
                        request.requestGeneratorType
                      )
                    }
                  >
                    <ButtonSvg width="24" height="24">
                      <use xlinkHref={`${sprite}#retry`}></use>
                    </ButtonSvg>
                  </AIHistoryListElementActionButton>
                </AIHistoryListElementActionButtons>
              </AIHistoryListElement>
            );
          })}
        </AIHistoryListWrap>
      ) : (
        <>
          <EmptySvg width="69" height="66">
            <use xlinkHref={`${sprite}#empty-history`}></use>
          </EmptySvg>
          <AIHistoryEmptyTitle>No content generated yet.</AIHistoryEmptyTitle>
          <AIHistoryEmptySubtitle>
            Start Generating Content Now
          </AIHistoryEmptySubtitle>
        </>
      )}
    </AIHistoryWrap>
  );
};

export default AIHistoryList;
