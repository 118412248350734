import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  TSocialMediaPost,
  TSocialMediaPostHistory,
  TSocialMediaPostHistoryPrompt,
} from "../../types/index";
import { SocialMediaPostWrap } from "./SocialMediaPostGenerator.styled";
import SocialMediaPostPrompt from "./SocialMediaPostPrompt";
import SocialMediaPostResult from "./SocialMediaPostResult";
import Popup from "../Popup";

interface SocialMediaPostGeneratorProps {
  data: TSocialMediaPostHistory | undefined;
  type: string | undefined;
}

const SocialMediaPostGenerator: React.FC<SocialMediaPostGeneratorProps> = ({
  data,
  type,
}) => {
  const [postData, setPostData] = useState<TSocialMediaPost>({
    _id: "",
    post: "",
    imageDescription: "",
    imageUrl: null,
    errors: null,
    requestGeneratorType: "",
    requestDate: "",
    customImage: false,
  });
  const [errorsData, setErrorsData] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadintContentType, setLoadintContentType] =
    useState<string>("social media post");
  const [changeImagePopup, setChangeImagePopupOpen] = useState<boolean>(false);
  const [errorPopup, setErrorPopupOpen] = useState<boolean>(false);
  const [isAvailableGenerator, setIsAvailableGenerator] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [copyButtonText, setCopyButtonText] = useState<string>("Copy result");

  const [promptHistory, setPromptHistory] =
    useState<TSocialMediaPostHistoryPrompt | null>(null);

  useEffect(() => {
    if (data && type) {
      if (
        type === "result" &&
        data.socialMediaPost &&
        data.socialMediaPost.result
      ) {
        const { result } = data.socialMediaPost;
        setPostData({
          _id: data._id,
          post: result.post,
          imageDescription: result.imageDescription,
          imageUrl: result.image,
          errors: null,
          requestGeneratorType: "SocialMediaPost",
          requestDate: "",
          customImage: result.customImage,
        });
      } else if (type === "prompt") {
        const { prompt } = data.socialMediaPost;

        setPromptHistory({
          includeEmoji: prompt.includeEmoji,
          includeHashtags: prompt.includeHashtags,
          postIdea: prompt.postIdea,
          productDescription: prompt.productDescription,
          socialMediaType: prompt.socialMediaType,
        });
      }
    }
  }, [data, type]);

  const handleSocialPostData = async (data: TSocialMediaPost) => {
    if (data.errors) {
      setErrorMessage("request-error");
      setErrorPopupOpen(true);
    } else {
      setPostData({
        _id: data._id,
        post: data.post,
        imageDescription: data.imageDescription,
        imageUrl: data.imageUrl,
        errors: data.errors,
        requestGeneratorType: "",
        requestDate: "",
        customImage: false,
      });

      if (data.imageUrl == null) {
        try {
          const response = await generateImageWithRetry(
            (type = "Image"),
            data.imageDescription,
            data._id,
            false
          );

          setPostData((prevData) => ({
            ...prevData,
            imageUrl: response.imageUrl,
            customImage: response.customImage,
          }));
        } catch (error) {
          console.error("Failed to generate image:", error);
        }
      }
    }
    setLoading(false);
  };

  const setTechnicalError = (error: boolean) => {
    if (error) {
      setErrorMessage("technical-error");
      setErrorPopupOpen(true);
    }
  };

  const generateImageWithRetry = async (
    type: string,
    imageDescription: string,
    requestId: string,
    customImage: boolean
  ): Promise<{ imageUrl: string; customImage: boolean }> => {
    const url = `${process.env.REACT_APP_SERVER_URL}/openai/image-generator`;
    const initialDelay = 10000; // Delay 10seconds
    const maxAttempts = 50; // The maximum number of attempts after which the creation request will expire when the maximum number of requests is reached, you can add a repeat test button that will launch the request again
    let attempt = 0;
    while (attempt < maxAttempts) {
      try {
        const response = await axios.post<{
          image_url: string;
          customImage: boolean;
        }>(url, {
          imageDescription: imageDescription,
          type: type,
          requestId: requestId,
          historyType: "Social Media Post",
          postIndex: 0,
          customImage,
        });

        if (response.data.image_url) {
          return {
            imageUrl: response.data.image_url,
            customImage: response.data.customImage,
          };
        } else {
          throw new Error("No image URL returned");
        }
      } catch (error) {
        attempt += 1;

        if (attempt >= maxAttempts) {
          throw new Error("Max retries exceeded");
        }

        await new Promise((resolve) => setTimeout(resolve, initialDelay));
      }
    }

    throw new Error("Failed to generate image after multiple retries");
  };

  const handleImageGeneration = async (values: {
    image_description: string;
  }) => {
    setLoading(true);
    setLoadintContentType("image");
    setChangeImagePopupOpen(false);
    try {
      const response = await generateImageWithRetry(
        (type = "UserPromptImage"),
        values.image_description,
        postData._id,
        true
      );
      setPostData((prevData) => ({
        ...prevData,
        imageUrl: response.imageUrl,
        customImage: response.customImage,
      }));
    } catch (error) {
      console.error("Request failed:", error);
      setErrorsData("An error occurred while generating the image.");
    } finally {
      setLoading(false);
    }
  };

  const handleCopyResult = async () => {
    try {
      if (postData) {
        await navigator.clipboard.writeText(postData.post);
        setCopyButtonText("Copied");
        setTimeout(() => setCopyButtonText("Copy result"), 2000);
      }
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const handleNewPromptImage = () => {
    setChangeImagePopupOpen(true);
  };

  const handleUploadImage = async () => {
    if (postData.imageUrl) {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_SERVER_URL
          }/upload/download-image?url=${encodeURIComponent(postData.imageUrl)}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/octet-stream",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Помилка в мережі");
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "image.png");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Помилка під час завантаження зображення:", error);
      }
    }
  };

  return (
    <SocialMediaPostWrap>
      <SocialMediaPostPrompt
        onData={handleSocialPostData}
        setLoading={setLoading}
        setTechnicalError={setTechnicalError}
        setGeneratorLimit={setIsAvailableGenerator}
        prompt={promptHistory}
      />
      <SocialMediaPostResult
        postData={postData}
        errors={errorsData}
        handleChangeImage={handleNewPromptImage}
        handleDownloadImage={handleUploadImage}
        handleCopyResult={handleCopyResult}
        copyButtonText={copyButtonText}
      />
      <Popup
        popupType="loading"
        isOpen={loading}
        contentType={loadintContentType}
      />
      <Popup
        popupType="image-prompt"
        isOpen={changeImagePopup}
        contentType="social media post"
        onSubmitPrompt={handleImageGeneration}
        onClose={() => setChangeImagePopupOpen(false)}
      />
      <Popup
        popupType="application-error"
        isOpen={isAvailableGenerator}
        onClose={() => setIsAvailableGenerator(false)}
      />
      {errorMessage && (
        <>
          <Popup
            popupType={errorMessage}
            isOpen={errorPopup}
            onClose={() => setErrorPopupOpen(false)}
          />
          <Popup
            popupType={errorMessage}
            isOpen={errorPopup}
            onClose={() => setErrorPopupOpen(false)}
          />
        </>
      )}
    </SocialMediaPostWrap>
  );
};

export default SocialMediaPostGenerator;
