import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import Button from "../Button";
import LoadingSpinner from "../LoadingSpinner";
import TextareaField from "../TextareaField/TextareaField";
import {
  applicationSuccessful,
  applicationError,
  purchase,
  technicalError,
} from "../../assets/image/popups/index";
import {
  Wrapper,
  AdditionalWrapper,
  Img,
  Title,
  Text,
  StyledForm,
  UploadImagePopupRow,
  PopupRow,
  ButtonWrapper,
  SpinnerWrapper,
  AdditionalText,
  CloseButton,
  CloseImagePopupButton,
} from "./Popup.styled";
import InputFile from "../InputFile";

type PopupProps = {
  popupType: string;
  contentType?: string;
  isOpen: boolean;
  loading?: boolean;
  onClose?: () => void;
  onSubmitPrompt?: (values: FormPromptValues) => void;
  onSubmitUpload?: (values: FormFileValues) => void;
  onValidationError?: (error: string) => void;
};

type FormFileValues = {
  file: File | null;
};

type FormPromptValues = {
  image_description: string;
};

const initialFileValues: FormFileValues = {
  file: null,
};

const validationFileSchema = Yup.object().shape({
  file: Yup.string().required("Description is required"),
});

const validationPromptSchema = Yup.object().shape({
  image_description: Yup.string().required("Description is required"),
});

const initialPromptValues: FormPromptValues = {
  image_description: "",
};

const PopupContent: Record<
  string,
  (
    onClose?: () => void,
    contentType?: string,
    onSubmitPrompt?: (values: FormPromptValues) => void,
    onSubmitUpload?: (values: FormFileValues) => void,
    fileTypes?: string[] | undefined,
    onValidationError?: (error: string) => void,
    loading?: boolean
  ) => JSX.Element
> = {
  "application-successful": (onClose) => (
    <>
      <Img src={applicationSuccessful} alt="successful" />
      <Title>
        Thank you for your application. We will contact you shortly!
      </Title>
      <ButtonWrapper>
        <Button onClick={onClose} text="Okay, Thanks!" withIcon={false} />
      </ButtonWrapper>
    </>
  ),
  "application-error": (onClose) => (
    <>
      <Img src={applicationError} alt="error" />
      <Title>
        Thank you for your application. Unfortunately, we were unable to process
        it at this time. Please try again.
      </Title>
      <ButtonWrapper>
        <Button onClick={onClose} text="Try Again" withIcon={false} />
      </ButtonWrapper>
    </>
  ),
  purchase: (onClose) => (
    <>
      <Img src={purchase} alt="purchase" />
      <Title>Purchase Successful!</Title>
      <Text>
        Thank you for your purchase. One of our managers will contact you
        shortly.
      </Text>
      <ButtonWrapper>
        <Button onClick={onClose} text="Great, Thanks!" withIcon={false} />
      </ButtonWrapper>
    </>
  ),
  "reset-password": (onClose) => (
    <>
      <Img src={purchase} alt="purchase" />
      <Title>Check your email!</Title>
      <Text>
        A message with a link to reset your password has been sent to your email
      </Text>
      <ButtonWrapper>
        <Button onClick={onClose} text="Great, Thanks!" withIcon={false} />
      </ButtonWrapper>
    </>
  ),
  "confirm-email": (onClose) => (
    <>
      <Img src={purchase} alt="purchase" />
      <Title>Check your email!</Title>
      <Text>Code was sent to your email</Text>
      <ButtonWrapper>
        <Button onClick={onClose} text="Great, Thanks!" withIcon={false} />
      </ButtonWrapper>
    </>
  ),
  loading: (onClose, contentType) => (
    <>
      <SpinnerWrapper>
        <LoadingSpinner width="48px" height="48px" />
      </SpinnerWrapper>
      <Title>Generating your {contentType}</Title>
      <AdditionalText>Please wait a moment.</AdditionalText>
    </>
  ),
  "technical-error": (onClose) => (
    <>
      <Img src={technicalError} alt="error" />
      <Title>Error</Title>
      <Text>Technical problem, try again.</Text>
      <ButtonWrapper>
        <Button onClick={onClose} text="Try Again" withIcon={false} />
      </ButtonWrapper>
    </>
  ),
  "request-error": (onClose) => (
    <>
      <Img src={applicationError} alt="error" />
      <Title>Error</Title>
      <Text>Change your request and try again.</Text>
      <ButtonWrapper>
        <Button onClick={onClose} text="Try Again" withIcon={false} />
      </ButtonWrapper>
    </>
  ),
  "size-error": (onClose) => (
    <>
      <Img src={applicationError} alt="error" />
      <Title>Error</Title>
      <Text>
        The uploaded image must have a 1:1 aspect ratio. Please adjust your
        image and try again.
      </Text>
      <ButtonWrapper>
        <Button onClick={onClose} text="Try Again" withIcon={false} />
      </ButtonWrapper>
    </>
  ),
  "format-error": (onClose) => (
    <>
      <Img src={applicationError} alt="error" />
      <Title>Error</Title>
      <Text>
        Invalid file format. Please upload an image in JPG or PNG format.
      </Text>
      <ButtonWrapper>
        <Button onClick={onClose} text="Try Again" withIcon={false} />
      </ButtonWrapper>
    </>
  ),
  "credits-error": (onClose) => (
    <>
      <Img src={applicationError} alt="error" />
      <Text>
        You're out of credits! Upgrade to the Pro plan for unlimited AI
        generations.
      </Text>
      <ButtonWrapper>
        <Button onClick={onClose} text="Upgrade Now" withIcon={false} />
      </ButtonWrapper>
    </>
  ),
  "upload-image": (
    onClose,
    contentType,
    _,
    onSubmitUpload,
    fileTypes,
    onValidationError,
    loading
  ) => {
    // const [uploadingError, setErrorUploading] = useState(false);
    return (
      <>
        <Formik
          initialValues={initialFileValues}
          validationSchema={validationFileSchema}
          onSubmit={async (
            values: FormFileValues,
            { setSubmitting }: FormikHelpers<FormFileValues>
          ) => {
            if (onSubmitUpload) onSubmitUpload(values);
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <UploadImagePopupRow>
                <Title>Use your image:</Title>
                <CloseImagePopupButton onClick={onClose} />
              </UploadImagePopupRow>
              <Text custom={true}>
                Ensure the image has a 1:1 aspect ratio. Accepted formats: JPG,
                JPEG, PNG, IMG.
              </Text>
              <InputFile
                handleChange={(file) => setFieldValue("file", file)}
                name="file"
                types={fileTypes}
                label="Drag and drop or click here"
                reset={true}
                $uploadertype="image"
                onValidationError={onValidationError}
              />
              <ButtonWrapper custom={true}>
                <Button
                  text={loading ? "Saving..." : "Save"}
                  withIcon={false}
                  width="300px"
                  type="submit"
                />
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </>
    );
  },
  "image-prompt": (onClose, contentType, onSubmitPrompt) => (
    <>
      <Formik
        initialValues={initialPromptValues}
        validationSchema={validationPromptSchema}
        onSubmit={async (
          values: FormPromptValues,
          { setSubmitting }: FormikHelpers<FormPromptValues>
        ) => {
          if (onSubmitPrompt) onSubmitPrompt(values);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit}>
            <PopupRow>
              <Title>Describe what you want to create</Title>
              <CloseButton onClick={onClose} />
            </PopupRow>
            <TextareaField name="image_description" placeholder="Add text" />
            <ButtonWrapper>
              <Button
                btnType="primary"
                type="submit"
                text="Generate"
                withIcon={false}
              />
            </ButtonWrapper>
          </StyledForm>
        )}
      </Formik>
    </>
  ),
};

const Popup: React.FC<PopupProps> = ({
  isOpen,
  popupType,
  contentType,
  loading,
  onClose,
  onSubmitPrompt,
  onSubmitUpload,
  onValidationError,
}) => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.style.overflow = isOpen ? "hidden !important" : "auto";
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const fileTypes = ["jpeg", "img", "jpg", "png"];

  const PopupComponent =
    PopupContent[popupType] || (() => <Text>Unknown popup type</Text>);

  return ReactDOM.createPortal(
    <Wrapper isOpen={isOpen}>
      <AdditionalWrapper type={popupType}>
        {PopupComponent(
          onClose,
          contentType,
          onSubmitPrompt,
          onSubmitUpload,
          fileTypes,
          onValidationError,
          loading
        )}
      </AdditionalWrapper>
    </Wrapper>,
    document.body
  );
};

export default Popup;
