import React, { useState } from "react";
import { TSocialMediaPost } from "../../types";
import sprite from "../../icons/symbol-defs.svg";
import ImagePreview from "../../assets/image/image-preview.png";
import Button from "../Button";
import {
  ResultWrap,
  ResultTitle,
  ResultContent,
  ResultImageWrap,
  ResultImage,
  ResultText,
  EmptyResultWrap,
  EmptyResulContent,
  EmptyResultIcon,
  EmptyResultTitle,
  EmptyResultText,
  EmptyResulHelp,
  EmptyResulHelpTitle,
  EmptyResulHelpText,
  ImageButtons,
  ImageTooltipWrap,
  ImageTooltipMark,
  ImageTooltipBox,
} from "./SocialMediaPostGenerator.styled";

type AuthorizationProps = {
  postData: TSocialMediaPost;
  errors: string | null;
  copyButtonText: string;
  handleChangeImage: () => void;
  handleDownloadImage: () => void;
  handleCopyResult: () => void;
};

const formatText = (text: string) => {
  return text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

const SocialMediaPostResult: React.FC<AuthorizationProps> = ({
  postData,
  errors,
  copyButtonText,
  handleChangeImage,
  handleDownloadImage,
  handleCopyResult,
}) => {
  const formattedPost = postData.post ? formatText(postData.post) : "";
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const showTooltip = () => setVisibleTooltip(true);
  const hideTooltip = () => setVisibleTooltip(false);
  return (
    <ResultWrap empty={postData.post && postData.imageUrl ? false : true}>
      <ResultTitle>Results:</ResultTitle>
      {postData.post && postData.imageUrl ? (
        <ResultContent>
          <ResultImageWrap>
            <ResultImage src={postData.imageUrl ?? ImagePreview} />
            {postData.customImage ? (
              <></>
            ) : (
              <ImageTooltipWrap>
                <ImageTooltipMark
                  onMouseEnter={showTooltip}
                  onMouseLeave={hideTooltip}
                >
                  !
                </ImageTooltipMark>
                <ImageTooltipBox visible={visibleTooltip}>
                  This image is generated in a sketch style to serve as a
                  reference for your future final image
                </ImageTooltipBox>
              </ImageTooltipWrap>
            )}
            <ImageButtons>
              <Button
                text="Generate image using AI"
                btnType="ai-generator"
                bgColor="#F0F424"
                icon="edit"
                width="173px"
                onClick={handleChangeImage}
              />
              <Button
                text="Download the image"
                btnType="ai-generator"
                bgColor="#F0F424"
                icon="upload"
                width="152px"
                onClick={handleDownloadImage}
              />
            </ImageButtons>
          </ResultImageWrap>
          <ResultText>{formattedPost}</ResultText>
          <Button
            text={copyButtonText}
            withIcon={false}
            bgColor="#B0B8FF"
            onClick={handleCopyResult}
          />
        </ResultContent>
      ) : (
        <EmptyResultWrap>
          <EmptyResulContent>
            <EmptyResultIcon width="48" height="48">
              <use xlinkHref={`${sprite}#no-post`}></use>
            </EmptyResultIcon>
            <EmptyResultTitle>No posts generated yet.</EmptyResultTitle>
            <EmptyResultText>
              Start generating your social media posts now!
            </EmptyResultText>
          </EmptyResulContent>
          <EmptyResulHelp>
            <EmptyResulHelpTitle>
              Need help getting started? Check out our tips below.
            </EmptyResulHelpTitle>
            <EmptyResulHelpText>
              Tip 1: Choose your platform and describe your product to tailor
              your post. Specify the topic you want to write about.
            </EmptyResulHelpText>
            <EmptyResulHelpText>
              Tip 2: Press the 'Generate' button to create your post.
            </EmptyResulHelpText>
          </EmptyResulHelp>
        </EmptyResultWrap>
      )}
    </ResultWrap>
  );
};

export default SocialMediaPostResult;
