import React, { useState } from "react";
import { TCoursesCardList } from "../../types";
import { Wrap, List, Title, ListElement } from "./CoursesCardList.styled";
import BannerContainer from "../Banners/BannerContainer";
import CourseCard from "../CourseCard";

type CoursesCardListProps = {
  list: TCoursesCardList;
};

const CoursesCardList: React.FC<CoursesCardListProps> = ({ list }) => {
  const [isHoveredId, setIsHoveredId] = useState<number | null>(null);

  const handleHover = (id: number) => {
    setIsHoveredId(id);
  };

  const handleMouseLeave = () => {
    setIsHoveredId(null);
  };

  const checkConditions = (
    isHoveredId: number | null,
    cardId: number
  ): boolean => {
    if (isHoveredId === null) {
      return false;
    }

    const isEvenHoveredId = isHoveredId % 2 === 0;

    const isCardIdLessThanHoveredId = isEvenHoveredId
      ? isHoveredId < cardId
      : isHoveredId + 1 < cardId;

    return isCardIdLessThanHoveredId;
  };

  return (
    <Wrap>
      <Title>Our Signature Courses</Title>
      <BannerContainer bannerType="sale" background={"white"} marginB="64px" />
      <List>
        {list.map((card) => {
          return (
            <ListElement key={card.id}>
              <CourseCard
                key={card.id}
                data={card}
                overlay={checkConditions(isHoveredId, card.id)}
                onHover={handleHover}
                onMouseLeave={handleMouseLeave}
                lastChild={
                  card.id === list.length - 1 || card.id === list.length
                }
              />
            </ListElement>
          );
        })}
      </List>
    </Wrap>
  );
};

export default CoursesCardList;
