import styled from "@emotion/styled";
import { d3, h2, green, h3, h6 } from "../../assets";

export const Wrap = styled.div`
  position: relative;
  @media (max-width: 768px) {
    display: flex;
  }
`;
export const Info = styled.div`
  position: relative;
  z-index: 1;
  padding-top: 150px;
  max-width: 55%;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

export const Title = styled.h2`
  ${h2}
  @media (max-width: 1024px) {
    ${h3}
  }
  @media (max-width: 768px) {
    ${h6}
  }
`;

export const TitleWrap = styled.div`
  position: relative;
  margin-bottom: 32px;
  margin-right: 70px;
  @media (max-width: 1024px) {
    margin-bottom: 24px;
    margin-right: 70px;
  }
  @media (max-width: 768px) {
    margin-bottom: 32px;
    margin-right: 0px;
  }
`;

export const Svg = styled.svg`
  position: absolute;
  right: -10px;
  top: -35px;
  fill: ${green};
  @media (max-width: 768px) {
    top: -50px;
  }
`;

export const Description = styled.p`
  ${d3}
  margin-bottom:32px;
  @media (max-width: 1024px) {
    margin: 24px 0;
  }
  @media (max-width: 1024px) {
    margin-bottom: 32px;
  }
`;

export const Img = styled.img`
  position: absolute;
  right: -100px;
  top: 0;
  max-height: 716px;
  height: auto;
  @media (max-width: 1024px) {
    right: -150px;
    top: 170px;
    width: 415px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
