import React, { useState } from "react";
import {
  AIGeneratorFAQWrap,
  AIGeneratorFAQTitle,
  AIGeneratorFAQSubtitle,
  AIGeneratorFAQList,
  AIGeneratorFAQItem,
  AIGeneratorFAQItemTitle,
  AIGeneratorFAQItemInfo,
  AIGeneratorFAQItemDescription,
  AIGeneratorFAQItemRow,
  AIGeneratorFAQItemToggleSvg,
  AIGeneratorFAQItemDescriptionList,
  AIGeneratorFAQItemDescriptionItem,
} from "./AIGeneratorFAQ.styled";
import sprite from "../../icons/symbol-defs.svg";

const AIGeneratorFAQ: React.FC = () => {
  const faqData = [
    {
      title: "What is your product?",
      description:
        "Our AI-powered product generates content for social media, including posts, content plans, and marketing strategies. It helps you quickly create and plan content for promoting your business across social media platforms.",
    },
    {
      title: "What are the main features available?",
      description: "We offer three key features:",
      descriptionList: [
        {
          title: "Social Media Post Generator:",
          description:
            "AI generates posts for Instagram, Facebook, Twitter, and LinkedIn. Simply describe your product and provide a general idea for the post.",
        },
        {
          title: "Content Plan Generator: ",
          description:
            " Based on your product description, AI creates a content plan for the desired period, with sample images for inspiration (you can upload your own as well). The content plan can be saved as a PDF file..",
        },
        {
          title: "Marketing Strategy Generator:",
          description:
            "AI develops a marketing strategy to help promote your product. The final document can also be saved as a PDF file.",
        },
      ],
    },
    {
      title: "Is there a free trial?",
      description:
        "Yes, we offer a 3-day free trial. After the trial ends, your selected plan will be billed automatically.",
    },
    {
      title: "What subscription plans are available?",
      description: "We offer two subscription plans:",
      descriptionList: [
        {
          title: "Creator",
          description:
            "Access to 20 posts, 3 content plans, and 1 marketing strategy. Cost: $19/mo with an annual subscription or $25/mo with a monthly subscription. You can cancel at any time.",
        },
        {
          title: "Content Plan Generator: ",
          description:
            "Unlimited access to all generations, plus a free course. Cost: $29/mo with an annual subscription or $35/mo with a monthly subscription. You can also cancel at any time.",
        },
      ],
    },
    {
      title: "How can I reach support?",
      description:
        "If you have any questions or issues, please contact us at support@woohooooo.com.",
    },
  ];

  const [expandedItems, setExpandedItems] = useState<number[]>([]);

  const toggleItem = (index: number) => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.includes(index)
        ? prevExpandedItems.filter((i) => i !== index)
        : [...prevExpandedItems, index]
    );
  };

  return (
    <AIGeneratorFAQWrap>
      <AIGeneratorFAQTitle>FAQ</AIGeneratorFAQTitle>
      <AIGeneratorFAQSubtitle>
        Answers to Frequently Asked Questions
      </AIGeneratorFAQSubtitle>
      <AIGeneratorFAQList>
        {faqData.map((item, index) => {
          const isExpanded = expandedItems.includes(index);
          return (
            <AIGeneratorFAQItem key={index}>
              <AIGeneratorFAQItemRow onClick={() => toggleItem(index)}>
                <AIGeneratorFAQItemTitle>{item.title}</AIGeneratorFAQItemTitle>
                <AIGeneratorFAQItemToggleSvg width="32" height="32">
                  <use xlinkHref={`${sprite}#toggle`}></use>
                </AIGeneratorFAQItemToggleSvg>
              </AIGeneratorFAQItemRow>
              <AIGeneratorFAQItemInfo
                style={{
                  maxHeight: isExpanded ? "1000px" : "0",
                }}
              >
                <AIGeneratorFAQItemDescription>
                  {item.description}
                </AIGeneratorFAQItemDescription>
                {item.descriptionList && (
                  <AIGeneratorFAQItemDescriptionList>
                    {item.descriptionList.map((item, index) => (
                      <AIGeneratorFAQItemDescriptionItem key={index}>
                        <span>{item.title} </span>
                        {item.description}
                      </AIGeneratorFAQItemDescriptionItem>
                    ))}
                  </AIGeneratorFAQItemDescriptionList>
                )}
              </AIGeneratorFAQItemInfo>
            </AIGeneratorFAQItem>
          );
        })}
      </AIGeneratorFAQList>
    </AIGeneratorFAQWrap>
  );
};

export default AIGeneratorFAQ;
