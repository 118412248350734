import styled from "@emotion/styled";

type SectionProps = {
  marginB?: string;
  overflow?: string;
  type: string | undefined;
  zIndex?: string;
};

export const SectionCss = styled.section<SectionProps>`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: ${(props) =>
    props.marginB === "0" ? "0" : props.marginB ? props.marginB : "120px"};
  overflow: ${(props) => (props.overflow ? props.overflow : "visible")};
  padding: 0 64px;
  ${(props) => (props.zIndex ? `z-index: ${props.zIndex};` : "")}
  @media (max-width: 1024px) {
    padding: 0 32px;
    margin-bottom: ${(props) =>
      props.marginB === "0" ? "0" : props.marginB ? props.marginB : "80px"};
  }
  @media (max-width: 768px) {
    padding: 0 24px;
    margin-bottom: ${(props) =>
      props.marginB === "0" ? "0" : props.marginB ? props.marginB : "48px"};
  }
  ${(props) =>
    props.type === "services" &&
    `
    margin-bottom: 120px;

    @media (max-width: 1024px) {
      margin-bottom: 32px;
    }

    @media (max-width: 768px) {
      margin-bottom: 24px;
    }
  `}
`;
