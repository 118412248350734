import styled from "@emotion/styled";
import hero from "../../assets/image/hero.png";
import { pink, h1, h3, h6 } from "../../assets";

export const HeroCss = styled.div`
  padding: 188px 0 60px 0;
  background-image: url(${hero});
  background-repeat: no-repeat;
  background-size: contain;

  background-position: right;
  @media (max-width: 480px) {
    background-image: none;
  }
  @media (max-width: 1024px) {
    padding-top: 150px;
  }
  @media (max-width: 768px) {
    padding: 115px 0 0 0;
  }
  /** 
    @media (max-width: 1024px) {
    background-position: 270px 0;
  }
  @media (max-width: 768px) {
    background-position: 170px 0;
  }*/
`;

export const HeroTitleWrap = styled.div`
  position: relative;
  width: 885px;
  @media (max-width: 1024px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    max-width: 500px;
    min-width: 320px;
    width: 100%;
  }
`;

export const Svg = styled.svg`
  position: absolute;
  right: 88px;
  top: -55px;
  fill: ${pink};
  @media (max-width: 1024px) {
    right: 0;
    top: -45px;
  }
`;

export const HeroTitle = styled.h1`
  ${h1}
  margin: 0;
  margin-bottom: 108px;
  @media (max-width: 1024px) {
    ${h3}
    margin-bottom: 53px;
  }
  @media (max-width: 768px) {
    ${h6}
    margin-bottom: 32px;
  }
`;

export const CardList = styled.div`
  display: grid;
  grid-template-columns: 572fr 267fr 425fr;
  grid-gap: 24px;

  @media (max-width: 1048px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;

    & > div:nth-of-type(3) {
      grid-column: 1 / span 2;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "main main";

    & > div:first-of-type {
      grid-column: 1 / span 2;
      grid-area: main;
    }

    & > div:nth-of-type(3) {
      grid-column: auto;
    }
  }
`;
