import { PageForgotPasswordWrap } from "./PageForgotPassword.styled";
import Section from "../Section";
import Authorization from "../Authorization";

function PageForgotPassword() {
  return (
    <PageForgotPasswordWrap>
      <Section overflow="hidden" marginB="0">
        <Authorization type="forgot-password" />
      </Section>
    </PageForgotPasswordWrap>
  );
}

export default PageForgotPassword;
