import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import type { RootState } from "../../store/store";
import { toggleMenu } from "../../store/slices/menuSlice";
import { NavLinkCss } from "./NavLink.styled";

interface INavLinkComponentProps {
  to: string;
  text: string;
  color?: "pink" | "black" | "yellow" | "white";
  scroll?: string;
}

const NavLinkComponent: FC<INavLinkComponentProps> = ({
  to,
  text,
  color = "black",
  scroll,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMenuOpen = useSelector((state: RootState) => state.menu.isOpen);

  const handleMenuButtonClick = () => {
    if (isMenuOpen) {
      dispatch(toggleMenu());
    }
    if (scroll && window.location.pathname !== '/') {
      navigate('/');
      setTimeout(() => smoothScrollTo(scroll), 400);
    } else if (scroll) {
      smoothScrollTo(scroll);
    }
  };

  const smoothScrollTo = (scrollTargetId: string) => {
    const element = document.getElementById(scrollTargetId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <NavLinkCss to={to} color={color} onClick={handleMenuButtonClick}>
      {text}
    </NavLinkCss>
  );
};

export default NavLinkComponent;
