import styled from "@emotion/styled";
import { h3 } from "../../assets";
import starBlue from "../../assets/image/star_shape-b.png";

interface StyledPageProps {
  backgroundColor: string;
  type?: string;
}

export const StyledPageWrapperWrap = styled.div<StyledPageProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 172px;
  ${(props) => (props.type ? "padding-top:0;" : "padding-top: 172px;")};
  min-height: 100vh;
  text-align: center;
  overflow: hidden;
  ${(props) =>
    props.backgroundColor && `  background:${props.backgroundColor}`};
  &:after {
    content: "";
    position: absolute;
    background-image: url(${starBlue});
    width: 1340px;
    height: 1180px;
    top: 0;
    right: -400px;
    z-index: 0;
    @media (max-width: 1024px) {
      top: 600px;
      transform: rotate(-10deg);
    }
    @media (max-width: 678px) {
      top: 1000px;
    }
  }
`;

export const PaymentPlansTitle = styled.h3`
  ${h3}
  font-weight: 500;
  text-align: left;
  margin-bottom: 32px;
  @media (max-width: 1024px) {
  }
  @media (max-width: 768px) {
  }
`;
