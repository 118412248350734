import React, { useState } from "react";
import {
  FeaturesCardWrap,
  FeaturesCardTitle,
  UsedCreditsNumber,
  RemainingCredits,
} from "./DashboardFeatureCard.styled";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import Popup from "../Popup";

type DashboardFeatureCardProps = {
  title: string;
  remainingCreditsNumber: number;
  usedCreditsNumber: number;
  generatorLink: string;
  isAttemptsAvailable: boolean;
  subscriptionType: string;
};

const DashboardFeatureCard: React.FC<DashboardFeatureCardProps> = ({
  title,
  remainingCreditsNumber,
  usedCreditsNumber,
  generatorLink,
  isAttemptsAvailable,
  subscriptionType,
}) => {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleNavigateToGenerator = () => {
    if (!isAttemptsAvailable) {
      navigate(generatorLink);
    } else {
      setIsPopupOpen(true);
    }
  };

  const handleUpdatePlan = () => {
    window.location.href = `${process.env.REACT_APP_BILLING_PORTAL}`;
    setIsPopupOpen(false);
  };
  return (
    <>
      <FeaturesCardWrap>
        <FeaturesCardTitle>{title}</FeaturesCardTitle>
        <UsedCreditsNumber>{usedCreditsNumber}</UsedCreditsNumber>
        <Button
          text="Generate"
          width="240px"
          withIcon={false}
          btnType="dashboard"
          bgColor="#F0F424"
          onClick={handleNavigateToGenerator}
        />
        <RemainingCredits>
          Remaining Credits:{" "}
          <span>
            {subscriptionType === "creator" ? remainingCreditsNumber : "\u221E"}
          </span>
        </RemainingCredits>
      </FeaturesCardWrap>
      <Popup
        popupType="credits-error"
        isOpen={isPopupOpen}
        onClose={handleUpdatePlan}
      />
    </>
  );
};

export default DashboardFeatureCard;
