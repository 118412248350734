import styled from "@emotion/styled";
import { c1 } from "../../assets";

type AIHistoryProps = {};

export const AIHistoryWrapper = styled.div<AIHistoryProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 48px;
`;
export const AIHistoryTitle = styled.div<AIHistoryProps>`
  ${c1}
  text-align:left;
  margin-bottom: 24px;
`;
