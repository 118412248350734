import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "../../store/store";
import { toggleMenu } from "../../store/slices/menuSlice";
import sprite from "../../icons/symbol-defs.svg";
import {
  HeaderCss,
  HeaderContainer,
  Cart,
  Panel,
  Logo,
  CustomLink,
  ProductsInCart,
  HeaderNavList,
  MenuButton,
  MobileMenu,
  User,
  UserWrap,
} from "./Header.styled";
import NavList from "../NavList";
import { navLinks } from "../../assets/";
import MobileNavList from "../MobileNavList";

type HeaderProps = {
  type?: string;
};

const Header: React.FC<HeaderProps> = ({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector((state: RootState) => state.cart.products);
  const isMenuOpen = useSelector((state: RootState) => state.menu.isOpen);
  const user = useSelector((state: RootState) => state.user);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleMenuButtonClick = () => {
    dispatch(toggleMenu());
  };

  const handleDashboard = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <HeaderCss type={type}>
      <HeaderContainer>
        <Link to="/">
          <Logo height="32">
            <use xlinkHref={`${sprite}#woohoo`}></use>
          </Logo>
        </Link>

        <HeaderNavList>
          <NavList navList={navLinks} />
        </HeaderNavList>

        {isMobile ? (
          <>
            <Panel>
              {type && (
                <UserWrap>
                  <User width="24" height="24">
                    <use xlinkHref={`${sprite}#user`}></use>
                  </User>
                </UserWrap>
              )}
              <CustomLink to="/checkout">
                <Cart>
                  <svg width="36" height="36">
                    <use xlinkHref={`${sprite}#cart`}></use>
                  </svg>
                  {cartItems.length === 0 ? (
                    <></>
                  ) : (
                    <ProductsInCart>{cartItems.length}</ProductsInCart>
                  )}
                </Cart>
              </CustomLink>
              <MenuButton
                onClick={handleMenuButtonClick}
                isMenuOpen={isMenuOpen}
              >
                <span></span>
              </MenuButton>
            </Panel>
            <MobileMenu isMenuOpen={isMenuOpen}>
              <MobileNavList navList={navLinks} />
            </MobileMenu>
          </>
        ) : (
          <Panel>
            {type && user.id && (
              <UserWrap onClick={handleDashboard}>
                <User width="24" height="24">
                  <use xlinkHref={`${sprite}#user`}></use>
                </User>
              </UserWrap>
            )}
            <Link to="/checkout">
              <Cart>
                <svg width="36" height="36">
                  <use xlinkHref={`${sprite}#cart`}></use>
                </svg>
                {cartItems.length === 0 ? (
                  <></>
                ) : (
                  <ProductsInCart>{cartItems.length}</ProductsInCart>
                )}
              </Cart>
            </Link>
          </Panel>
        )}
      </HeaderContainer>
    </HeaderCss>
  );
};

export default Header;
